

const residentialService = () => {

  return {
    name: 'Residential Service',
    title: 'Residential Service Rate with Seasonal Variations',
    rateElements: [
      // Distribution Charges
      {
        rateElementType: 'BlockedTiersInDays',
        name: 'Distribution Charges - October through May',
        rateComponents: [
          {
            charge: 0.033344,
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            months: [9, 10, 11, 0, 1, 2, 3, 4],
            name: 'Up to 600 kWh',
          },
          {
            charge: 0.033344,
            min: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            max: ['Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity'],
            months: [9, 10, 11, 0, 1, 2, 3, 4],
            name: 'Over 600 kWh',
          }
        ],
      },
      {
        rateElementType: 'BlockedTiersInDays',
        name: 'Distribution Charges - June through September',
        rateComponents: [
          {
            charge: 0.047308,
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            months: [5, 6, 7, 8],
            name: 'Up to 600 kWh',
          },
          {
            charge: 0.051129,
            min: [600, 600, 600, 600, 600, 600 ,600 ,600 ,600 ,600 ,600 ,600],
            max: ['Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity' ,'Infinity' ,'Infinity' ,'Infinity' ,'Infinity'],
            months: [5, 6, 7, 8],
            name: 'Over 600 kWh',
          }
        ],
      },
        // Basic Generation Service Charges
      {
        rateElementType: 'BlockedTiersInDays',
        name: 'Basic Generation Service Charge - Summer',
        rateComponents: [
          {
            charge: 0.077023,
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            months: [5, 6, 7, 8],
            name: 'Up to 600 kWh',
          },
          {
            charge: 0.086772,
            min: [600, 600, 600, 600, 600, 600 ,600 ,600 ,600 ,600 ,600 ,600],
            max: ['Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity' ,'Infinity' ,'Infinity' ,'Infinity' ,'Infinity'],
            months: [5, 6, 7, 8],
            name: 'Over 600 kWh',
          },
        ],
      },
      {
        rateElementType: 'BlockedTiersInDays',
        name: 'Basic Generation Service Charge - Winter',
        rateComponents: [
          {
            charge: 0.080531,
            min: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            max: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            months: [9, 10, 11, 0, 1, 2, 3, 4],
            name: 'Up to 600 kWh',
          },
          {
            charge: 0.080531,
            min: [600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600, 600],
            max: ['Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity', 'Infinity'],
            months: [9, 10, 11, 0, 1, 2, 3, 4],
            name: 'Over 600 kWh',
          },
        ],
      },
        // Transmission Charge
      {
        rateElementType: 'SurchargeAsPercent',
        name: 'Transmission Charge',
        rateComponents: [
          {
            charge: 0.061153,
            name: 'All Use',
          },
          {
            charge: 0.132919,
            months: [5, 6, 7, 8],
            name: 'On-Peak Summer',
          },
          {
            charge: 0.132919,
            months: [9, 10, 11, 0, 1, 2, 3, 4],
            name: 'On-Peak Winter',
          },
        ],
      },
    
      {
        rateElementType: 'FixedPerMonth',
        name: 'Service Charge',
        rateComponents: [
          {
            charge: 4.64,
            name: 'Basic Service Charge',
          },
          {
            charge: 4.95,
            name: 'Service Charge with NJ Sales and Use Tax',
          }
        ],
      }
    ],
  };
};

export default residentialService;
