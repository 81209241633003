import React from "react";
import { FormattedMessage } from "react-intl";

const HowItWorks = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="perksBottomText"
          defaultMessage="Every day, Xcel Energy will share energy needs and renewable energy availability with your automaker or evPulse. They will use that information and your charging preferences to create a customized charging schedule."
          description="Every day, Xcel Energy will share energy needs and renewable energy availability with your automaker or evPulse. They will use that information and your charging preferences to create a customized charging schedule."
        />
      </p>

      <p>
        <FormattedMessage
          id="perksBottomText2"
          defaultMessage="The schedule will ensure that your vehicle will be ready for you at the time you choose, and that it will charge during times that help the grid operate more efficiently and use more renewable energy. Your automaker or evPulse will automatically send the charging schedule to your car, so all you need to do is plug in. Your automaker or evPulse will also make it easy to adjust your schedule if you need to."
          description="The schedule will ensure that your vehicle will be ready for you at the time you choose, and that it will charge during times that help the grid operate more efficiently and use more renewable energy. Your automaker or evPulse will automatically send the charging schedule to your car, so all you need to do is plug in. Your automaker or evPulse will also make it easy to adjust your schedule if you need to."
        />
      </p>
    </>
  );
};

export default HowItWorks;
