import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import ChargingAvailabilityWizard from "../../ChargingAvailabilityWizard/ChargingAvailabilityWizard";
import { FormattedMessage} from 'react-intl';

const SelectChargerLevel = ({
  id = "select-charger-level",
  ...rest
}) => {
  const [showModal, setShowModal] = useState(false);
 
  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(!showModal)}
        className="btn-arrow"
      >
        <FormattedMessage
          id="evs.helpMeChoose"
          defaultMessage="Help Me Choose"
          description="Help Me Choose"
        />
        {">"}
      </button>

      <Modal isOpen={showModal} toggle={() => setShowModal(!showModal)}>
        <ModalHeader className="bold" toggle={() => setShowModal(!showModal)}>
          <FormattedMessage
            id="evs.chargingAvailability"
            defaultMessage="Charging Availability"
            description="Charging Availability"
          />
        </ModalHeader>
        <ModalBody>
          <ChargingAvailabilityWizard toggle={() => setShowModal(!showModal)} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default SelectChargerLevel;

SelectChargerLevel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string
};
