import React, { useContext } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"

import Select from "../../shared/InputElements/Select"
import { useIntl } from 'react-intl';

const SelectVehicleMakeFilter = ({
  id = "select-vehicle-make-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const vehicleMakeOptions = unique((vehicles || []).map((ev) => ev.make));
  const label = intl.formatMessage ? intl.formatMessage({ id: "Make", defaultMessage: "EV MAKE"}) : "EV MAKE"

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleMakeFilter")}
      label={label}
      optionNames={[intl.formatMessage 
        ? intl.formatMessage({
          id: "incentives.selectMake",
          defaultMessage: "Select Make",
        })
      : "Select Make",
        ...vehicleMakeOptions]}
      optionValues={["All", ...vehicleMakeOptions]}
      handler={(e) => userPrefs.set({ vehicleMakeFilter: e.target.value })}
    />
  );
};

export default SelectVehicleMakeFilter;

SelectVehicleMakeFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
