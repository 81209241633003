import React from "react";
import PropTypes from 'prop-types';
import "./FAQCard.scss"



const FAQCard = ({
    title,
    body,
    list,
    image
}) => {
  
   const mobile = window.innerWidth < 720;
    return (
      <div className="FAQCard">
          <div className="row ">
            <div className={mobile ? 'text' : 'col text'}>
              <h2 className="text-title">{title}</h2>
              <div className="small-space">
                <p>{body}</p>
              </div>
              {list ? (
                <ol className="list small-space">
                  {list.map((item) => (
                    <li>{item}</li>
                  ))}
                </ol>
              ) : null}
            </div>
            <div
              className={mobile ? 'image' : 'col image'}
              style={!mobile ? {
                backgroundImage: `url(${image})`,
              } : null}
            >
              {mobile ? <img src={image} alt="faqImage" className="icon" /> : null}
            </div>
          </div>
      </div>
    );
}

export default FAQCard;

FAQCard.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  list: PropTypes.array,
  image: PropTypes.any,
};