import { times } from "lodash";
import {
  ALL_HOURS,
  WEEKEND,
  FROM_9PM_TO_7AM,
  FROM_4PM_TO_9PM,
  FROM_7AM_TO_4PM
} from "../constants/";
import allHoursImage from "./chargingPatterns/allhours.png";
import from12amto6am from "./chargingPatterns/12am-6am.png";
import from6amto4pm from "./chargingPatterns/6am-4pm.png";
import from4pmto9pm from "./chargingPatterns/4pm-9pm.png";

const DST = {
  2021: {
    startHour: 1610,
    endHour: 7322,
  },
};

const dstAdjustedHour = (hour, year) => {
  const { startHour, endHour } = DST[year];
  const isWithinDST = hour >= startHour && hour <= endHour;
  return isWithinDST ? (hour + 1) % 24 : hour % 24;
};

// NOTE: this is not a complete list of leap years, but is sufficient for the data
// provided
const isLeapYear = (year) => [2016, 2020].includes(year);
const hoursInYear = (year) => (isLeapYear(year) ? 8784 : 8760);

// NOTE: Need to be only monday thru friday. 
const chargingPatterns = [
  {
    id: FROM_9PM_TO_7AM,
    title: "M-F: 9 p.m to 7 a.m",
    value: "$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const date = new Date(year, 0, 1, 0, 0, 0);
          date.setHours(i); 
          const hour = dstAdjustedHour(date.getHours(), year);
          const dayOfWeek = date.getDay();
          return (dayOfWeek >= 1 && dayOfWeek <= 5) && (hour >= 21 || hour < 7) ? 1.1 : 0;
        }),
    },
    image: from12amto6am,
    description: "Recommended!",
  },
  {
    id: FROM_7AM_TO_4PM,
    title: 'M-F: 7 a.m. to 4 p.m.',
    value: '$$',
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const date = new Date(year, 0, 1, 0, 0, 0);
          date.setHours(i);
          const hour = dstAdjustedHour(date.getHours(), year);
          const dayOfWeek = date.getDay();
          return (dayOfWeek >= 1 && dayOfWeek <= 5) && (hour >= 7 && hour < 16) ? 1.1 : 0;
        }),
    },
    image: from6amto4pm,
  },
  {
    id: FROM_4PM_TO_9PM,
    title: "M-F: 4-9 p.m.",
    value: "$$",
    loadProfile: {
      data: (year) =>
        times(hoursInYear(year), (i) => {
          const date = new Date(year, 0, 1, 0, 0, 0);
          date.setHours(i);
          const hour = dstAdjustedHour(date.getHours(), year);
          const dayOfWeek = date.getDay();
          return (dayOfWeek >= 1 && dayOfWeek <= 5) && (hour >= 16 && hour < 21) ? 1.1 : 0;
        }),
    },
    image: from4pmto9pm,
  },
  {
    id: ALL_HOURS,
    title: "All Hours",
    value: "$?",
    loadProfile: {
      data: () => times(hoursInYear(2021), (_) => 1.1),
    },
    image: allHoursImage,
    description: "Unpredictable!",
  },
  {
    id: WEEKEND,
    title: 'Weekend',
    value: '$?',
    loadProfile: {
      data: (year) => 
        times(hoursInYear(year), (i) => {
          const date = new Date(year, 0); // initiate with first day of the year
          date.setHours(i); // Add i hours to the start of the year
          const dayOfWeek = date.getDay(); // get week day, 0 is sunday and 6 is saturday
          return (dayOfWeek === 0 || dayOfWeek === 6) ? 1.1 : 0;
        }),
    },
    image: allHoursImage,
    description: "Only active on Saturdays and Sundays",
  }
];

export default chargingPatterns;
