import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const ChooseCanTurnInClunker = ({
  id = 'can-turn-in-clunker',
  label = 'can-turn-in-clunker',
  incentives,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  return (
    <Select
      id={id}
      label={label}
      isSpecial={true}
      value={userPrefs.get('canTurnInClunker')}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({ id: 'yes', defaultMessage: 'Yes' })
          : 'Yes',
        intl.formatMessage
          ? intl.formatMessage({ id: 'no', defaultMessage: 'No' })
          : 'No',
      ]}
      optionValues={['true', 'false']}
      handler={(e) => userPrefs.set({ canTurnInClunker: e.target.value })}
      {...rest}
    />
  );
};

export default ChooseCanTurnInClunker;

ChooseCanTurnInClunker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  incentives: PropTypes.array,
};
