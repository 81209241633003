import React from "react";

import "./styles.scss";
import {useIntl} from "react-intl"

const RLMSDetails = () => {
  const intl = useIntl();
  return (
    <div>
      <h2 className="title">
        {intl.formatMessage({
          id: 'rlms.loadService',
          defaultMessage: 'Residential Load Management Service',
        })}
      </h2>

      <h3>
        {intl.formatMessage({
          id: 'rlms.rateOverview',
          defaultMessage: 'Rate Overview',
        })}
      </h3>
      <p className="text">
        {intl.formatMessage({
          id: 'rlms.designed',
          defaultMessage: `The Residential Load Management Service (Rate Schedule RLM) is designed for residential customers. It covers the delivery of electricity, which can be purchased either from a Third Party Supplier (TPS) or through Public Service’s Basic Generation Service. Monthly service charges and variable distribution charges depending on the season and time of use (On-Peak or Off-Peak) apply. Additional charges may include Societal Benefits, Non-utility Generation, Solar Pilot Recovery, Green Programs Recovery, Tax Adjustment Credit, Zero Emission Certificate Recovery, and Conservation Incentive Program.`,
        })}
      </p>

      <h3>
        {intl.formatMessage({
          id: 'rlms.howItWorks',
          defaultMessage: 'How it Works',
        })}
      </h3>
      <p className="text">
        {intl.formatMessage({
          id: 'rlms.customerBilled',
          defaultMessage:
            'Customers are billed a monthly service charge plus distribution charges based on electricity consumption, which vary by season and time of day. In summer, On-Peak hours are more expensive. Various additional charges recover costs related to public policies, renewable energy programs, and efficiency incentives. Customers can choose to get their electricity supply from a TPS or from Public Service’s default service. If opting for TPS, charges are based on the agreement with the supplier, and Public Service will not charge for the supply portion.',
        })}
      </p>

      <h3>
        {intl.formatMessage({
          id: 'rlms.whatItMeans',
          defaultMessage: 'What it Means for You',
        })}
      </h3>
      <p className="text">
        {intl.formatMessage({
          id: 'rlms.customerFlexibility',
          defaultMessage:
            'As a customer, you have flexibility in choosing your electricity supplier, which could impact your overall cost. Understanding the various charges and when electricity is more expensive (On-Peak vs. Off-Peak) can help you manage your usage and potentially reduce your bill. Additionally, the charges include contributions to state-mandated programs and incentives, ensuring that you are supporting broader public and environmental policies. Ensure timely payments to avoid penalties, and review the full terms for specific conditions related to your service.',
        })}
      </p>
    </div>
  );
};

export default RLMSDetails;