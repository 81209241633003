import React from "react";
import { FormattedMessage } from "react-intl";
import Tooltip from "../../../../components/shared/ToolTip/ToolTip";
import redCheck from "../../../assets/images/icons/red_check.svg";
import tooltipImage from "../../../assets/images/icons/Union.svg";
import {
  EV_ACCELERATE_AT_HOME_NEW_MEXICO,
  INCOME_QUALIFIED_COLORADO,
  EV_ACCELERATE_AT_HOME_MINNESOTA,
  EV_ACCELERATE_AT_HOME_WISCONSIN,
  EV_ACCELERATE_AT_HOME_COLORADO,
  OPTIMIZE_YOUR_CHARGE_COLORADO,
  OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
  EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
  CHANGING_PERKS_PILOT_COLORADO,
  HOME_WIRING_REBATE_COLORADO,
  HOME_WIRING_REBATE_NEW_MEXICO,
  COLORADO,
  NEW_MEXICO,
  WISCONSIN,
  MINNESOTA,
} from "../../../../constants";
import { homeWiringRebateFaqs } from "./homeWiringRebateFAQs";
import { byApplicable } from "./byApplicable";
import { RenderByState } from "../../../../components/RenderByState";

export const getFAQs = (faqKey, region) => {
  const pdfLinks = {
    [COLORADO]:
      "https://www.xcelenergy.com/staticfiles/xe-responsive/Programs%20and%20Rebates/Residential/22-03-628%20CO%20EV%20Electrician%20Info%20Sheet%20P1.pdf",
    [WISCONSIN]:
      "https://www.xcelenergy.com/staticfiles/xe/PDF/WI-EV-Electricians-Info-Sheet.pdf",
    [MINNESOTA]:
      "https://drive.google.com/file/d/1Ve3-ezeSjjTAGDmmaLrzgEkSHFkP23Uz",
  };

  switch (faqKey) {
    case HOME_WIRING_REBATE_NEW_MEXICO:
    case HOME_WIRING_REBATE_COLORADO:
      return homeWiringRebateFaqs(faqKey, region);
    default:
      return [
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqElectricityPricingWork"
                defaultMessage="How does the electricity pricing in this program work?"
                description="How does the electricity pricing in this program work?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                With EV Accelerate At Home, the price of the electricity you use
                to charge your EV varies depending on the time of day. That
                means your bill will depend on how much electricity you use to
                charge your EV and when you charge it. (The electricity you use
                in your home will continue to be billed at your current rate.)
              </p>
              <p>
                Your Level 2 charger will be programmed to charge only during
                off-peak hours, between midnight and 8 a.m. So, you can rest
                assured that your EV is automatically charging during the lowest
                cost time period. No need to go to the garage at midnight to
                plug in!
              </p>
              <p>
                If you need to occasionally charge your EV during other times,
                you can easily adjust your charging schedule. Details about
                adjusting the scheduled charging time are found in the FAQ “What
                if I need to adjust my scheduled charging time?”
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqElectricityPricingWork"
                defaultMessage="How does the electricity pricing in this program work?"
                description="How does the electricity pricing in this program work?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqElectricityPricingWorkCOAnswerOne"
                  defaultMessage="Your EV charging will be billed at your home’s existing electricity rate, and you will qualify for the annual $50 credit as part of the Optimize Your Charge program."
                  description="Electricity Pricing CO Answer One"
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqElectricityPricingWorkCOAnswerTwo"
                  defaultMessage="If you need to occasionally charge your EV during other times, you can easily adjust your charging schedule."
                  description="Electricity Pricing CO Answer Two"
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">What is a Level 2 charger?</span>
          ),
          body: (
            <div>
              <p>
                There are two common household levels of EV charging - Level 1
                and 2. <br />
                <br />
                <strong>Level 1</strong> - Can provide 4 to 6 miles range for
                every hour charging. EVs come with a standard Level 1 charger
                that plugs into a typical household outlet (120-volt).
                <br />
                <strong>Level 2</strong> - Faster and more convenient charging,
                providing 25 to 40 miles range for every hour charging. Needs a
                dedicated 240-volt circuit (like an electric clothes dryer).
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id={`faqWhyAreOnly2LevelChargers${faqKey.split("-")[1]}`}
                defaultMessage="Why are only two Level 2 chargers eligible for this program?"
                values={{
                  stateName: region,
                }}
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id={`faqWhyAreOnly2LevelChargers${
                    faqKey.split("-")[1]
                  }Answer`}
                  defaultMessage="Through a formal vetting and approval process, we selected chargers that fulfilled program requirements of providing access to your charging data while keeping your bills low. We use this charging data to bill your EV energy use at the discounted rate for off-peak charging. As technologies change and improve, we will continue to explore additional options for customers to participate in this program."
                  values={{
                    stateName: region,
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
          ],
        },

        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id={`faqWorkWithElectricians${faqKey.split("-")[1]}`}
                defaultMessage="Do you work with electricians in {stateName}?"
                description="Do you work with electricians in Colorado?"
                values={{
                  stateName: region,
                }}
              />
            </span>
          ),
          body: (
            <RenderByState
              stateName={region}
              options={{
                default: (
                  <div>
                    <p>
                      <FormattedMessage
                        id={`faqWorkWithElectriciansAnswer`}
                        defaultMessage="We work with skilled electricians who are experienced in
                        installing Level 2 chargers for electric vehicles. "
                        description="Do you work with electricians in Colorado? Answer"
                      />

                      <a href={`${pdfLinks[region]}`}>
                        <FormattedMessage
                          id={`faqWorkWithElectriciansAnswerLink`}
                          defaultMessage="Here are the electricians we partner with in {stateName}"
                          values={{
                            stateName: region,
                          }}
                        />
                      </a>
                    </p>
                  </div>
                ),
                [NEW_MEXICO]: (
                  <div>
                    <p>
                      <FormattedMessage
                        id={`faqWorkWithElectricians${
                          faqKey.split("-")[1]
                        }Answer`}
                        defaultMessage="We work with skilled electricians who are experienced in installing Level 2 chargers for electric vehicles. Here are the electricians we partner with in New Mexico:"
                        description="Do you work with electricians in New Mexico? Answer"
                      />
                    </p>
                    <ul>
                      <li>
                        White Star Electric
                        <br />
                        15849 Harvest Rd.
                        <br />
                        Brighton, CO 80603
                        <br />
                        <a href="mailto:office@whitestarteam.com">
                          office@whitestarteam.com
                        </a>
                        <br />
                        720-534-1901
                      </li>
                    </ul>
                  </div>
                ),
              }}
            />
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            HOME_WIRING_REBATE_COLORADO,
            EV_ACCELERATE_AT_HOME_COLORADO,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqHowToEnrollOwnCharger"
                defaultMessage="How do I enroll if I’m bringing my own charger?"
                description="How do I enroll if I’m bringing my own charger?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqHowToEnrollOwnChargerAnswer"
                  defaultMessage="Follow the prompts in the enrollment form here:"
                  description="How do I enroll if I’m bringing my own charger? answer"
                  values={{
                    link: (
                      <RenderByState
                        stateName={faqKey}
                        options={{
                          default: (
                            <a href="https://my.xcelenergy.com/MyAccount/s/ev">
                              <FormattedMessage
                                id="enrollInOYC"
                                defaultMessage="Enroll in Optimize Your Charge"
                                description="Enroll in Optimize Your Charge"
                              />
                            </a>
                          ),
                          [OPTIMIZE_YOUR_CHARGE_NEW_MEXICO]: (
                            <a href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/NM-EV_Accelerate_At_Home_Optimize_Your_Charge_Application_Terms_Conditions.pdf">
                              <FormattedMessage
                                id="enrollInOYC"
                                defaultMessage="Enroll in Optimize Your Charge"
                                description="Enroll in Optimize Your Charge"
                              />
                            </a>
                          ),
                        }}
                      />
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="ifChargePointCharger"
                  defaultMessage="If you have a ChargePoint charger:"
                  description="If you have a ChargePoint charger:"
                />
                <ul>
                  <li>
                    <FormattedMessage
                      id="ifChargePointChargerAnswer"
                      defaultMessage="If you have a ChargePoint charger:"
                      description="If you have a ChargePoint charger: Answer"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="ifChargePointChargerAnswerTwo"
                      defaultMessage="You will be prompted to fill in information including your first and last name, charger serial number, your electric meter and service number. The charger serial number is a 12-digit number. This can be found on the charger or its original packaging. Please contact us at ElectricVehicles@xcelenergy.com so that we can provide you with your electric meter number and service number."
                      description="If you have a ChargePoint charger: Answer Two"
                      values={{
                        link: (
                          <a href="mailto:ElectricVehicles@xcelenergy.com">
                            ElectricVehicles@xcelenergy.com
                          </a>
                        ),
                      }}
                    />
                  </li>
                </ul>
                <FormattedMessage
                  id="ifEnelXCharger"
                  defaultMessage="If you have a EnelX charger:"
                  description="If you have a EnelX charger:"
                />
                <ul>
                  <li>
                    <FormattedMessage
                      id="ifEnelXChargerAnswer"
                      defaultMessage="After enrolling, Enel X will connect your Enel X charger and will notify you by email once that has been done."
                      description="If you have a EnelX charger: Answer"
                    />
                  </li>
                </ul>
              </p>
            </div>
          ),
          applicableTo: [
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqBenefitsLevelTwoCharging"
                defaultMessage="What are the benefits of Level 2 charging?"
                description="What are the benefits of Level 2 charging?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqBenefitsLevelTwoChargingAnswer"
                  defaultMessage="Level 2 charging is faster and more convenient, providing 25-40 miles of range for every hour of charging. Level 1 charging provides 4-6 miles of range for every one hour of charging. A Level 2 charger requires a dedicated 240-volt circuit (like an electric clothes dryer)."
                  description="What are the benefits of Level 2 charging? Answer"
                />
              </p>
            </div>
          ),
          applicableTo: [
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What if I already own a Level 2 charger?
            </span>
          ),
          body: (
            <div>
              <p>
                Your existing Level 2 charger is ineligible. You may still
                participate in this program; however, you must participate by
                using one of the two eligible chargers offered in this program.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqAlreadyOwnLevelTwoCharger"
                defaultMessage="What if I already own a Level 2 charger?"
                description="What if I already own a Level 2 charger?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                If you have a ChargePoint Home or Enel X JuiceBox charger that
                was purchased prior to Dec. 16, 2020, you may be able to
                participate in this program. Please{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">email us</a>{" "}
                instead of enrolling online. We will work with you directly to
                get you signed up. An electrician will need to program the
                charger and provide wiring if needed. If you bring your own
                charger, you’ll pay $240 for electrician set up and $6.68 per
                month for data and service fees and have access to electricity
                pricing that can save you money on charging. You will be
                responsible for maintenance of the charger.
                <br />
                At this time, other Level 2 chargers are not compatible with the
                program.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqAlreadyOwnLevelTwoCharger"
                defaultMessage="What if I already own a Level 2 charger?"
                description="What if I already own a Level 2 charger?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqAlreadyOwnLevelTwoChargerAnswer"
                  defaultMessage="EV Accelerate At Home provides a lifetime warranty for a Level 2 charger and could be a great option if you need more than one charger. With your existing charger, you may be eligible to participate in Optimize Your Charge."
                  description="What if I already own a Level 2 charger? Answer"
                  values={{
                    link: (
                      <a href="/optimize-your-charge">
                        <FormattedMessage
                          id="optimizeYourCharge"
                          defaultMessage="Optimize Your Charge"
                          description="Optimize Your Charge"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What is included in the installation service?
            </span>
          ),
          body: (
            <div>
              <p>
                The installation service in this program only includes the
                set-up and hardwiring of the Level 2 charger. This service&nbsp;
                <i>does not include</i> any additional electrical work such as
                circuit wiring or panel upgrades, which may be required to set
                up your charging location for a 240-volt circuit.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="whereFindEVID"
                defaultMessage="Where do I find my charger's ID or serial number?"
                description="Where do I find my charger's ID or serial number?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <a href="https://www.xcelenergy.com/staticfiles/xe-responsive/Archive/Customer Resource_Finding CPSN_JBID_XcelMeter.pdf">
                  <FormattedMessage
                    id="whereFindEVIDHere"
                    defaultMessage="Here "
                    description="Here "
                  />
                </a>
                <FormattedMessage
                  id="whereFindEVIDAnswer1"
                  defaultMessage="is a guide with instructions on how to find your ChargePoint Home Flex serial number or Enel X JuiceBox ID number and your meter number."
                  description="is a guide with instructions on how to find your ChargePoint Home Flex serial number or Enel X JuiceBox ID number and your meter number."
                />
              </p>
              <p>
                <FormattedMessage
                  id="whereFindEVIDAnswer2"
                  defaultMessage="If you are still having issues finding this information, please contact an Electric Vehicle Advisor at 800.895.4999."
                  description="If you are still having issues finding this information, please contact an Electric Vehicle Advisor at 800.895.4999."
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqWhatIsIncludedInstallationService"
                defaultMessage="What is included in the installation service?"
                description="What is included in the installation service?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqWhatIsIncludedInstallationServiceAnswer"
                  defaultMessage="Our Level 2 charger installation service includes setup and hardwiring. This service does not include any additional electrical work, which may be required at your charging location."
                  description="What is included in the installation service? Answer"
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqWhatIsIncludedInstallationServiceAnswerTwo"
                  defaultMessage="Our electricians can perform additional required home wiring work for you at the time of installation and will provide you a quote prior to that work. They can also help you save up front with our Home Wiring Rebate. You can choose your own electrician outside of our network to perform the home wiring work, but they cannot offer the home wiring rebate up front."
                  description="What is included in the installation service? Answer Two"
                  values={{
                    link: (
                      <a
                        href="/home-wiring-rebate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="faqRebateCOHomeWiringRebate"
                          defaultMessage="Home Wiring Rebate"
                          description="Home Wiring Rebate"
                        />
                      </a>
                    ),
                    bold: (
                      <b>
                        <FormattedMessage
                          id="theyCanAlsoHelpSave"
                          defaultMessage="They can also help you save up front with our"
                          description="They can also help you save up front with our"
                        />
                      </b>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              How much does additional circuit wiring or panel upgrades
              typically cost?
            </span>
          ),
          body: (
            <div>
              <p>
                The program cost does not include additional circuit wiring that
                may be required to add a new 240-volt outlet. Those costs depend
                on your home’s unique electrical setup and wiring.{" "}
                <a href="/home-charging-advisor">
                  Use our online tool to help estimate potential costs for
                  additional wiring.
                </a>
                <br />
                Our electricians can help you understand the actual costs of any
                additional work unique to your home. To complete any additional
                electrical work, you can use our electrician and pay them
                directly or choose another electrician.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqAdditionalWiringPanelUpgradeCost"
                defaultMessage="How much do additional circuit wiring or panel upgrades typically cost?"
                description="How much do additional circuit wiring or panel upgrades typically cost?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqAdditionalWiringPanelUpgradeCostAnswer"
                  defaultMessage="The program cost does not include additional circuit wiring that may be required to add a new 240-volt circuit. Those costs depend on your home’s unique electrical setup and wiring."
                  description="The program cost does not include additional circuit wiring that may be required to add a new 240-volt circuit. Those costs depend on your home’s unique electrical setup and wiring."
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqAdditionalWiringPanelUpgradeCostAnswerTwo"
                  defaultMessage="Our electricians can perform additional required home wiring work for you at the time of installation and will provide you a quote prior to that work. They can also help you save up front with our Home Wiring Rebate. You can choose your own electrician outside of our network to perform the home wiring work, but they cannot offer the home wiring rebate up front."
                  description="Wiring Panel Upgrage Cost Answer Two"
                  values={{
                    link: (
                      <a
                        href="/home-wiring-rebate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="faqRebateCOHomeWiringRebate"
                          defaultMessage="Home Wiring Rebate"
                          description="Home Wiring Rebate"
                        />
                      </a>
                    ),
                    bold: (
                      <b>
                        <FormattedMessage
                          id="theyCanAlsoHelpSave"
                          defaultMessage="They can also help you save up front with our"
                          description="They can also help you save up front with our"
                        />
                      </b>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqAdditionalWiringPanelUpgradeCostAnswerThree"
                  defaultMessage="To estimate potential costs for additional wiring, try our Home Wiring Estimator."
                  description="To estimate potential costs for additional wiring, try our Home Wiring Estimator."
                  values={{
                    link: (
                      <a href="/home-charging-advisor">
                        <FormattedMessage
                          id="homeWiringEstimator"
                          defaultMessage="Home Wiring Estimator"
                          description="Home Wiring Estimator"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What if I'm already enrolled in the EV Pricing Plan or the Time of
              Day Pricing Plan?
            </span>
          ),
          body: (
            <div>
              <p>
                Before you enroll, please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com.">
                  ElectricVehicles@xcelenergy.com.
                </a>
                to discuss your options. You cannot participate in this pilot
                and our EV Pricing Plan or Time of Day Pricing Plan at the same
                time.
                <br />
                See{" "}
                <a
                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </a>{" "}
                (PDF) for more information.
              </p>
            </div>
          ),
          applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              Can I participate if I rent my home?
            </span>
          ),
          body: (
            <div>
              <p>
                Customers who are renting their home (detached single family
                home, townhome, row house, or duplex) must have a separately
                metered service for that home and have the building owner’s
                written consent to participate in this program.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
        },
        {
          headLine: (
            <span className="headline">
              Can I participate if I’m enrolled in the Time of Day rate?
            </span>
          ),
          body: (
            <div>
              <p>
                Yes. If you are already enrolled in our&nbsp;
                <a
                  href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Time of Day
                </a>
                &nbsp;rate, you can enroll if you meet all other eligibility
                requirements.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
        },
        {
          headLine: (
            <span className="headline">
              Can I participate if I’m enrolled in the Time of Day rate?
            </span>
          ),
          body: (
            <div>
              <p>
                Yes. If you are already enrolled in our&nbsp;
                <a
                  href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Time of Day
                </a>
                &nbsp;rate, you can enroll if you meet all other eligibility
                requirements.
                <br />
                Like all other participants, you will have a choice to either
                rent or own the charger that we will install and maintain, but
                the monthly fee is slightly different: $12.09 if you rent the
                charger or $2.30 if you buy the charger and installation up
                front.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              Can I participate if I’m enrolled in an Xcel Energy on-site solar
              program?
            </span>
          ),
          body: (
            <div>
              <p>
                If you are enrolled in Net Metering, you must sign up for
                the&nbsp;
                <a
                  href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Time of Day</b>
                </a>
                &nbsp;rate (and meet all other eligibility) to enroll in this
                program.
              </p>
              <p>
                Like all other participants, you will have a choice to either
                rent or own the charger that we will install and maintain, but
                the monthly fee is slightly different: $13.00 if you rent the
                charger or $3.00 if you buy the charger and installation up
                front.
              </p>
              <p>
                If you sign up for the&nbsp;
                <a
                  href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Time of Day
                </a>
                &nbsp;rate, your EV charging will also be billed at the Time of
                Day rate, not the EV Accelerate At Home EV charging rate.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
        },
        {
          headLine: (
            <span className="headline">
              Can I participate if I’m enrolled in an Xcel Energy on-site solar
              program?
            </span>
          ),
          body: (
            <div>
              <p>
                If you are enrolled in Net Metering or Solar*Rewards, you must
                sign up for the&nbsp;
                <a
                  href="https://www.xcelenergy.com/billing_and_payment/understanding_your_bill/residential_rate_plans/time_of_day"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <b>Time of Day</b>
                </a>
                &nbsp;rate (and meet all other eligibility) to enroll in this
                program.
                <br />
                Like all other participants, you will have a choice to either
                rent or own the charger that we will install and maintain, but
                the monthly fee is slightly different: $12.09 if you rent the
                charger or $2.30 if you buy the charger and installation up
                front. In addition, the customer charge on the Residential Time
                of Day rate is $2.00 higher than the standard Residential
                service.
                <br />
                If you’re a solar customer considering the Time of Day rate,
                please email&nbsp;
                <a href="mailto:solarprogrammn@xcelenergy.com">
                  solarprogrammn@xcelenergy.com
                </a>
                &nbsp;to find out if this rate is a good option for you. If you
                sign up for the Time of Day rate, your EV charging will also be
                billed at the Time of Day rate, not the EV Accelerate At Home EV
                charging rate.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqOnSiteSolarProgram"
                defaultMessage="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
                description="Can I participate if I’m enrolled in an Xcel Energy on-site solar program?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="yes"
                  defaultMessage="yes"
                  description="yes"
                />
                .
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqMultiFamilyBuilding"
                defaultMessage="Can I participate if I live in a multi-family building?"
                description="Can I participate if I live in a multi-family building?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqMultiFamilyBuildingAnswer"
                  defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                  description="Can I participate if I live in a multi-family building? Answer"
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqMultiFamilyBuilding"
                defaultMessage="Can I participate if I live in a multi-family building?"
                description="Can I participate if I live in a multi-family building?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqMultiFamilyBuildingAnswer"
                  defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                  description="Can I participate if I live in a multi-family building? Answer"
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqMultiFamilyBuildingAnswerTwo"
                  defaultMessage="If you live in a condo, townhome, or rowhouse that has an assigned parking spot and is metered separately, you can participate with your building owner’s approval."
                  description="Can I participate if I live in a multi-family building? Answer"
                  values={{
                    link: (
                      <a href="https://co.my.xcelenergy.com/s/business/ev/multifamily-charging">
                        <FormattedMessage
                          id="multiFamilyPrograms"
                          defaultMessage="multi-family programs"
                          description="multi-family programs"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              If I'm accepted into the program, when will my charging station be
              installed?
            </span>
          ),
          body: (
            <div>
              <p>
                Once you receive email confirmation that you are accepted into
                the program, an electrician from Xcel Energy’s network will
                contact you within 2 business days to schedule installation
                within 4 weeks. You or your authorized representative (over 18)
                must be present during the appointment. These timelines are
                rough estimates and subject to change. If you are also
                installing a separate meter and service for the charger, this
                may take 6 weeks or more.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqChargingStationBeInstalled"
                defaultMessage="When will my charging station be installed?"
                description="When will my charging station be installed?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqChargingStationBeInstalledAnswer"
                  defaultMessage="Once you receive email confirmation that you are accepted into the program, an electrician from Xcel Energy’s network will contact you within 2 business days to schedule installation, typically within 4 weeks. These timelines are rough estimates and subject to change. If you are also installing a separate meter and service for the charger, this may take 6 weeks or more."
                  description="When will my charging station be installed? Answer"
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqFiftyBillCredit"
                defaultMessage="When will I receive my annual $50 bill credit?"
                description="When will I receive my annual $50 bill credit?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqFiftyBillCreditAnswer"
                  defaultMessage="To reward EV drivers who participate in Optimize Your Charge, we will provide an annual $50 bill credit every October as long as your enrollment is active."
                  description="When will I receive my annual $50 bill credit? Answer"
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">What if my charger malfunctions?</span>
          ),
          body: (
            <div>
              <p>
                A common fix for most issues is to turn the charger on and off.
                Start by turning the power to the charger off by flicking the
                breaker switch in your electric panel. Wait 5 minutes, then turn
                the power back on. Your charger should automatically reconnect
                to your Wi-Fi and resume charging as originally scheduled.
                <br />
                If this does not work, please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">
                  ElectricVehicles@xcelenergy.com
                </a>{" "}
                to let us know, then contact the charger manufacturer. When
                speaking with the manufacturer, describe the issue and tell them
                that you are participating in the Xcel Energy EV Accelerate At
                Home program. The manufacturer will help troubleshoot and work
                with us to identify the resolution.
              </p>
              <a
                href="https://www.chargepoint.com/drivers/home/resource/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ChargePoint Support
              </a>
              &nbsp;
              <a href={`tel: 877-850-4562`}>877-850-4562</a>
              <br />
              <a
                href="https://support-emobility.enelx.com/hc/en-us"
                target="_blank"
                rel="noopener noreferrer"
              >
                Enel X Support
              </a>
              &nbsp;
              <a href={`tel: 844-584-2329`}>844-584-2329</a>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What is included in charger maintenance and warranty?
            </span>
          ),
          body: (
            <div>
              <p>
                If you rent the charger from us and stay in the program, ongoing
                maintenance is included in your monthly fee and you are eligible
                for a lifetime warranty for your charger. If you buy the charger
                from us, you are eligible for the standard 3-year manufacturer
                warranty and ongoing maintenance up until your charger is beyond
                repair. Maintenance is not included for customers who bring
                their own charger.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqIncludedInMaintenanceAndWarranty"
                defaultMessage="What is included in charger maintenance and warranty?"
                description="What is included in charger maintenance and warranty?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqIncludedInMaintenanceAndWarrantyAnswer"
                  defaultMessage="Ongoing maintenance is included in your monthly fee and you are eligible for a lifetime warranty for your charger as long as you are enrolled."
                  description="What is included in charger maintenance and warranty? Answer"
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What if my charger malfunctions or I need to relocate it?
            </span>
          ),
          body: (
            <div>
              <p>
                Ongoing maintenance of your charger is included in your monthly
                subscription. If you have questions or experience issues with
                your charger, please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com.">
                  ElectricVehicles@xcelenergy.com.
                </a>
                <br />
                For additional information and helpful tips for troubleshooting
                Wi-Fi or other potential issues with your charger, please see
                the following links.
                <br />
                <a href="https://www.chargepoint.com/drivers/home/resource/">
                  ChargePoint Support
                </a>
                <br />
                <a href="https://support-emobility.enelx.com/hc/en-us">
                  Enel X Support
                </a>
              </p>
            </div>
          ),
          applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              What if I am not able to charge my EV overnight?
            </span>
          ),
          body: (
            <div>
              <p>
                You may still participate in this program, but it will likely
                not be the best option for you. The program is designed for EV
                drivers who can charge their vehicles overnight when energy
                prices are the lowest.
                <br />
                However, if you need to occasionally charge your EV during peak
                and mid-peak times, you will have that option. You will be
                charged at the higher rates.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What if I do not charge my EV overnight?
            </span>
          ),
          body: (
            <div>
              <p>
                You may still participate in this pilot program, but it will
                likely not be the best option for you. The program is designed
                for EV drivers who can charge their vehicles overnight when
                energy prices are the lowest.
                <br />
                However, if you need to occasionally charge your EV on weekdays
                between 9 a.m. and 9 p.m., you have that option. Charges during
                this time will incur charges in addition to your monthly
                subscription price. On-peak use will be charged at 16.508¢ per
                kWh during winter months (Oct. through May) and 20.497¢ per kWh
                in summer months (June through Sept)
              </p>
            </div>
          ),
          applicableTo: [EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA],
        },
        // {
        //   headLine: (
        //     <span className="headline">
        //       What if I need to adjust my scheduled charging time?
        //     </span>
        //   ),
        //   body: (
        //     <div>
        //       <p>
        //         If you need to occasionally charge your EV during peak or
        //         mid-peak times, you can easily adjust your charging time.
        //       </p>
        //       <ul>
        //         <li>
        //           Use your charger manufacturer's app to override the scheduled
        //           charging time and charge immediately. (Tesla drivers should
        //           use the Tesla app.)
        //         </li>
        //         <li>
        //           If you have a ChargePoint Home, you can "double pump" the
        //           charger by plugging in, unplugging and plugging back into the
        //           car charging port.
        //         </li>
        //         <li>
        //           Your charger will revert to your scheduled charging time the
        //           next time you plug in your EV.
        //         </li>
        //       </ul>
        //     </div>
        //   ),
        //   applicableTo: [
        //     EV_ACCELERATE_AT_HOME_COLORADO,
        //     EV_ACCELERATE_AT_HOME_MINNESOTA,
        //     EV_SUBSCRIPTION_SERVICE_PILOT_MINNESOTA,
        //   ],
        // },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqAdjustScheduledChargingTime-nm"
                defaultMessage="What if I need to adjust my scheduled charging time?"
                description="What if I need to adjust my scheduled charging time?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqAdjustScheduledChargingTimeAnswer-nm"
                  defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                  description="What if I need to adjust my scheduled charging time? Answer"
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id="useYourChargerAppOverride-nm"
                    defaultMessage="Through your charger: Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers will use the Tesla app vs charger app.)"
                    description="ChargePoint Home Adjust Charging Time"
                  />
                  <ul>
                    <li>
                      <FormattedMessage
                        id="chargePointAdjustChargingTime-nm"
                        defaultMessage='If you have a ChargePoint Home, you can "double pump" the charger by plugging in, unplugging and plugging back into the car charging port.'
                        description="ChargePoint Home Adjust Charging Time"
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="revertScheduleChargingTime-nm"
                        defaultMessage="Your ChargePoint charger will revert to your scheduled charging time the next time you plug in your EV."
                        description="Revert Charging Time"
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedMessage
                    id="throughYourVehicle-nm"
                    defaultMessage="Through your vehicle: You’ll adjust your charging schedule through your vehicle’s app."
                    description="Through Vehicle Adjust"
                  />
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id="chooseDifferentChargingSchedule"
                  defaultMessage="If you would like to choose a different charging schedule, please contact us at ElectricVehicles@xcelenergy.com."
                  description="Revert Charging Time"
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com.">
                        ElectricVehicles@xcelenergy.com.
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqAdjustScheduledChargingTime"
                defaultMessage="What if I need to adjust my scheduled charging time?"
                description="What if I need to adjust my scheduled charging time?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqAdjustScheduledChargingTimeAnswer"
                  defaultMessage="If you need to occasionally charge your EV outside your selected charging schedule, you can easily adjust your scheduled charging time."
                  description="What if I need to adjust my scheduled charging time? Answer"
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id="useYourChargerAppOverride"
                    defaultMessage="Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers should use the Tesla app.)"
                    description="Use your charger manufacturer's app to override the scheduled charging time and charge immediately. (Tesla drivers should use the Tesla app.)"
                  />
                  <ul>
                    <li>
                      <FormattedMessage
                        id="chargePointAdjustChargingTime"
                        defaultMessage="If you have a ChargePoint Home, you can double pump the charger by plugging in, unplugging and plugging back into the car charging port."
                        description="ChargePoint Home Adjust Charging Time"
                      />
                    </li>
                    <li>
                      <FormattedMessage
                        id="revertScheduleChargingTime"
                        defaultMessage="Your charger will revert to your scheduled charging time the next time you plug in your EV."
                        description="Revert Charging Time"
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <FormattedMessage
                    id="throughYourVehicle"
                    defaultMessage="Through your vehicle: You’ll adjust your charging schedule through your vehicle’s app."
                    description="Through Vehicle Adjust"
                  />
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id="chooseDifferentChargingSchedule"
                  defaultMessage="If you would like to choose a different charging schedule, please contact us at ElectricVehicles@xcelenergy.com."
                  description="Revert Charging Time"
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com.">
                        ElectricVehicles@xcelenergy.com.
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_COLORADO,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
            CHANGING_PERKS_PILOT_COLORADO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="canIChargeWithRenewableEnergy"
                defaultMessage="Can I charge my EV with renewable energy?"
                description="Can I charge my EV with renewable energy?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="canIChargeWithRenewableEnergyAnswer"
                  defaultMessage="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                  description="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                  values={{
                    link: (
                      <a
                        href="https://www.xcelenergy.com/programs_and_rebates/residential_programs_and_rebates/renewable_energy_options_residential"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="renewableEnergyPrograms"
                          defaultMessage="renewable energy programs"
                          description="renewable energy programs"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_COLORADO,
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
            CHANGING_PERKS_PILOT_COLORADO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="canIChargeWithRenewableEnergy"
                defaultMessage="Can I charge my EV with renewable energy?"
                description="Can I charge my EV with renewable energy?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="canIChargeWithRenewableEnergyAnswer"
                  defaultMessage="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                  description="You can enroll in one of our renewable energy programs to lower your vehicle’s carbon footprint even more."
                  values={{
                    link: (
                      <a
                        href="https://nm.my.xcelenergy.com/s/renewable"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="renewableEnergyPrograms"
                          defaultMessage="renewable energy programs"
                          description="renewable energy programs"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqChargeAwayFromHome"
                defaultMessage="What do I do if I need to charge my car when I’m away from home?"
                description="What do I do if I need to charge my car when I’m away from home?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqChargeAwayFromHomeAnswer"
                  defaultMessage="You can find public charging stations using our interactive map, using a smartphone app, or by following your vehicle’s onboard charging prompts."
                  description="You can find public charging stations using our interactive map, using a smartphone app, or by following your vehicle’s onboard charging prompts."
                  values={{
                    link: (
                      <a href="/charging-stations">
                        <FormattedMessage
                          id="interactiveMap"
                          defaultMessage="interactive map"
                          description="interactive map"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
            EV_ACCELERATE_AT_HOME_COLORADO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              Which holidays do not include peak hours?
            </span>
          ),
          body: (
            <div style={{ width: "100%" }}>
              <p>
                There are no peak hours on New Year’s Day, Good Friday, Memorial
                Day, Independence Day, Labor Day, Thanksgiving, or Christmas
                Day. Mid-peak hours still apply.
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
          ],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqMovingTakeCharger"
                defaultMessage="What If I am moving and would like to take my charger?"
                description="What If I am moving and would like to take my charger?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqMovingTakeChargerAnswer"
                  defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com. If you are moving within our service territory, we will send an electrician to remove the charger and install it at your new property. The relocation fee will be confirmed and billed by the electrician."
                  description="What If I am moving and would like to take my charger? Answer"
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com">
                        ElectricVehicles@xcelenergy.com
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            EV_ACCELERATE_AT_HOME_WISCONSIN,
            EV_ACCELERATE_AT_HOME_MINNESOTA,
            EV_ACCELERATE_AT_HOME_COLORADO,
            EV_ACCELERATE_AT_HOME_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What if I need to cancel my participation?
            </span>
          ),
          body: (
            <div>
              <p>
                Please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">
                  ElectricVehicles@xcelenergy.com
                </a>{" "}
                to cancel your participation. If you are renting the charger
                from us and it has been installed, you will be charged $200 to
                have your charger removed. If you are not renting from us, there
                is no charge.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqCancelParticipation-nm"
                defaultMessage="What if I need to cancel my participation?"
                description="What if I need to cancel my participation?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqCancelParticipationAnswer"
                  defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com to cancel your enrollment. If your charger has been installed, you will be invoiced $200 to have your charger removed or you may purchase the charger for the estimated remaining value."
                  description="What if I need to cancel my participation?"
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com">
                        ElectricVehicles@xcelenergy.com
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_NEW_MEXICO],
        },
        {
          headLine: (
            <span className="headline">
              What if I need to cancel my participation?
            </span>
          ),
          body: (
            <div>
              <p>
                Please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">
                  ElectricVehicles@xcelenergy.com
                </a>{" "}
                to cancel your participation. If you are renting the charger
                from us and it has been installed, you will be charged $200 to
                have your charger removed. You also have the option to buy the
                charger for the estimated remaining value of your specific
                charger. If you are <i>not</i> renting the charger from us,
                there is no charge.
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_MINNESOTA],
        },
        {
          headLine: (
            <span className="headline">
              What if I need to cancel my participation?
            </span>
          ),
          body: (
            <div>
              <p>
                Please contact us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">
                  ElectricVehicles@xcelenergy.com
                </a>{" "}
                if you would like to withdraw from this pilot program.
              </p>
            </div>
          ),
          applicableTo: ["SSP-MN"],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqCancelParticipation"
                defaultMessage="What if I need to cancel my participation?"
                description="What if I need to cancel my participation?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqCancelParticipationAnswer"
                  defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com to cancel your enrollment. If your charger has been installed, you will be invoiced $200 to have your charger removed or you may purchase the charger for the estimated remaining value."
                  description="What if I need to cancel my participation?"
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com">
                        ElectricVehicles@xcelenergy.com
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqCancelParticipation"
                defaultMessage="What if I need to cancel my participation?"
                description="What if I need to cancel my participation?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqCancelParticipationOYC"
                  defaultMessage="Please contact us at ElectricVehicles@xcelenergy.com."
                  description="Please contact us at ElectricVehicles@xcelenergy.com."
                  values={{
                    link: (
                      <a href="mailto:ElectricVehicles@xcelenergy.com">
                        ElectricVehicles@xcelenergy.com
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [
            OPTIMIZE_YOUR_CHARGE_COLORADO,
            OPTIMIZE_YOUR_CHARGE_NEW_MEXICO,
          ],
        },
        {
          headLine: (
            <span className="headline">
              What are my options at the end of the pilot?
            </span>
          ),
          body: (
            <div>
              <p>
                If you elected to rent the charger for a monthly fee, you can
                have the charger removed at no cost to you after the two-year
                pilot ends, or you may purchase the charger from us at a
                prorated cost of $686. If you choose to have the charger
                removed, you will be returned to your previous rate.
                <br />
                If you prepaid for the charger during enrollment, we will
                transfer ownership of the charger to you at no cost. Please see
                the{" "}
                <a
                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions
                </a>{" "}
                (PDF) for more information.
              </p>
            </div>
          ),
          applicableTo: ["SSP-MN"],
        },
        {
          headLine: <span className="headline">Additional Information</span>,
          body: (
            <div>
              <ul>
                <li>
                  <a
                    href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms &amp; Conditions
                  </a>{" "}
                  (PDF)
                </li>
                <li>
                  <a
                    href="https://www.xcelenergy.com/billing_and_payment/customer_data_&_privacy/privacy_policy_and_customer_data_access"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Enel X Data Privacy Agreement (PDF)
                  </a>
                </li>
              </ul>
            </div>
          ),
          applicableTo: [""],
        },

        {
          headLine: (
            <span className="headline">
              Preview customer service agreement required at time of enrollment
            </span>
          ),
          body: (
            <div>
              <p>
                <a
                  href="https://www.xcelenergy.com/staticfiles/xe-responsive/Energy%20Portfolio/Electricity/EV%20Subscription%20Pilot%20Terms%20and%20Conditions.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms &amp; Conditions (PDF)
                </a>
                <br />
                For additional questions, email us at{" "}
                <a href="mailto:ElectricVehicles@xcelenergy.com">
                  ElectricVehicles@xcelenergy.com
                </a>
                .
              </p>
            </div>
          ),
          //applicableTo: ['HSPI-WI', 'SSP-MN']
          applicableTo: ["SSP-MN"],
        },
        {
          headLine: (
            <span className="headline">What is a Level 2 charger?</span>
          ),
          body: (
            <div>
              <p>
                There are two common household levels of EV charging - Level 1
                and 2. <br />
                <br />
                Level 1 - Can provide 4 to 6 miles range for every hour
                charging. EVs come with a standard Level 1 charger that plugs
                into a typical household outlet (120-volt).
                <br />
                Level 2 - Faster and more convenient charging, providing 25 to
                40 miles range for every hour charging. Needs a dedicated
                240-volt circuit (like an electric clothes dryer).
              </p>
            </div>
          ),
          applicableTo: [EV_ACCELERATE_AT_HOME_WISCONSIN, "SSP-MN"],
        },
        {
          headLine: (
            <span className="headline">
              Why is Xcel Energy offering this pilot program?
            </span>
          ),
          body: (
            <div>
              <p>
                In the years to come, we expect significant growth in electric
                vehicles. This pilot program will help us understand how we can
                effectively manage the growth of EVs in ways that can make the
                grid more efficient and help use more renewable energy.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              Why does it matter when I charge my vehicle?
            </span>
          ),
          body: (
            <div>
              <p>
                Charging your EV during times of the day when energy demand is
                high puts additional strain on the energy grid. If you can shift
                the charging of your vehicle to times when demand is low, it
                reduces this strain.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              Will this pilot help reduce the environmental impact of charging
              my vehicle?
            </span>
          ),
          body: (
            <div>
              <p>
                Through this pilot, Xcel Energy will study the opportunities
                that smart charging provides to more effectively utilize our
                carbon-free resources to charge vehicles. The pilot will do this
                by sending information to your automaker or evPulse when the
                grid has an over-abundance of carbon-free resources.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              Will other automakers participate in this pilot program?
            </span>
          ),
          body: (
            <div>
              <p>
                If the pilot proves successful, Xcel Energy may seek to expand
                the number of automakers and opportunities for more drivers.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              What participation incentive will I receive?
            </span>
          ),
          body: (
            <div>
              <p>
                After Xcel Energy validates your eligibility for the pilot,
                we’ll send you a link you can use to select a $100 e-gift card
                from over&nbsp;
                <a
                  href="https://www.tangocard.com/reward-catalog/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  80 popular merchants.
                </a>
                &nbsp; Keep participating in the pilot program and you’ll get up
                to $100 more at the end of 2021 and again in 2022. Your year-end
                reward depends on whether you charge at home with a Level 1 or
                Level 2 charging station.
              </p>

              {/* <img src={participationIncentive} alt="Participation Incentive Table"/> */}
              {/* Keep here just incase copy needs to change */}
              <table className="incentive-table">
                <tr>
                  <th>Your Home Charging Station</th>
                  <th>Enrollment Reward</th>
                  <th>Year-End Participation Reward</th>
                </tr>
                <tr>
                  <td>
                    <p className="incentive-table-subheader">
                      Level 1 Charging
                    </p>
                    <p>
                      Level 1 charging uses a standard 120-vold plug and
                      provides approximately 4 to 6 miles of range per hour of
                      charging.
                      <span className="asterick">*</span>
                    </p>
                  </td>
                  <td rowSpan="2">$100</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>
                    <p className="incentive-table-subheader">
                      Level 2 Charging
                    </p>
                    <p>
                      Level 2 charging requries 208/240-volt electrical service
                      and typically provides 25 to 40 miles of range per hour of
                      charging.
                      <span className="asterick">*</span>
                    </p>
                  </td>
                  <td>$100</td>
                </tr>
              </table>
              <p className="incentive-table-legend">
                *Charging times vary by vehicle; customers should refer to their
                manufacturer’s information
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              How long will it take to receive my enrollment reward?
            </span>
          ),
          body: (
            <div>
              <p>
                After receiving your enrollment confirmation email from Xcel
                Energy, please allow 2+ weeks for your e-gift card to arrive in
                your inbox.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              Who do I contact if I am having problems redeeming or using my
              gift card?
            </span>
          ),
          body: (
            <div>
              <p>
                If you have any problems or questions redeeming or using your
                e-gift card, please contact Tango Card customer service at{" "}
                <a href={"mailto:cs@tangocard.com"}>cs@tangocard.com.</a>
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">Will I save on my electric bill?</span>
          ),
          body: (
            <div>
              <p>
                The pilot is not designed to affect your electric bill.
                Customers will receive gift cards for enrollment and
                participation, but your electric rate will remain the same.
              </p>
              <p>
                If you are on a time-of-use rate, this pilot will schedule much
                of your charging for late night and early morning hours, which
                are off-peak times. Depending on how you previously charged your
                vehicle, this pilot may provide additional savings on your bill
                if more of your charging during the pilot is completed during
                the off-peak time period.
              </p>
            </div>
          ),
          applicableTo: [],
        },
        {
          headLine: (
            <span className="headline">How will my charging be scheduled?</span>
          ),
          body: (
            <div>
              <p>
                Your automaker or evPulse will create an optimized charging
                schedule based on your preferences and information from Xcel
                Energy about the best times to charge. This schedule will be
                sent to your car automatically, so all you need to do is plug in
                when you get home and your car takes care of it from there.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              What if I need the car to charge before it is scheduled to start?
            </span>
          ),
          body: (
            <div>
              <p>
                The pilot program rules allow you to change your charging
                schedule whenever you need to, without affecting your program
                rewards or program status. To learn how to make changes to your
                charging schedule, visit your automaker’s website or log into
                your evPulse account:
              </p>
              <div className="d-flex justify-content-around flex-wrap">
                <a
                  href="https://bmwchargeforward.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  BMW
                </a>
                <a
                  href="https://smartcharging.chevrolet.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chevrolet
                </a>
                <a
                  href="http://www.ford.com/grid/xcel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ford
                </a>
                <a
                  href="https://hondasmartcharge.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Honda
                </a>
                <a
                  href="https://landing.xcelenergy.ev-pulse.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tesla (evPulse)
                </a>
              </div>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              What if I no longer want to participate in the pilot?
            </span>
          ),
          body: (
            <div>
              <p>
                You are free to leave the program without penalty whenever you
                need. Please contact your automaker to unenroll:
              </p>
              <p>
                <b>BMW: </b>
                <a href={"mailto:bmwgenius@bmwusa.com"}>bmwgenius@bmwusa.com</a>
              </p>
              <p>
                <b>Chevrolet:</b> Visit{" "}
                <a
                  href="https://www.smartcharging.chevrolet.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  smartcharging.chevrolet.com
                </a>
              </p>
              <p>
                <b>Ford:</b> Call 800-392-3673.
              </p>
              <p>
                <b>Honda:&nbsp;</b>
                Unenroll through HondaLink mobile application.
              </p>
              <p>
                Tesla drivers please email Xcel Energy at
                ElectricVehicles@xcelenergy.com with your request to unenroll.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              What data are you collecting from my vehicle?
            </span>
          ),
          body: (
            <div>
              <p>
                evPulse gathers charging data by accessing data available from
                your vehicle. Once you provide evPulse permission to gather this
                charging data through the enrollment process, evPulse will
                provide charging information about your vehicle to Xcel Energy.
                Xcel Energy uses this information to evaluate the benefits of
                smart charging.
              </p>
            </div>
          ),
          applicableTo: [CHANGING_PERKS_PILOT_COLORADO],
        },
        {
          headLine: (
            <span id="adjust-power-faq" className="headline">
              Can I adjust the power on my charger?
            </span>
          ),
          body: (
            <div>
              <p>
                Yes! You can adjust the amperage on the ChargePoint Home Flex
                and the Enel X Juicebox 40 to best match your home's unique
                wiring and your preferences. When our electrician installs your
                charger, they will set charger’s amperage for you.
              </p>
              <p>Supported amperage by charger:</p>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th className="dark-gray">
                      Minimum Circuit Rating
                      <Tooltip
                        id="minimum-circuit-rating-tooltip"
                        message="Dependent on breaker size. The circuit must be rated for 125% of the connected load."
                        img={tooltipImage}
                      />
                    </th>
                    <th className="dark-gray">
                      <>
                        Charger Amp Setting
                        <Tooltip
                          id="charger-amp-setting-tooltip"
                          message="This is the maximum connected load for a given circuit rating"
                          img={tooltipImage}
                        />
                      </>
                    </th>
                    <th className="dark-gray">ChargePoint Home Flex</th>
                    <th className="dark-gray">Enel X Juicebox 40</th>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="light-gray">
                    <td>60 amps</td>
                    <td>48 amps</td>
                    <td>
                      <img src={redCheck} alt="red check" />
                      &nbsp;
                    </td>
                    <td></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>50 amps*</td>
                    <td>40 amps*</td>
                    <td>
                      <img src={redCheck} alt="red check" />*
                    </td>
                    <td>
                      <img src={redCheck} alt="red check" />*
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <td>40 amps*</td>
                    <td>32 amps*</td>
                    <td>
                      <img src={redCheck} alt="red check" />*
                    </td>
                    <td>
                      <img src={redCheck} alt="red check" />*
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="light-gray">
                    <td>30 amps</td>
                    <td>24 amps</td>
                    <td>
                      <img src={redCheck} alt="red check" />
                      &nbsp;
                    </td>
                    <td>
                      <img src={redCheck} alt="red check" />
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr className="light-gray">
                    <td>20 amps</td>
                    <td>16 amps</td>
                    <td>
                      <img src={redCheck} alt="red check" />
                      &nbsp;
                    </td>
                    <td>
                      <img src={redCheck} alt="red check" />
                      &nbsp;
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="text-right">*most common configurations</div>
            </div>
          ),
          applicableTo: [],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOIncomeRequirements"
                defaultMessage="What are the income requirements?"
                description="What are the income requirements?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOIncomeRequirementsAnswer"
                  defaultMessage="To meet the “Income-Qualified” requirement, you must be currently enrolled in any one of the following programs:"
                  description="What are the income requirements?"
                  values={{
                    bold: (
                      <b>
                        <FormattedMessage
                          id="one"
                          defaultMessage="one"
                          description="one"
                        />
                      </b>
                    ),
                  }}
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id="lowIncomeAssistProgram"
                    defaultMessage="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
                    description="State of Colorado Low-Income-Energy Assistance Program (LEAP)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoAffordableResidentialEnergyProgram"
                    defaultMessage="Energy Outreach Colorado’s Colorado Affordable Residential Energy Program (CARE)"
                    description="Energy Outreach Colorado’s Colorado Affordable Residential Energy Program (CARE)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoWeatherAssistanceProgram"
                    defaultMessage="Colorado’s Weather Assistance Program (WAP)"
                    description="Colorado’s Weather Assistance Program (WAP)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoDemandSideManagementProgram"
                    defaultMessage="Xcel Energy income-qualified demand side management program"
                    description="Xcel Energy income-qualified demand side management program"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoCommunitySolarGardensProgram"
                    defaultMessage="Xcel Energy’s income-qualified Community Solar Gardens program"
                    description="Xcel Energy’s income-qualified Community Solar Gardens program"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoSNAPProgram"
                    defaultMessage="Supplemental Nutrition Assistance Program (SNAP)"
                    description="Supplemental Nutrition Assistance Program (SNAP)"
                  />
                </li>
                <li>
                  <FormattedMessage
                    id="coloradoTANFProgram"
                    defaultMessage="Temporary Assistance for Needy Families program (TANF)"
                    description="Temporary Assistance for Needy Families program (TANF)"
                  />
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id="faqHWRQualifiyRebateAnswerFour"
                  defaultMessage="OR, have a household income below:"
                  description="OR, have a household income below:"
                  values={{
                    bold: (
                      <b>
                        <FormattedMessage
                          id="or"
                          defaultMessage="OR"
                          description="OR"
                        />
                      </b>
                    ),
                  }}
                />
              </p>
              <ul>
                <li>
                  <FormattedMessage
                    id="coloradoSixtyMedianIncome"
                    defaultMessage="60% of the state of Colorado’s median income"
                    description="60% of the state of Colorado’s median income"
                  />
                </li>
                <span>
                  <strong>OR</strong>
                </span>
                <li>
                  <FormattedMessage
                    id="coloradoTwoHundredFederalPoverty"
                    defaultMessage="200% of the relevant federal poverty level"
                    description="200% of the relevant federal poverty level"
                  />
                </li>
                <span>
                  <strong>OR</strong>
                </span>
                <li>
                  <FormattedMessage
                    id="coloradoMedianIncomeArea"
                    defaultMessage="80% of area median income (varies by county; see examples, below):"
                    description="80% of area median income (varies by county; see examples, below):"
                  />
                  <table className="expanded-rebate-table">
                    <tr>
                      <th>
                        <FormattedMessage
                          id="coloradoCountyAMI"
                          defaultMessage="80% of County AMI"
                          description="80% of County AMI"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="coloradoIndividual"
                          defaultMessage="Individual"
                          description="Individual"
                        />
                      </th>
                      <th>
                        <FormattedMessage
                          id="coloradoFamilyFour"
                          defaultMessage="Family of 4"
                          description="Family of 4"
                        />
                      </th>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <FormattedMessage
                            id="coloradoDenverCounty"
                            defaultMessage="Denver, Jefferson, Arapahoe, Adams, and Douglas County"
                            description="Denver, Jefferson, Arapahoe, Adams, and Douglas County"
                          />
                        </b>
                      </td>
                      <td>$65,680</td>
                      <td>$93,760</td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <FormattedMessage
                            id="coloradoBoulderCounty"
                            defaultMessage="Boulder County"
                            description="Boulder County"
                          />
                        </b>
                      </td>
                      <td>$70,240</td>
                      <td>$100,320</td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <FormattedMessage
                            id="coloradoMesaCounty"
                            defaultMessage="Mesa County"
                            description="Mesa County"
                          />
                        </b>
                      </td>
                      <td>$46,080</td>
                      <td>$65,760</td>
                    </tr>
                    <tr>
                      <td>
                        <b>
                          <FormattedMessage
                            id="coloradoSummitCounty"
                            defaultMessage="Summit County"
                            description="Summit County"
                          />
                        </b>
                      </td>
                      <td>$58,640</td>
                      <td>$83,760</td>
                    </tr>
                  </table>
                </li>
              </ul>
              <p>
                <FormattedMessage
                  id="faqRebateCOIncomeRequirementsAnswerHelp"
                  defaultMessage="Get help with your questions about income qualification by contacting our partner, GRID Alternatives Colorado, at 866-434-1690 or information@coloradoevs.org."
                  description="Income Requirements Help"
                  values={{
                    phone: <a href="tel:8664341690">866-434-1690</a>,
                    mail: (
                      <a href="mailto:information@coloradoevs.org">
                        information@coloradoevs.org
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOEVsQualify"
                defaultMessage="What electric vehicles qualify for the EV rebate?"
                description="What electric vehicles qualify for the EV rebate?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOEVsQualifyAnswer"
                  defaultMessage="You can get a rebate on any new or used plug-in electric vehicle (battery electric or plug-in hybrid electric) with a manufacturer’s suggested retail price for a new EV (or the final negotiated price for a pre-owned EV) of $50,000 or less. You can buy or lease a new or used EV from any Colorado-based car dealer. Lease term must be at least two years.  Learn more about which EV is right for you."
                  description="Rebate CO EVs Qualify Answer"
                  values={{
                    lineBreak: <br />,
                    link: (
                      <a
                        href="/vehicles"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="EVRightForYou"
                          defaultMessage="EV is right for you"
                          description="EV is right for you"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              {" "}
              <FormattedMessage
                id="faqRebateCOWhereGetEV"
                defaultMessage="Where can I get an EV?"
                description="Where can I get an EV?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOWhereGetEVAnswer"
                  defaultMessage="To get the rebate up front while you are buying or leasing an EV, you must be pre-qualified and take your pre-qualification code to one of the EV Dealers in our EV Dealer Network.  To get the rebate after you purchase or lease the EV, you can buy or lease your EV from any Colorado-based dealer, whether they are in our dealer network or not."
                  description="Rebate CO Where Get EV Answer"
                  values={{
                    lineBreak: <br />,
                    link: (
                      <a
                        href="/dealers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="evDealerNetwork"
                          defaultMessage="EV Dealer Network"
                          description="EV Dealer Network"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOPreQualified"
                defaultMessage="How do I get pre-qualified?"
                description="How do I get pre-qualified?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOPreQualifiedAnswer"
                  defaultMessage="First, you must complete and submit the EV Rebate application. You must be an Xcel Energy residential electricity customer. Then, we will verify your income eligibility. Our partner, GRID Alternatives Colorado, will be in touch with you about the information needed from you to complete this process. Pre-qualification can take about 2 weeks from the time all the information necessary to process your application is received from you.  Get help with your questions about income qualification by contacting our partner, GRID Alternatives Colorado, at 866-434-1690 or information@coloradoevs.org."
                  description="Rebate CO PreQualified Answer"
                  values={{
                    lineBreak: <br />,
                    phone: <a href="tel:8664341690">866-434-1690</a>,
                    mail: (
                      <a href="mailto:information@coloradoevs.org">
                        information@coloradoevs.org
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOReceiveRebate"
                defaultMessage="When will I receive my rebate?"
                description="When will I receive my rebate?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOReceiveRebateAnswer"
                  defaultMessage="If you fill out our application before you shop and get pre-qualified, you get an instant rebate when you buy or lease your EV. You must buy or lease your EV from a dealer in our EV Dealer Network, and that dealer will reduce the price of the EV by the amount of the rebate. Pre-qualification can be done in as quickly as 10 days once we have all your information, and you will receive a code to take to the dealer for your instant rebate. You must use the code within 90 days or you’ll have to re-start the application process."
                  values={{
                    lineBreak: <br />,
                    bold1: (
                      <b>
                        <FormattedMessage
                          id="beforeYouShop"
                          defaultMessage="Before you shop"
                          description="Before you shop"
                        />
                      </b>
                    ),
                    bold2: (
                      <b>
                        <FormattedMessage
                          id="getPreQualified"
                          defaultMessage="get pre-qualified"
                          description="get pre-qualified"
                        />
                      </b>
                    ),
                    bold3: (
                      <b>
                        <FormattedMessage
                          id="instantRebate"
                          defaultMessage="instant rebate"
                          description="instant rebate"
                        />
                      </b>
                    ),
                    link1: (
                      <a
                        href="/dealers"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="evDealerNetwork"
                          defaultMessage="EV Dealer Network"
                          description="EV Dealer Network"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqRebateCOReceiveRebateAnswerTwo"
                  defaultMessage="If you buy or lease your EV first and submit an EV Rebate application afterward, you will get your rebate in the form of a check 6-8 weeks after your application is approved. You must meet the income requirements and all other requirements of the EV Rebate program to qualify for the rebate. You can buy or lease an EV from any Colorado-based dealer, whether they are in our EV Dealer Network or not, and receive your rebate after you’ve purchased the vehicle."
                  description="When will I receive my rebate Answer Two"
                  values={{
                    bold1: (
                      <b>
                        <FormattedMessage
                          id="buyOrLeaseFirst"
                          defaultMessage="buy or lease your EV first"
                          description="buy or lease your EV first"
                        />
                      </b>
                    ),
                    bold2: (
                      <b>
                        <FormattedMessage
                          id="submitRebateApplication"
                          defaultMessage="submit an EV Rebate application afterward"
                          description="submit an EV Rebate application afterward"
                        />
                      </b>
                    ),
                    bold3: (
                      <b>
                        <FormattedMessage
                          id="rebateInFormCheck"
                          defaultMessage="rebate in the form of a check"
                          description="rebate in the form of a check"
                        />
                      </b>
                    ),
                    bold4: (
                      <b>
                        <FormattedMessage
                          id="any"
                          defaultMessage="any"
                          description="any"
                        />
                      </b>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqRebateCOReceiveRebateAnswerThree"
                  defaultMessage="Full terms and conditions for the EV Rebate program can be found with the rebate application here."
                  description="Full terms and conditions for the EV Rebate program can be found with the rebate application here."
                  values={{
                    link: (
                      <a
                        href="https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-533_CO-EV-CarRebate_app_P03.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="here"
                          defaultMessage="here"
                          description="here"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOChargeMyEV"
                defaultMessage="Where can I charge my EV?"
                description="Where can I charge my EV?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOChargeMyEVAnswer"
                  defaultMessage="You can find lots of places to charge your EV when you’re on the go. Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                  description="Where can I charge EV answer"
                  values={{
                    link: (
                      <a
                        href="/charging-stations"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="lookForPublicChargingExamples"
                          defaultMessage="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                          description="Look for public charging at grocery stores, hotels, parking ramps, parks and more."
                        />
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  id="faqRebateCOChargeMyEVAnswerTwo"
                  defaultMessage="Charging your electric vehicle at home is easy with our home charging programs."
                  description="Where can I charge EV answer two"
                  values={{
                    link: (
                      <a
                        href="/ev-charging-programs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="ourHomeChargingPrograms"
                          defaultMessage="our home charging programs"
                          description="our home charging programs"
                        />
                      </a>
                    ),
                  }}
                />
                <ul>
                  <li>
                    <FormattedMessage
                      id="faqRebateCOEVAAH"
                      defaultMessage="With EV Accelerate At Home, we install a Level 2 EV charger at your home and maintain it, all for a low monthly fee."
                      description="With EV Accelerate At Home, we install a Level 2 EV charger at your home and maintain it, all for a low monthly fee."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="faqRebateCOOYC"
                      defaultMessage="With Optimize Your Charge, you choose from three off-peak charging schedule options and get a $50 annual credit on your electric bill."
                      description="With Optimize Your Charge, you choose from three off-peak charging schedule options and get a $50 annual credit on your electric bill."
                    />
                  </li>
                </ul>
              </p>
              <p>
                <FormattedMessage
                  id="faqRebateCOChargeMyEVAnswerThree"
                  defaultMessage="And be sure to check out our Home Wiring Rebate. If you meet our income requirements you can get $1,300 toward the cost of installing a Level 2 charger at your home.  More information about charging your EV can be found here: EV Charging 101."
                  description="Where can I charge EV answer two"
                  values={{
                    lineBreak: <br />,
                    link1: (
                      <a
                        href="/home-wiring-rebate"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="faqRebateCOHomeWiringRebate"
                          defaultMessage="Home Wiring Rebate"
                          description="Home Wiring Rebate"
                        />
                      </a>
                    ),
                    link2: (
                      <a
                        href="/charging-101"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage
                          id="evChargingOneZeroOne"
                          defaultMessage="EV Charging 101"
                          description="EV Charging 101"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
        {
          headLine: (
            <span className="headline">
              <FormattedMessage
                id="faqRebateCOPayTaxes"
                defaultMessage="Do I have to pay taxes on my rebate?"
                description="Do I have to pay taxes on my rebate?"
              />
            </span>
          ),
          body: (
            <div>
              <p>
                <FormattedMessage
                  id="faqRebateCOPayTaxesAnswer"
                  defaultMessage="If you buy an EV from a dealer outside our EV Dealer Network, the rebate cannot be provided instantly, and you may have to pay income tax on the rebate amount. You have 2 options:"
                  description="Have to pay taxes answer"
                />
              </p>
              <p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="faqRebateCOPayTaxesAnswerOption1"
                      defaultMessage="If you get prequalified and then buy from a dealer outside of our EV Dealer Network, you can receive the rebate in the form of a check from us, which you will receive within 6-8 weeks of providing proof of purchase and a completed IRS Form W9."
                      description="If you get prequalified and then buy from a dealer outside of our EV Dealer Network, you can receive the rebate in the form of a check from us, which you will receive within 6-8 weeks of providing proof of purchase and a completed IRS Form W9."
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="faqRebateCOPayTaxesAnswerOption2"
                      defaultMessage="If you’ve already purchased your vehicle and you want to see if you can still receive the rebate, you can submit the rebate application and if we determine that you are income-qualified, you’ve purchased an eligible vehicle, and you submit a completed IRS Form W9, we’ll send you a rebate check within 6-8 weeks."
                      description="If you’ve already purchased your vehicle and you want to see if you can still receive the rebate, you can submit the rebate application and if we determine that you are income-qualified, you’ve purchased an eligible vehicle, and you submit a completed IRS Form W9, we’ll send you a rebate check within 6-8 weeks."
                    />
                  </li>
                </ul>
              </p>
            </div>
          ),
          applicableTo: [INCOME_QUALIFIED_COLORADO],
        },
      ].filter(byApplicable(faqKey));
  }
};
