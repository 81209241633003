import { useRef, useState } from "react";
import useDeepCompareEffect from "./useDeepCompareEffect";

const useGeoJsonUrls = (urls) => {
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapRef = useRef();
  const geojsonFeatures = useRef([]);

  useDeepCompareEffect(() => {
    const addFeatures = async (url) => {
      try {
        const response = await window.fetch(url);
        const data = await response.json();
        const newFeatures = mapRef.current.data.addGeoJson(data);
        geojsonFeatures.current = [...geojsonFeatures.current, ...newFeatures];
      } catch (e) {
        // Do nothing... just make sure the user doesn't see an error in the JS console
      }
    };

    if (mapRef.current && isMapLoaded) {
      // If urls is non-empty and then changes, remove existing features from map.
      geojsonFeatures.current.forEach((feature) => {
        mapRef.current.data.remove(feature);
      });
      geojsonFeatures.current = [];

      urls.forEach((url) => {
        addFeatures(url);
      });
    }
  }, [urls, isMapLoaded]);

  const registerGeojsonUrlMap = (map) => {
    mapRef.current = map;
    map.data.setStyle({
      fillColor: "#d13d3d",
      fillOpacity: 0.4,
      strokeColor: "#d13d3d",
      strokeOpacity: 0.9,
      strokeWeight: 1,
    });
    setIsMapLoaded(true);
  };

  return registerGeojsonUrlMap;
};

export default useGeoJsonUrls;
