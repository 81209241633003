import React from "react";
import PropTypes from "prop-types";
import Uuid from "../../../utils/Uuid/Uuid"

import "./ComparedVehicleToggle.scss";

const Buttons = ({
  comparedVehicles,
  onChangeVehicleNumber,
  selectedVehicleNumber
}) => {
  return comparedVehicles.map((vehicle, i) => {
    let btnClasses = ["btn"];
    if (selectedVehicleNumber === i) btnClasses.push("active");
    return (  
      <button
        style={{display: "flex", justifyContent: "center"}}
        type="button"
        className={btnClasses.join(" ")}
        onClick={() => onChangeVehicleNumber(i)}
        key={Uuid()}
      >
        <small className="btn-select">{
            (vehicle != null)?`${vehicle.make} ${vehicle.model} ${vehicle.trim}`.trim() : "Please Select A Vehicle"
            }
        </small>
      </button>
    );
  });
};

const ComparedVehicleToggle = ({
  comparedVehicles,
  selectedVehicleNumber,
  onChangeVehicleNumber
}) => {
  return (
    <div className="ComparedVehicleToggle d-flex d-md-none d-lg-none btn-group">
      <Buttons
        comparedVehicles={comparedVehicles}
        selectedVehicleNumber={selectedVehicleNumber}
        onChangeVehicleNumber={onChangeVehicleNumber}
      />
    </div>
  );
};

ComparedVehicleToggle.propTypes = {
  comparedVehicles: PropTypes.array,
  onChangeVehicleNumber: PropTypes.func,
  selectedVehicleNumber: PropTypes.number
};

ComparedVehicleToggle.defaultProps = {
  comparedVehicles: []
};

export default ComparedVehicleToggle;
