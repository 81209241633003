import React from 'react';
import './Table.scss';

const Table = ({ data }) => {
  return (
    <table className="tableDetail">
      {data.map((column) => (
        <>
          <tr className="tableColumn">
            {column.map((cell) => (
              <td className="tableCell">
                <div className="label">{cell.title}</div>
                <div className="value">{cell.value}</div>
              </td>
            ))}
          </tr>
        </>
      ))}
    </table>
  );
};

export default Table;
