// import coastalLoadProfile from "./coastalLoadProfile.json";
// import mountainLoadProfile from "./mountainLoadProfile.json";
// import desertLoadProfile from "./desertLoadProfile.json";
// import inlandLoadProfile from "./inlandLoadProfile.json";
import typicalLoadProfile from "./typicalLoadProfile.json";

import {
  COASTAL,
  MOUNTAIN,
  DESERT,
  INLAND,
} from "../../constants/chargingClimateZones";

//OBS: We gonna change all to the same date duo client request to not separete bu climate zone
//TODO: Improve the logic and clean the code. Made like this duo the tiny deadline to code and align

const loadProfiles = {
  COASTAL: {
    rateGroup: "TIERED",
    climateZone: COASTAL,
    nemStatus: "NON-NEM",
    year: 2021,
    data: typicalLoadProfile,
  },
  MOUNTAIN: {
    rateGroup: "TIERED",
    climateZone: MOUNTAIN,
    nemStatus: "NON-NEM",
    year: 2021,
    data: typicalLoadProfile,
  },
  DESERT: {
    rateGroup: "TIERED",
    climateZone: DESERT,
    nemStatus: "NON-NEM",
    year: 2021,
    data: typicalLoadProfile,
  },
  INLAND: {
    rateGroup: "TIERED",
    climateZone: INLAND,
    nemStatus: "NON-NEM",
    year: 2021,
    data: typicalLoadProfile,
  },
};

const getLoadProfileData = ({ rateGroup, climateZone, hasSolar = false }) => {
  return loadProfiles[climateZone];
};

export default getLoadProfileData;