// Source: https://docs.google.com/document/d/1IxO36l_f6CJGLjfmNWTvziNJv7trLSTVOLaI9NoIkj0/edit

import capitalizedInLease from "../../../incentives/capitalization/capitalizedInLease";
import nonCapitalizedInLease from "../../../incentives/capitalization/nonCapitalizedInLease";
import capitalizedInPurchase from "../../../incentives/capitalization/capitalizedInPurchase";

import ResidualValue from "../../../financial/calcResidualValue";
import LoanPayment from "../../../financial/calcLoanPayment";


const VehicleCost = {
  msrp: function (vehicle) {
    if (!vehicle) return 0;

    return vehicle.msrp && parseInt(vehicle.msrp, 10) > 0
      ? parseInt(vehicle.msrp, 10)
      : 0;
  },

  msrpPlusTax: function (vehicle, salesTax) {
    return this.msrp(vehicle) * (1 + salesTax / 100000);
  },

  afterIncentives: function (vehicle) {
    const incentives = capitalizedInPurchase(vehicle);
    return this.msrp(vehicle) - incentives;
  },

  downPayment: function (vehicle, salesTax, downPayment) {
    return this.msrpPlusTax(vehicle, salesTax) * (downPayment / 100);
  },

  resaleValue: function (vehicle, lifetimeMiles) {
    return this.msrp(vehicle) * ResidualValue(lifetimeMiles);
  },

  incentives: function (vehicle, purchaseMethod) {
    return purchaseMethod === 'lease'
      ? capitalizedInLease(vehicle)
      : capitalizedInPurchase(vehicle);
  },

  nonCapitalizedLeaseIncentives: function (vehicle) {
    return vehicle && vehicle.incentives ? nonCapitalizedInLease(vehicle) : 0;
  },

  loanAmountToBeFinanced: function (vehicle, salesTax, downPayment) {
    return (
      this.msrpPlusTax(vehicle, salesTax) -
      this.downPayment(vehicle, salesTax, downPayment)
    );
  },

  monthlyLoanPayment: function (
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    return LoanPayment.monthly(
      interestRateAsBasisPoints,
      monthsOfOwnership,
      this.loanAmountToBeFinanced(vehicle, salesTax, downPayment)
    );
  },

  monthlyLoanPaymentsTotal: function (
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    return (
      monthsOfOwnership *
      this.monthlyLoanPayment(
        vehicle,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax,
        downPayment
      )
    );
  },

  moneyFactor: function (interestRateAsBasisPoints) {
    return interestRateAsBasisPoints / 240000;
  },

  leaseCapitalizedCost: function (vehicle, salesTax, downPayment) {
    return (
      this.msrp(vehicle) -
      this.incentives(vehicle, 'lease')
       -
      this.downPayment(vehicle, salesTax, downPayment)
    );
  },

  monthlyDepreciationCost: function (
    vehicle,
    lifetimeMiles,
    monthsOfOwnership,
    salesTax,
    downPayment
  ) {
    return (
      (this.leaseCapitalizedCost(vehicle, salesTax, downPayment) -
        this.resaleValue(vehicle, lifetimeMiles)) /
      monthsOfOwnership
    );
  },

  monthlyFinancingCost: function (
    vehicle,
    lifetimeMiles,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    return (
      (this.leaseCapitalizedCost(vehicle, salesTax, downPayment) +
        this.resaleValue(vehicle, lifetimeMiles)) *
      this.moneyFactor(interestRateAsBasisPoints)
    );
  },

  monthlyLeaseDepreciationAndFinancingCosts: function (
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;
    return (
      this.monthlyDepreciationCost(
        vehicle,
        lifetimeMiles,
        monthsOfOwnership,
        salesTax,
        downPayment
      ) +
      this.monthlyFinancingCost(
        vehicle,
        lifetimeMiles,
        interestRateAsBasisPoints,
        salesTax,
        downPayment
      )
    );
  },

  monthlyLeasePayment: function (
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    return (
      (1 + salesTax / 100) *
      this.monthlyLeaseDepreciationAndFinancingCosts(
        vehicle,
        annualMiles,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax,
        downPayment
      )
    );
  },

  firstLeasePayment: function (
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    salesTax,
    downPayment
  ) {
    return (
      this.downPayment(vehicle, salesTax, downPayment) +
      this.monthlyLeasePayment(
        vehicle,
        annualMiles,
        monthsOfOwnership,
        interestRateAsBasisPoints,
        salesTax,
        downPayment
      )
    );
  },

  total: function (
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue,
    salesTax,
    downPayment
  ) {
    if (!vehicle) return 0;

    const lifetimeMiles = (annualMiles * monthsOfOwnership) / 12;

    let cost = 0;

    const resaleValue = includeResaleValue
      ? this.resaleValue(vehicle, lifetimeMiles)
      : 0;

    switch (purchaseMethod) {
      case 'loan':
        cost =
          this.downPayment(vehicle, salesTax, downPayment) +
          this.monthlyLoanPaymentsTotal(
            vehicle,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            salesTax,
            downPayment
          ) -
          this.incentives(vehicle, 'loan') -
          resaleValue;
        break;
      case 'lease':
        cost =
          this.firstLeasePayment(
            vehicle,
            annualMiles,
            monthsOfOwnership,
            interestRateAsBasisPoints,
            salesTax,
            downPayment
          ) +
          (monthsOfOwnership - 1) *
            this.monthlyLeasePayment(
              vehicle,
              annualMiles,
              monthsOfOwnership,
              interestRateAsBasisPoints,
              salesTax,
              downPayment
            ) -
          this.nonCapitalizedLeaseIncentives(vehicle);
        break;
      default:
        // case 'cash'
        cost =
          this.msrpPlusTax(vehicle, salesTax) -
          this.incentives(vehicle, 'cash') -
          resaleValue;
    }
    return isFinite(cost) ? cost : 0;
  },

  perMile: function (
    vehicle,
    annualMiles,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    purchaseMethod,
    includeResaleValue,
    salesTax,
    downPayment
  ) {
    if (!vehicle) return 0;

    const totalVehicleCost = this.total(
      vehicle,
      annualMiles,
      monthsOfOwnership,
      interestRateAsBasisPoints,
      purchaseMethod,
      includeResaleValue,
      salesTax,
      downPayment
    );
    const cost = totalVehicleCost / (annualMiles / 12) / monthsOfOwnership;

    return isFinite(cost) ? cost : 0;
  },
};

export default VehicleCost;