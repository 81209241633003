import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import FaqComponent from '../../components/Faq/FaqComponent';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import faqImage1 from "../../client_customizations/assets/images/faqImage1.svg";
import faqImage2 from "../../client_customizations/assets/images/faqImage2.svg";
import faqImage3 from "../../client_customizations/assets/images/faqImage3.svg";
import faqImage4 from "../../client_customizations/assets/images/faqImage4.svg";
import faqImage5 from "../../client_customizations/assets/images/faqImage5.svg";
import FAQCard from '../../client_customizations/components/FAQCard/FAQCard';
import './FAQ.scss';


function getSortBySection(faqs, language) {
  let faqsSections = {};

  faqs.forEach((faq) => {
    if (!faqsSections[faq.section])
      faqsSections[faq.section] = {
        title: language === "EN" ? faq.section : faq.sectionSP,
        faqs: [faq],
      };
    else {
      faqsSections[faq.section].faqs.push(faq);
    }
  });

  return faqsSections;
}


const FAQ = ({ faqs, language }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_FAQ_TITLE;
  });

  if (!faqs)
    return (
      <div className="spinner-container">
        <LoadingSpinner />
      </div>
    );


  const sortedWithSections = getSortBySection(faqs, language);
  return (
    <div className="FAQ">
      <section className="container white" style={{ paddingBottom: '0' }}>
        <div className="row">
          <div className="faq-jumbotron">
            <h1 className="title-faq pad-top">
              <FormattedMessage
                id="FAQ.title"
                defaultMessage="Frequently Asked Questions"
                description="Frequently Asked Questions"
              />
            </h1>
            <h2 className="subtitle-faq mb-bottom pad-bottom">
              <FormattedMessage
                id="FAQ.subtitle"
                defaultMessage="EVs are still new, and it’s natural to have questions. Find answers below."
                description="EVs are still new, and it’s natural to have questions. Find answers below."
              />
            </h2>
          </div>
        </div>
      </section>
      <section className='container'>
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleOne"
              defaultMessage="What Is an EV and How Does It Work?"
              description="What Is an EV and How Does It Work?"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyOne"
              defaultMessage="An electric vehicle (EV) is a type of vehicle that uses electricity as its primary source of power, rather than gasoline or diesel fuel. EVs have become increasingly popular in recent years due to their potential to reduce emissions and lower operating costs compared to traditional gasoline-powered vehicles.{lineBreak} So, how does an EV work? An EV is powered by an electric motor, which is in turn powered by a battery. The battery stores electricity, which is used to power the motor and turn the wheels of the vehicle. When the vehicle is in use, the battery is slowly depleted. To recharge the battery, the EV must be plugged into an electric power source, such as a wall outlet or a public charging station. {lineBreak}There are two main types of EVs: battery electric vehicles (BEVs) and plug-in hybrid electric vehicles (PHEVs). BEVs are powered exclusively by electricity, while PHEVs have both an electric motor and a gasoline engine. PHEVs can switch between the two power sources as needed, allowing them to extend their range beyond what is possible with electricity alone."
              description="An electric vehicle (EV) is a type of vehicle that uses electricity as its primary source of power, rather than gasoline or diesel fuel. EVs have become increasingly popular in recent years due to their potential to reduce emissions and lower operating costs compared to traditional gasoline-powered vehicles. {lineBreak} So, how does an EV work? An EV is powered by an electric motor, which is in turn powered by a battery. The battery stores electricity, which is used to power the motor and turn the wheels of the vehicle. When the vehicle is in use, the battery is slowly depleted. To recharge the battery, the EV must be plugged into an electric power source, such as a wall outlet or a public charging station. {lineBreak} There are two main types of EVs: battery electric vehicles (BEVs) and plug-in hybrid electric vehicles (PHEVs). BEVs are powered exclusively by electricity, while PHEVs have both an electric motor and a gasoline engine. PHEVs can switch between the two power sources as needed, allowing them to extend their range beyond what is possible with electricity alone."
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage1}
        />
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleTwo"
              defaultMessage="EV Charging 101"
              description="EV Charging 101"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyTwo"
              defaultMessage="Electric vehicle (EV) charging is categorized into three levels: Level 1, Level 2, and Level 3 (DC Fast Charging). {lineBreak} Level 1 charging uses a standard 120-volt household outlet, providing the slowest charge, typically adding 3-5 miles of range per hour, making it suitable for overnight charging at home. EVs typically come with Level 1 chargers included. {lineBreak} Level 2 charging utilizes a 240-volt outlet like those used for large appliances. It delivers a faster charge, adding about 10-60 miles of range per hour, and is commonly found in homes, workplaces, and public charging stations. Installation of a level 2 charger should be completed by a professional electrician. {lineBreak} Finally, level 3--or DC Fast Charging--employs direct current (DC) and high-power stations (usually 400 volts or more), providing the fastest charge. It is capable of adding 60-100 miles of range in just 20 minutes and is primarily used in commercial settings and along highways for quick recharges."
              description="Electric vehicle (EV) charging is categorized into three levels: Level 1, Level 2, and Level 3 (DC Fast Charging). {lineBreak} Level 1 charging uses a standard 120-volt household outlet, providing the slowest charge, typically adding 3-5 miles of range per hour, making it suitable for overnight charging at home. EVs typically come with Level 1 chargers included. {lineBreak} Level 2 charging utilizes a 240-volt outlet like those used for large appliances. It delivers a faster charge, adding about 10-60 miles of range per hour, and is commonly found in homes, workplaces, and public charging stations. Installation of a level 2 charger should be completed by a professional electrician. {lineBreak} Finally, level 3--or DC Fast Charging--employs direct current (DC) and high-power stations (usually 400 volts or more), providing the fastest charge. It is capable of adding 60-100 miles of range in just 20 minutes and is primarily used in commercial settings and along highways for quick recharges."
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage2}
        />
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleThree"
              defaultMessage="Alleviate EV Range Anxiety"
              description="Alleviate EV Range Anxiety"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyThree"
              defaultMessage="Electric vehicles (EVs) vary significantly in how far they can travel on a single charge, depending on factors such as battery capacity, vehicle efficiency, and driving conditions. Most modern EVs offer ranges between 150 and 300 miles per charge, with some high-end models exceeding 400 miles. Technological advancements and increasing investments in battery technology are continually pushing these limits, improving energy density and efficiency.{lineBreak} If you do need to charge up on the road, finding a public charging station for electric vehicles (EVs) is becoming easier due to the rapid expansion of charging infrastructure. In urban areas and along major highways, charging stations are relatively common, with networks like Tesla Superchargers, ChargePoint, and Electrify America providing widespread coverage. Many EVs come equipped with navigation systems that include charging station locations, and numerous smartphone apps and websites also help drivers locate nearby chargers, check their availability, and even start charging sessions remotely. While rural and less populated areas may still have fewer options, the continuous growth of the EV market is driving further investment in charging infrastructure, making it more convenient for EV owners to find public charging stations across diverse locations."
              description="Electric vehicles (EVs) vary significantly in how far they can travel on a single charge, depending on factors such as battery capacity, vehicle efficiency, and driving conditions. Most modern EVs offer ranges between 150 and 300 miles per charge, with some high-end models exceeding 400 miles. Technological advancements and increasing investments in battery technology are continually pushing these limits, improving energy density and efficiency.{lineBreak} If you do need to charge up on the road, finding a public charging station for electric vehicles (EVs) is becoming easier due to the rapid expansion of charging infrastructure. In urban areas and along major highways, charging stations are relatively common, with networks like Tesla Superchargers, ChargePoint, and Electrify America providing widespread coverage. Many EVs come equipped with navigation systems that include charging station locations, and numerous smartphone apps and websites also help drivers locate nearby chargers, check their availability, and even start charging sessions remotely. While rural and less populated areas may still have fewer options, the continuous growth of the EV market is driving further investment in charging infrastructure, making it more convenient for EV owners to find public charging stations across diverse locations."
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage3}
        />
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleFour"
              defaultMessage="EV Benefits: Environmental, Cost, & Maintenance"
              description="EV Benefits: Environmental, Cost, & Maintenance"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyFour"
              defaultMessage="One of the main benefits of EVs is their potential to reduce emissions. Because they are powered by electricity, rather than fossil fuels, they produce no tailpipe emissions when in use. This makes them a cleaner alternative to traditional gasoline-powered vehicles. In addition, EVs are typically more energy efficient than gasoline-powered vehicles, which can further reduce their environmental impact.{lineBreak} Another advantage of EVs is their low operating costs. Because they have fewer moving parts than gasoline-powered vehicles, they may require less maintenance and consequently have longer lifespans. In addition, electricity is generally cheaper than gasoline, so operating an EV can be more cost-effective in the long run. There are also several incentives and discounts available for EV owners, including federal and state tax credits, HOV lane access, and reduced tolls. These incentives can further lower the cost of ownership."
              description="One of the main benefits of EVs is their potential to reduce emissions. Because they are powered by electricity, rather than fossil fuels, they produce no tailpipe emissions when in use. This makes them a cleaner alternative to traditional gasoline-powered vehicles. In addition, EVs are typically more energy efficient than gasoline-powered vehicles, which can further reduce their environmental impact.{lineBreak} Another advantage of EVs is their low operating costs. Because they have fewer moving parts than gasoline-powered vehicles, they may require less maintenance and consequently have longer lifespans. In addition, electricity is generally cheaper than gasoline, so operating an EV can be more cost-effective in the long run. There are also several incentives and discounts available for EV owners, including federal and state tax credits, HOV lane access, and reduced tolls. These incentives can further lower the cost of ownership."
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage4}
        />
        <FAQCard
          title={
            <FormattedMessage
              id="FAQ.sectionTitleFive"
              defaultMessage="EV Safety"
              description="EV Safety"
            />
          }
          body={
            <FormattedMessage
              id="FAQ.bodyFive"
              defaultMessage="Electric vehicles (EVs) are generally considered safe, meeting or exceeding the safety standards of traditional gasoline-powered vehicles. They undergo rigorous testing and must comply with the same safety regulations. EVs have some inherent safety advantages, such as a lower center of gravity due to the battery placement, which can reduce the risk of rollovers. Additionally, they lack a combustible fuel tank, minimizing the risk of fire in the event of a collision. Many EVs are also equipped with advanced safety features, including collision avoidance systems, automatic emergency braking, and lane-keeping assistance. {lineBreak} To further ensure a safe and enjoyable experience with your EV, take time to familiarize yourself with your vehicle to understand your EV’s features including safety systems, charging procedures, and emergency protocols. The owner’s manual is a valuable resource. Also make sure to maintain your EV by keeping up with regular maintenance as recommended by the manufacturer. This includes checking the battery, brakes, tires, and electrical systems. Finally, employ safe charging practices through using designated charging stations and avoiding extension cords for home charging. Ensure your home charging setup is installed by a qualified electrician and follows all safety guidelines."
              description="Electric vehicles (EVs) are generally considered safe, meeting or exceeding the safety standards of traditional gasoline-powered vehicles. They undergo rigorous testing and must comply with the same safety regulations. EVs have some inherent safety advantages, such as a lower center of gravity due to the battery placement, which can reduce the risk of rollovers. Additionally, they lack a combustible fuel tank, minimizing the risk of fire in the event of a collision. Many EVs are also equipped with advanced safety features, including collision avoidance systems, automatic emergency braking, and lane-keeping assistance. {lineBreak} To further ensure a safe and enjoyable experience with your EV, take time to familiarize yourself with your vehicle to understand your EV’s features including safety systems, charging procedures, and emergency protocols. The owner’s manual is a valuable resource. Also make sure to maintain your EV by keeping up with regular maintenance as recommended by the manufacturer. This includes checking the battery, brakes, tires, and electrical systems. Finally, employ safe charging practices through using designated charging stations and avoiding extension cords for home charging. Ensure your home charging setup is installed by a qualified electrician and follows all safety guidelines."
              values={{
                lineBreak: (
                  <p style={{ margin: '5px' }}>
                    <br />
                  </p>
                ),
              }}
            />
          }
          image={faqImage5}
        />
      </section>
      <section className="container">
        <div className="row faqContainerPage">
          {Object.values(sortedWithSections).map((section) => (
            <div key={`faq-${section.title}`}>
              <h2>{section.title}</h2>
              <FaqComponent faqs={section.faqs} language={language}/>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FAQ;

FAQ.propTypes = {
  faqs: PropTypes.array,
  language: PropTypes.string
};
