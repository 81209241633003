import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import EVIncentives from "../../components/EVIncentives/EVIncentives";
import EVJumbotron from "../../components/EVJumbotron/EVJumbotron";
import EVReviews from "../../components/EVReviews/EVReviews";
import ChargingMap from "../../components/LocationMap/ChargingMap";
import CostOfOwnership from "../../components/CostOfOwnership/CostOfOwnership";
// import getPaymentDetails from "../../functions/vehicle/getPaymentDetails";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import isPHEV from "../../functions/vehicle/isPHEV";
// import { FormatCarName } from "../../utils/Helpers/Format";
// import { set } from "react-ga";
import { FormattedMessage } from 'react-intl';

const UsedEV = ({
  match,
  electricVehicles,
  userLocation,
  ip,
  uuid,
  zipcode
}) => {
  // const userPrefs = useContext(UserPrefsContext);
  const [usedEv, setUsedEv] = useState(null)
  const [usedEvs, setUsedEvs] = useState(null)

  // let usedEvs = []
  let car
  // let make
  // let model
  // let allUsedEvs

  // console.log(make)
  // const setElectricVehicles = () => {
  //   if (electricVehicles) {
  //   const evId = match ? match.params["evId"].split(":") : "";
  //   make = evId[0]
  //   model = evId[1].replace(/_/g, ' ')

  //   allUsedEvs = electricVehicles.filter(ev => {
  //     return ev.make === make && ev.model === model
  //   })
  //   setUsedEv(allUsedEvs[0])
  //   setUsedEvs(allUsedEvs)
  //   }
  // } 

  useEffect(() => {

    const setElectricVehicles = () => {
      if (electricVehicles) {
        const evId = match ? match.params["evId"].split(":") : "";
        const make = evId[0].replace(/_/g, ' ')
        const model = evId[1].replace(/_/g, ' ')
        const trim = evId[2].replace(/_/g, ' ')

        const allUsedEvs = electricVehicles.filter(ev => {
          return ev.make === make && ev.model === model && ev.trim === trim
        })
        setUsedEv(allUsedEvs[0])
        setUsedEvs(allUsedEvs)

        document.title = make
          ? `Used Vehicle: ${make} ${model}`
          : "";
      }
    }
    setElectricVehicles()
  }, [electricVehicles, match]);

  const ignoreSuperchargerStations = car && isPHEV(car);

  const renderEV = usedEv ? (
    <>
      <EVJumbotron
        car={usedEv}
        incentives={usedEv.incentives}
        cars={usedEvs}
        setUsedEv={setUsedEv}
      />
      <CostOfOwnership cars={[usedEv, usedEv.equivalent_gas_vehicle]} usedEv={true} zipcode={zipcode}/>
      <EVIncentives incentives={usedEv.incentives} car={usedEv} usedEv={true} />
      <div className="container-fluid" style={{ backgroundColor: "#f2f2f2" }}>
        <div className="container pb-4">
          <p className="h1 pt-4 mb-3 text-center">
            <FormattedMessage
              id="chargingStations"
              defaultMessage="Charging Stations"
              description="Charging Stations"
            />
          </p>
          <div style={{
            borderRadius: "3px",
            border: "1px solid #dee2e6",
            boxShadow: "0 2px 5px 0 rgba(0,0,0,.15)",
            padding: "20px",
            backgroundColor: "#fff"
          }}>
            <ChargingMap
              userLocation={userLocation}
              zipcode={zipcode}
              ignoreSuperchargerStations={ignoreSuperchargerStations}
            />
          </div>
        </div>
      </div>
      <EVReviews reviews={usedEv.video_reviews} />
    </>
  ) : (
    <div className="container text-center">
      <LoadingSpinner />
    </div>
  );

  return (
    <>
      {renderEV}
    </>
  );
};

export default UsedEV;

UsedEV.propTypes = {
  match: PropTypes.object,
  electricVehicles: PropTypes.array,
  userLocation: PropTypes.object,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
