import React, { useContext } from 'react';
import Select from '../../../../components/shared/InputElements/Select';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import { FormattedMessage } from 'react-intl';

const SelectChargingPattern = ({ chargingPatterns }) => {
  // const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  // NOTE: If necessary, create translations for each pattern id. So far, using the own title
  return (
    <Select
      id="home-charging-advisor-charging-pattern"
      value={userPrefs.get('chargingPatternId')}
      optionNames={chargingPatterns.map(p => p.title )}
      optionValues={chargingPatterns.map(p => p.id)}
      handler={(e) => userPrefs.set({ chargingPatternId: e.target.value })}
      label={<FormattedMessage
        id="hcaWhenWillYouCharge"
        defaultMessage="Charging Pattern"
        description="Charging Pattern"
      />}
    />
  );
};

export default SelectChargingPattern;
