import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import "./RateDetails.scss";
import { useIntl } from "react-intl";

const RateDetails = ({ rates }) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const selectedRateDetailsId = userPrefs.get('selectedRateDetailsId');

  const selectedRate = rates.find((rate) => rate.id === selectedRateDetailsId);
  const SelectedDetailsComponent =
    (selectedRate || {}).detailsComponent || (() => null);

  const getName = (rate) => {
    if(rate.id === 'RS') {
      return 'Residential Service'
    }

    if(rate.id === 'RLM') {
      return 'Residential Load Management'
    }

    return `${rate.title} ${rate.subTitle}`
  }

  return (
    <div className="chart-details-container">
      <div>
        <div className="d-flex mb-4">
          {rates.map((rate) => {
            return (
              <button
                className={`btn chartBtn-${
                  rate.id === selectedRateDetailsId ? 'ae' : 'secondary'
                } mr-2`}
                key={rate.id}
                onClick={() =>
                  userPrefs.set({ selectedRateDetailsId: rate.id })
                }
              >
                {getName(rate)}
              </button>
            );
          })}
        </div>
        <div>
          <SelectedDetailsComponent />
          <a
            href="https://nj.pseg.com/aboutpseg/regulatorypage/electrictariffs"
            className="cta-btn"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage({
              id: 'rateDetails.learnMore',
              defaultMessage: 'Learn More',
            })}
          </a>
        </div>
      </div>
    </div>
  );
};

export default RateDetails;
