import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import ControlledInputZipcode from '../../../components/InputComponents/InputZipcode/ControlledInputZipcode';
import SlideHouseholdIncome from '../../../components/InputComponents/SlideHouseholdIncome/SlideHouseholdIncome';
import SelectHouseholdSize from '../InputComponents/SelectHouseholdSize/SelectHouseholdSize';
import ChooseTaxFilingStatus from '../InputComponents/ChooseTaxFilingStatus/ChooseTaxFilingStatus.js';
import ChooseCanTurnInClunker from '../InputComponents/ChooseCanTurnInClunker/ChooseCanTurnInClunker';
import SelectElectricityProvider from '../InputComponents/SelectElectricityProvider/SelectElectricityProvider';
import SelectVehicleMakeFilter from '../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter';
import SelectVehicleModelFilter from '../../../components/SelectVehicleModelFilter/SelectVehicleModelFilter';
import SelectVehicleTrimFilter from '../InputComponents/SelectVehicleTrimFilter/SelectVehicleTrimFilter';
import SelectPurchaseMethod from '../../../components/InputComponents/SelectPurchaseMethod/SelectPurchaseMethod';

import ToolTip from '../../../components/shared/ToolTip/ToolTip';
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';
import { ValidateFiveDigitCode } from '../../../utils/Helpers/USPostalCodeValidator';

import { FormattedMessage, useIntl } from 'react-intl';

import './IncentiveScoreOptions.scss';

const IncentiveScoreOptions = ({ userLocation, electricVehicles, mobile }) => {
  const intl=useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const [workingZipcode, setWorkingZipcode] = useState(
    userPrefs.get('zipcode')
  );
  
  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };
  const RenderItem = ({ component, tooltip_id, title_id, message }) => (
    <div style={{lineHeight: "0px"}}>
      <div className="incentive-label-select">
        <FormattedMessage
          id={title_id}
          defaultMessage={'Item Title'}
          description={'Item Title'}
        />
        {tooltip_id ? <ToolTip message={message} id={tooltip_id} /> : null}
      </div>
      {component}
    </div>
);

  return (
    <div className="input-well MatchScoreOptions">
      {!mobile ? (
        <p className="score-options-header">
          <FormattedMessage
            id="evs.incentivesFilterHeader"
            defaultMessage="Eligibility Filters"
            description="Eligibility Filters"
          />
        </p>
      ) : null}
      <div style={{ marginBottom: '20px' }}>
        <ControlledInputZipcode
          zipcode={workingZipcode}
          handleSubmit={(e) => {
            handleFormSubmit();
          }}
          workingZipcode={workingZipcode}
          setZipcode={(e) => {
            setWorkingZipcode(e.target.value);
            if (ValidateFiveDigitCode(e.target.value)) {
              userPrefs.set({ workingZipcode: e.target.value });
            }
          }}
          id="input-zipcode-for-rate-comp-launch"
          isUpdating={userPrefs.zipcodeIsUpdating}
          isNotFound={userPrefs.zipcodeIsNotFound}
          hideValidateRequest
          userLocation={userLocation}
        />
      </div>
      <RenderItem
        component={<SelectElectricityProvider location={userLocation} />}
        title_id={'incentives.electricityProvider'}
      />
      <SelectVehicleMakeFilter vehicles={electricVehicles} />
      <SelectVehicleModelFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get('vehicleMakeFilter')}
      />
      <SelectVehicleTrimFilter
        vehicles={electricVehicles}
        makeOption={userPrefs.get('vehicleMakeFilter')}
        modelOption={userPrefs.get('vehicleModelFilter')}
      />
      <SelectPurchaseMethod incentives={true} />
      <RenderItem
        component={<ChooseCanTurnInClunker />}
        tooltip_id="trade_in_tooltip"
        title_id={'incentives.tradeIn'}
        message={intl.formatMessage({
          id: 'incentives.retiringVehicleTooltip',
          defaultMessage:
            'Retiring an old gas vehicle may be required for certain incentives.',
        })}
      />
      <RenderItem
        component={<SlideHouseholdIncome />}
        tooltip_id="household_income_tooltip"
        title_id={'incentives.householdIncome'}
        message={intl.formatMessage({
          id: 'incentives.householdIncomeTooltip',
          defaultMessage:
            'Combine income from all household members. Used to determine incentive eligibility.',
        })}
      />
      <RenderItem
        component={<SelectHouseholdSize />}
        tooltip_id="household_size_tooltip"
        title_id={'incentives.householdSize'}
        message={intl.formatMessage({
          id: 'incentives.householdSizeTooltip',
          defaultMessage:
            'Include all members of your household (spouse, deoendants, etc). Used to determine incentived eligibility.',
        })}
      />
      <RenderItem
        component={<ChooseTaxFilingStatus />}
        tooltip_id="filing_status_tooltip"
        title_id={'incentives.taxFilingStatus'}
        message={intl.formatMessage({
          id: 'incentives.taxFilingStatusTooltip',
          defaultMessage: 'Used to determine incentive eligibility.',
        })}
      />
    </div>
  );
};

export default IncentiveScoreOptions;

IncentiveScoreOptions.propTypes = {
  electricVehicles: PropTypes.array,
};
