import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import "./Inventory.scss";
import { NavLink } from "react-router-dom";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import fetchInventory from "../../services/fetchInventory";
import InventoryFilterControls from "../../components/InventoryFilterControls/InventoryFilterControls";
import { FormattedMessage } from "react-intl";

import InventoryDealersFilter from "../../components/InventoryDealersFilter/InventoryDealersFilter";
import InventoryCatalog from "../../components/InventoryCatalog/InventoryCatalog";
import EvSortControls from "../../components/EvSortControls/EvSortControls";
import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";
// import InventoryCarousel from "../../client_customizations/components/InventoryCarousel/InventoryCarousel";

const Inventory = ({ electricVehicles, dealerLocations, ip, uuid, page }) => {
  const userPrefs = useContext(UserPrefsContext);
  const [inventory, setInventory] = useState(null);

  const zipcode = userPrefs.get("zipcode");
  const distance = userPrefs.get("vehicleDistanceFilter");

   const evToggle = (page) => {
     return (
       <div className="ev-toggle">
         <NavLink
           title={page === "vehicles" ? "Active Page" : null}
           to="/vehicles"
         >
           <span>
             <FormattedMessage
               id="new"
               defaultMessage="Browse All EVS"
               description="Electric Vehicles Toggle"
             />
           </span>
         </NavLink>
         <NavLink
           title={page === "inventory" ? "Active Page" : null}
           to="/inventory"
         >
           <span>
             <FormattedMessage
               id="preOwned"
               defaultMessage="Shop Local Inventory"
               description="Electric Vehicles Toggle PreOwned"
             />
           </span>
         </NavLink>
       </div>
     );
   };

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      const params = {
        postcode: zipcode,
      };

      if (distance) {
        params.distance = distance;
      }else{
        params.distance = 2500;
      }

      try {
        const inventory = await fetchInventory(params);
        if (!ignore) setInventory(inventory);
      } catch (e) {
        console.log("Failed to load inventory");
      }
    }

    fetchData();
    document.title = process.env.REACT_APP_PAGES_INVENTORY_TITLE;

    return () => {
      ignore = true;
    };
  }, [zipcode, distance]);

  const time = new Date().toLocaleString();

  const renderOptions = (
    <>
      <InventoryFilterControls vehicles={inventory} zipcode={zipcode}/>
      <br />
      <InventoryDealersFilter inventory={inventory} zipcode={zipcode}/>
    </>
  );

  const modalButtonText = (
    <FormattedMessage
      id="inventory.filters-modal-button-text"
      defaultMessage="Inventory Filters"
      description="Inventory Filters"
    />
  );

  return (
    <section className="container-fluid grey inventory">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1 className="bold">
              <FormattedMessage
                id="evs.title"
                defaultMessage="Electric Vehicles"
                description="Electric Vehicles"
              />
            </h1>
            <p className="lead light small-centered">
              <FormattedMessage
                id="evs.welcomeSub"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
                values={{
                  lineBreak: <br />,
                }}
              />
            </p>
            {evToggle(page)}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="pull-right">
              <EvSortControls inventory />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText={modalButtonText}
              titleText={modalButtonText}
            >
              {renderOptions}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions}
            <p className="selected-subText">
              <FormattedMessage
                id="evs.inventorythirdParty"
                defaultMessage="Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer."
                description="Vehicles Disclaimer"
                values={{
                  company: process.env.REACT_APP_FULL_COMPANY_NAME,
                }}
              />
            </p>
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ paddingLeft: 10 }}
          >
            <InventoryCatalog vehicles={inventory} />
            {/* <InventoryCarousel vehicles={electricVehicles}/> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Inventory;

Inventory.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
