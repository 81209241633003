import React from "react";
import { useIntl } from "react-intl";
import "./styles.scss";

const RSDetails = () => {
  const intl = useIntl();
  return (
    <div>
      <h2 className="title">
        {intl.formatMessage({
          id: 'rs.residentialService',
          defaultMessage: 'Residential Service',
        })}
      </h2>

      <h3>
        {intl.formatMessage({
          id: 'rs.rateOverview',
          defaultMessage: 'Rate Overview',
        })}
      </h3>
      <p className="text">
        {intl.formatMessage({
          id: 'rs.residentialBody',
          defaultMessage: `The Residential Service (RS) rate schedule is designed for residential customers receiving electric delivery service from Public Service Electric and Gas Company (PSE&G). Customers can choose to purchase their electric supply from a Third Party Supplier (TPS) or from PSE&G’s Basic Generation Service (BGS). The rate includes a monthly service charge and varying distribution charges based on usage and season.`,
        })}
      </p>

      <h3>
        {intl.formatMessage({
          id: 'rs.howItWorks',
          defaultMessage: 'How it Works',
        })}
      </h3>
      <ul>
        <li>
          <strong>
            {intl.formatMessage({
              id: 'rs.serviceCharge',
              defaultMessage: 'Service Charge:',
            })}
          </strong>
          <p>
            {intl.formatMessage({
              id: 'rs.tax',
              defaultMessage: `A fixed monthly fee of $4.64 (or $4.95 with New Jersey Sales and Use Tax)`,
            })}
          </p>
        </li>
        <li>
          <strong>
            {intl.formatMessage({
              id: 'rs.distribution',
              defaultMessage: 'Distribution Charges:',
            })}
          </strong>
          <p>
            {intl.formatMessage({
              id: 'rs.billed',
              defaultMessage: `Billed per kilowatt-hour (kWh) and vary by season and usage:`,
            })}
          </p>
          <ul>
            <li>
              <strong>
                {intl.formatMessage({
                  id: 'rs.timeOne',
                  defaultMessage: 'October through May:',
                })}
              </strong>
              <p>
                {intl.formatMessage({
                  id: 'rs.rangeOne',
                  defaultMessage: `$0.033344 per kWh (up to 600 kWh), $0.033344 per kWh (over 600 kWh).`,
                })}
              </p>
            </li>
            <li>
              <strong>
                {intl.formatMessage({
                  id: 'rs.timeTwo',
                  defaultMessage: `June through September:`,
                })}
              </strong>
              <p>
                {intl.formatMessage({
                  id: 'rs.rangeTwo',
                  defaultMessage: `$0.047308 per kWh (up to 600 kWh), $0.051129 per kWh (over 600 kWh).`,
                })}
              </p>
            </li>
            <li>
              <p>
                {intl.formatMessage({
                  id: 'rs.charges',
                  defaultMessage:
                    'Charges include additional fees for infrastructure improvements and various public policy programs.',
                })}
              </p>
            </li>
          </ul>
        </li>
        <li>
          <strong>
            {intl.formatMessage({
              id: 'rs.additional',
              defaultMessage: 'Additional Charges:',
            })}
          </strong>
          <p>
            {intl.formatMessage({
              id: 'rs.include',
              defaultMessage: 'Include societal benefits, non-utility generation, solar pilot recovery, green programs, tax adjustments, zero emission certificates, and conservation incentives, all combined in the billing.',
            })}
          </p>
        </li>
      </ul>
    </div>
  );
};

export default RSDetails;