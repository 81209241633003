import React, { useState } from "react" 
import "./Sidebar.scss"
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import * as openpgp from "openpgp";
import publicKey from "../../data/publicKey";

const crypto = require('crypto');

const Sidebar = ({electricVehicles, usedElectricVehicles}) => {

    const [modal, setModal] = useState(false);
    const [submittedModal, setSubmittedModal] = useState(false)
    const [responseStatus, setResponseStatus] = useState(null)

    const toggle = () => setModal(!modal);

    const evsMapping = {}

    const mapEvs = (vehicles) => {
        vehicles.forEach(vehicle => {
            const evModel = vehicle.model + vehicle.trim
            const evMake = vehicle.make
            if (evsMapping[evMake] === undefined) {
                evsMapping[evMake] = {}
            } 

            if (evsMapping[evMake][evModel] === undefined) {
                evsMapping[evMake][evModel] = []
            } else {
                evsMapping[evMake][evModel].push(vehicle.model_year)
            }
        })
    };

    if (electricVehicles && usedElectricVehicles) {
        mapEvs(electricVehicles.concat(usedElectricVehicles))
    }

    const MySelect = ({ label, ...props }) => {
        const [field, meta] = useField(props);
        return (
          <>
            <label htmlFor={props.id || props.name}>{label}</label>
            <select {...field} {...props} />
            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
            ) : null}
          </>
        );
      };

    const renderMakes = Object.keys(evsMapping).sort((a, b) => a.localeCompare(b)).map( make => {
            return <option key={make} value={make}>{make}</option>
        })

    const renderModels = (make) => {
        if (make !== "Select a Make") {
            return Object.keys(evsMapping[make]).sort((a, b) => a.localeCompare(b)).map( model => {
                return <option key={model} value={model}>{model}</option>
            })
        }
    }

    const renderYears = (make, model) => {
        if (make !== "Select a Make" && model !== "Select a Model" && make !== "" && model !== "") {
            if (evsMapping[make][model] === undefined) {
                return null
            }
            return evsMapping[make][model].sort((a, b) => a - b).map( year => {
                return <option key={year} value={year}>{year}</option>
            })
        }
    }

    const hearBackOptions = [
        "Home and public charging", 
        "Rebates and incentives", 
        "EV news and reports", 
        "Upcoming EV events", 
        "Fleet or business charging", 
        "Everything"]

    const renderHearBackOptions = hearBackOptions.map((option, i) => {
        return (
            <label key={i}>
                <Field  type="checkbox" name="checked" value={option} />
                    {option}
            </label>
        )
    })

    return (
        <div className="lead-gen-sidebar">
            <button type="button" className="join-network-button" onClick={() => setModal(true)}>
                Join Our EV Network V2
            </button>
            <Modal className="lead-gen-modal" isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle} centered="true">
                </ModalHeader>
                <ModalBody>
                <>
                    <p className="title-modal">
                        Join Our EV Network
                    </p>
                    <p className="modal-subtitle">
                        Get the latest EV news and updates straight to your inbox.
                    </p>
                    </>
                    <Formik
                        initialValues={{ 
                            firstName: '',
                            lastName: '',
                            address: '',    
                            email: '',
                            phone: '',
                            ownsEv: 'true',
                            make: '',
                            model: '',
                            year: '',
                            checked: [],
                            legal: false,
                        }}
                        validate={values => {
                            const errors = {};
                            if (!values.firstName) {
                                errors.firstName = 'Required';
                            } else if (values.firstName.length > 15) {
                                errors.firstName = 'Must be 15 characters or less';
                            }
                            
                            if (!values.lastName) {
                                errors.lastName = 'Required';
                            } else if (values.lastName.length > 20) {
                                errors.lastName = 'Must be 20 characters or less';
                            }
                            
                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                errors.email = 'Invalid email address';
                            }

                            if (!values.address) {
                                errors.address = 'Required';
                            }
                            if (!values.legal) {
                                errors.legal = 'Required';
                            }
                            
                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            // setTimeout(() => {
                            // alert(JSON.stringify(values, null, 2));
                            // setSubmitting(false);
                            // }, 400);
                            delete values.legal

                            let url = new URL(
                                process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_BACKEND_HOST : process.env.REACT_APP_JOIN_NETWORK_DEV_BACKEND_HOST
                              );

                            const submitForm = async () => {
                                const encrypted = await openpgp.encrypt({
                                    message: await openpgp.createMessage({ text: JSON.stringify(values) }),
                                    encryptionKeys: await openpgp.readKey({ armoredKey: publicKey })
                                  });


                                const payload = Buffer.from(encrypted).toString('base64');

                                const leadGen = {
                                    service: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_JOIN_NETWORK_PROD_SERVICE_ID : process.env.REACT_APP_JOIN_NETWORK_DEV_SERVICE_ID,
                                    payload: [ payload ],
                                    checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
                                }
    
                                const response = await fetch(url, {
                                    method: "POST",
                                    headers: {
                                      "Content-Type": "application/json"        
                                    },
                                    body: JSON.stringify(leadGen)
                                }).catch(err => {
                                    console.log(err.response.data);
                                });

                                if (response.ok) {
                                    setResponseStatus(true)
                                } else {
                                    setResponseStatus(false)

                                }
                                setSubmittedModal(true)
                                setModal(false)
                            }
                            submitForm()

                        }}
                        >
                        {({ isSubmitting, values }) => (
                            <Form className="join-ev-network">
                                <label htmlFor="firstName">First Name</label>
                                <Field id="firstName" name="firstName" className="xcel-input" placeholder="" />
                                <div className="form-error">
                                    <ErrorMessage name="firstName" />
                                </div>

                                <label htmlFor="lastName">Last Name</label>
                                <Field id="lastName" name="lastName" className="xcel-input" placeholder="" />
                                <div className="form-error">
                                    <ErrorMessage className="erorasdf" name="lastName" />
                                </div>

                                <label htmlFor="address">Address</label>
                                <Field id="address" name="address" className="xcel-input" placeholder="" />
                                <div className="form-error">
                                    <ErrorMessage name="address" />
                                </div>


                                <label htmlFor="email">Email</label>
                                <Field
                                    id="email"
                                    name="email"
                                    placeholder=""
                                    className="xcel-input"
                                    type="email"
                                />
                                <div className="form-error">
                                    <ErrorMessage name="email" />
                                </div>

                                <label htmlFor="phone">Phone (Optional)</label>
                                <Field id="phone" name="phone" className="xcel-input" placeholder="" />
                                <label htmlFor="ownEV">Already Own an Ev</label>
                                <Field name="ownsEv" as="select" className="my-select xcel-input">
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Field>
                                {values.ownsEv === "true" && 
                                <>
                                    <MySelect label="Make(Optional)" className="xcel-input" name="make">
                                        <option>Select a Make</option>
                                        {renderMakes}
                                    </MySelect>
                                    <MySelect label="Model(Optional)" className="xcel-input" name="model" disabled={!evsMapping[values.make]}>
                                        <option>Select a Model</option>
                                        {values.make && renderModels(values.make)}
                                    </MySelect>
                                    <MySelect label="Year(Optional)" className="xcel-input" name="year" disabled={evsMapping[values.make] ? !evsMapping[values.make][values.model] : !false}>
                                        <option>Select a Year</option>
                                        {renderYears(values.make, values.model)}
                                    </MySelect>
                                    </>
                                }

                                <label className="hear-about" htmlFor="hearAbout">What do you want to hear about? (Optional)</label>
                                <div role="group" aria-labelledby="checkbox-group" className="hear-about-group">
                                    {renderHearBackOptions}
                                </div>
                                <label className="legal-disclaimer">
                                <Field  type="checkbox" name="legal" /> 
                                    By checking this box, I acknowledge that Xcel Energy may send the information provided to another party. 
                                </label>
                                <div className="form-error">
                                    <ErrorMessage name="legal" />
                                </div>

                                <button type="submit" className="btn btn-aps submit" disabled={isSubmitting}>
                                    Join Now
                                </button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
            <Modal 
                isOpen={submittedModal}
                toggle={() => setSubmittedModal(false)}
                className="lead-gen-modal"
            >
                <ModalHeader toggle={() => setSubmittedModal(false)} centered="true">
                </ModalHeader>
                <ModalBody>
                    {responseStatus ? 
                    <>
                        <p className="title-modal">
                            Thanks for joining!
                        </p>
                        <p className="modal-subtitle">
                            Keep an eye on your inbox for the latest EV news and updates.
                        </p>
                    </>
                    :
                    <>
                        <p className="title-modal">
                            Something went wrong.. 
                        </p>
                        <p className="modal-subtitle">
                            Please try again. 
                        </p>
                    </>
                    }
                    <button
                        className="btn btn-aps submit"
                        onClick={() => setSubmittedModal(false)}
                    >
                        Done
                    </button>
                </ModalBody>
            </Modal>

        </div>
    )
}

export default Sidebar
