import React, { useContext, useEffect, useState } from "react";
import Switch from "react-switch";
import chartOptions from "./chartOptions";
import { Bar } from "react-chartjs-2";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import "./RateComparisonChart.scss";
import { useIntl } from "react-intl";

const RateComparisonChart = ({
  title,
  chartData,
  savingsColor,
  children,
  className,
  setBestSaving
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl();
  const selectedEnergyCostIds  = userPrefs.get('selectedEnergyCostIds');
  const [storedChartData, setStoredChartData] = useState(chartData);

  function deepEqual(a, b) {
    if (a === b) {
      return true;
    }
  
    if (typeof a !== 'object' || a === null || typeof b !== 'object' || b === null) {
      return false;
    }
  
    let keysA = Object.keys(a);
    let keysB = Object.keys(b);
  
    if (keysA.length !== keysB.length) {
      return false;
    }
  
    for (let key of keysA) {
      if (!keysB.includes(key) || !deepEqual(a[key], b[key])) {
        return false;
      }
    }
  
    return true;
  }

  useEffect(() => {
    if (deepEqual(storedChartData, chartData)) {
      return;
    }

    setStoredChartData(chartData);
  }, [chartData]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickDataset = (id) => {
    const current = userPrefs.get("selectedEnergyCostIds");
    userPrefs.set({
      selectedEnergyCostIds: current.includes(id)
        ? current.filter((existingId) => existingId !== id)
        : [...current, id],
    });
  };

  let filteredChartData = {
    ...chartData,
    datasets: chartData.datasets.filter((dataset) =>
      selectedEnergyCostIds.includes(dataset.id)
    ),
  };


  const opts = filteredChartData.labels.map((label, index) => ({
    index,
    value: filteredChartData.datasets.reduce(
      (sum, dataset) => sum + dataset.data[index],
      0
    ),
    label: label[0],
    id: filteredChartData.ids[index]
  }));

  const currentComparisonValue = opts.find(e => e.id.split('-')[0] === userPrefs.get("currentRateId"));
  const biggestValue = Math.max(...opts.map((item) => item.value));

  const optsWithSavings = opts.map((item) => ({
    ...item,
    savings: currentComparisonValue.value - item.value,
  }));

  const isRateLowest = optsWithSavings.every(e => e.savings <= 0);
  
  const options = chartOptions({ savingsColor, opts: optsWithSavings, isLowest: isRateLowest, rateValue: biggestValue - currentComparisonValue.value, yAxesLabel: intl.formatMessage({id:"savingsGraphYAxis", defaultMessage:"Estimated average costs per year"}), savedLabel: intl.formatMessage({id:"saved", defaultMessage:"saved"}) });
  const optionWithLargestSavings = optsWithSavings.reduce((prev, current) => (prev.savings > current.savings) ? prev : current);
  
  useEffect(() => {
    if(isRateLowest){
      setBestSaving({selectedRate: {savings: biggestValue - currentComparisonValue.value, label: currentComparisonValue.label} , isLowest: true});
    } else {
      setBestSaving({selectedRate: optionWithLargestSavings, isLowest: false});
    }
  }, [storedChartData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={`RateAdvisorChart ${className}`}>
       <div className="RateAdvisorChart_canvas_container">
              <Bar data={filteredChartData} options={options} opts={optsWithSavings} />
       </div>
      <div className="d-flex chart-controls">
        <div>
          {chartData.datasets.map((dataset) => {
            return (
              <div
                key={dataset.id}
                className="d-inline-block mr-2 chart-data-toggle"
              >
                <Switch
                  id={dataset.id}
                  aria-label={dataset.label === "Electric Vehicle" ? "Electric" : dataset.label}
                  width={40}
                  height={20}
                  checked={selectedEnergyCostIds.includes(dataset.id)}
                  onChange={() => handleClickDataset(dataset.id)}
                  onColor="#142C41"
                  offColor="#E2E5E2"
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <span>
                  {dataset.label === "Electric Vehicle"
                    ? "Electric"
                    : dataset.label}
                </span>
              </div>
            );
          })}
        </div>
        <div>
              <a href="https://nj.pseg.com/" className="cta-btn" target="_blank" rel="noopener noreferrer">{intl.formatMessage({id:"pseg.changeYourRate", defaultMessage:"Change Your Rate"})}</a>
        </div>
      </div>
      {children}
      <div className="graph-disclaimer">
        {intl.formatMessage({id:"savingsGraphDisclaimer", defaultMessage:"* Assumes you power your home with this pricing plan and currently drive a gas vehicle with a type, make, and price that is similar to the EV you selected."})}
      </div>
    </div>
  );
};

export default RateComparisonChart;
