import React, { useContext } from "react";
import PropTypes from "prop-types";

import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from 'react-intl';

const SelectPublicChargingCost = ({
  id = "select-public-charging-cost",
  label,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);

  const optionNames = [
       intl.formatMessage({ id: "free", defaultMessage: "Free" }),
       intl.formatMessage({ id: "low", defaultMessage: "Low" }) + ": $0.20/kWh",
       intl.formatMessage({ id: "medium", defaultMessage: "Medium" }) + ": $0.40/kWh",
       intl.formatMessage({ id: "high", defaultMessage: "High" }) + ": $0.60/kWh",
     ];
     
     const optionValues = [0, 20, 40, 60];

  return (
    <Select
      id={id}
      value={userPrefs.get("publicChargingCostInCentsPerKwh")}
      label={intl.formatMessage
              ? intl.formatMessage({ id: "costOfPublicCharging", defaultMessage: "Cost of Public Charging" })
              : "Cost of Public Charging"}
      tooltip={intl.formatMessage({ id: "costOfPublicChargingTooltip", defaultMessage: "We estimate the cost of paid public charging stations, but you can edit this filter if you know your local charging cost." })}
      optionNames={optionNames}
      optionValues={optionValues}
      handler={(e) =>
        userPrefs.set({ publicChargingCostInCentsPerKwh: e.target.value })
      }
      isLarge
      {...rest}
    />
  );
};

export default SelectPublicChargingCost;

SelectPublicChargingCost.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
