import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './CollapsibleSection.scss';
import icon from '../../../client_customizations/assets/images/icons/accordion_arrow.svg';

/* A collapsible component that opens and closes sub-content 
    todo: have state of open ityems persist on redraw
*/

const CollapsibleSection = ({
  key,
  headLine,
  children,
  collectionOfOpenItems = [],
  updateCollectionOfOpenItemsFunction = (x) => x,
}) => {
  const [collapsibleSectionButtonOpen, setCollapsibleSectionButtonOpen] =
    useState(false);

  function handleHideSection() {
    setCollapsibleSectionButtonOpen(!collapsibleSectionButtonOpen);

    if (collectionOfOpenItems.includes(headLine)) {
      updateCollectionOfOpenItemsFunction(
        collectionOfOpenItems.filter((item) => item !== headLine)
      );
    } else {
      updateCollectionOfOpenItemsFunction([...collectionOfOpenItems, headLine]);
    }
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      handleHideSection(e);
    }
  }

  return (
    <>
      <div className="collapsibleSectionWrapper">
        <button
          className="collapsibleSection"
          onClick={handleHideSection}
          onKeyPress={handleKeyPress}
          aria-expanded={collapsibleSectionButtonOpen}
          tabIndex={0}
        >
          <div className={`collapsibleSectionHead d-flex`}>
            <div className="collapsibleSectionHeadLine">{headLine}</div>
            <div className="collapsibleSectionHeadButton">
              <img
                onClick={(e) => {
                  e.stopPropagation(); // Prevents button click event
                  handleHideSection();
                }}
                className={
                  collapsibleSectionButtonOpen ? 'iconOpen' : 'iconClose'
                }
                src={icon}
                alt="collapsible_icon"
              />
            </div>
          </div>
        </button>
        <div
          className={`collapsibleSectionBody ${
            collapsibleSectionButtonOpen ? 'contentShow' : 'contentHide'
          }`}
        >
          <div className="collapsibleSectionBodyContent">{children}</div>
        </div>
      </div>
    </>
  );
};
export default CollapsibleSection;

CollapsibleSection.propTypes = {
  headline: PropTypes.node,
  children: PropTypes.node.isRequired,
  collectionOfOpenItems: PropTypes.array,
  updateCollectionOfOpenItemsFunction: PropTypes.func,
};
