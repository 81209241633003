
const residentialLoadManagementService = () => {
  return {
    name: 'RATE SCHEDULE RLM',
    title: 'Residential Load Management Service',
    rateElements: [
      {
        rateElementType: 'FixedPerMonth',
        name: 'Service Charge',
        rateComponents: [
          {
            charge: 13.07,
            name: 'Service Charge',
          },
          {
            charge: 13.94,
            name: 'Service Charge with NJ Sales and Use Tax (SUT)',
          }
        ],
      },

      {
        rateElementType: "EnergyTimeOfUse",
        name: "Distribution Charges - Summer On-Peak",
        classification: "energy",
        billingCategory: "delivery",
        rateComponents: [
          {
            charge: 0.080700,
            months: [5, 6, 7, 8],
            daysOfWeek: [1, 2, 3, 4, 5],
            hourStarts: [15, 16, 17, 18, 19],
            name: "Summer On-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Distribution Charges - Summer Off-Peak",
        classification: "energy",
        billingCategory: "delivery",
        rateComponents: [
          {
            charge: 0.016847,
            months: [5, 6, 7, 8],
            daysOfWeek: [0, 6],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23],
            name: "Summer Off-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Distribution Charges - Winter On-Peak",
        classification: "energy",
        billingCategory: "delivery",
        rateComponents: [
          {
            charge: 0.016847,
            months: [0, 1, 2, 3, 4, 9, 10, 11],
            daysOfWeek: [1, 2, 3, 4, 5],
            hourStarts: [15, 16, 17, 18, 19],
            name: "Winter On-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Distribution Charges - Winter Off-Peak",
        classification: "energy",
        billingCategory: "delivery",
        rateComponents: [
          {
            charge: 0.016847,
            months: [0, 1, 2, 3, 4, 9, 10, 11],
            daysOfWeek: [0, 6],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23],
            name: "Winter Off-Peak"
          }
        ]
      },

      {
        rateElementType: "SurchargeAsPercent",
        name: "Conservation Incentive Program",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.001029,
            name: "Conservation Incentive Program"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Societal Benefits Charge",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.010413,
            name: "Societal Benefits Charge"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Non-utility Generation Charge",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.000026,
            name: "Non-utility Generation Charge"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Solar Pilot Recovery Charge",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.000067,
            name: "Solar Pilot Recovery Charge"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Green Programs Recovery Charge",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.005246,
            name: "Green Programs Recovery Charge"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Zero Emission Certificate Recovery Charge",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.004265,
            name: "Zero Emission Certificate Recovery Charge"
          }
        ]
      },
      {
        rateElementType: "SurchargeAsPercent",
        name: "Tax Adjustment Credit",
        classification: "surcharge",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: -0.006138,
            name: "Tax Adjustment Credit"
          }
        ]
      },

      {
        rateElementType: "EnergyTimeOfUse",
        name: "Basic Generation Service Charge - Summer On-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.100394,
            months: [5, 6, 7, 8],
            daysOfWeek: [1, 2, 3, 4, 5],
            hourStarts: [15, 16, 17, 18, 19],
            name: "Summer On-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Basic Generation Service Charge - Summer Off-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.063398,
            months: [5, 6, 7, 8],
            daysOfWeek: [0, 6],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23],
            name: "Summer Off-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Basic Generation Service Charge - Winter On-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.095132,
            months: [0, 1, 2, 3, 4, 9, 10, 11],
            daysOfWeek: [1, 2, 3, 4, 5],
            hourStarts: [15, 16, 17, 18, 19],
            name: "Winter On-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Basic Generation Service Charge - Winter Off-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.068746,
            months: [0, 1, 2, 3, 4, 9, 10, 11],
            daysOfWeek: [0, 6],
            hourStarts: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 20, 21, 22, 23],
            name: "Winter Off-Peak"
          }
        ]
      },

      {
        rateElementType: "FixedPerMonth",
        name: "Transmission Charge",
        classification: "fixed",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.000000,
            name: "Transmission Charge"
          }
        ]
      },

      {
        rateElementType: "EnergyTimeOfUse",
        name: "Transmission Charge - On-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.035922,
            name: "On-Peak"
          }
        ]
      },
      {
        rateElementType: "EnergyTimeOfUse",
        name: "Transmission Charge - Off-Peak",
        classification: "energy",
        billingCategory: "supply",
        rateComponents: [
          {
            charge: 0.030415,
            name: "Off-Peak"
          }
        ]
      }

    ],
  };
};

export default residentialLoadManagementService;
