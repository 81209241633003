export default {
  electricVehicles: 'Electric Vehicles',
  usedElectricVehicles: 'Used Electric Vehicles',
  electricVehiclesHeader: 'BROWSE EVS',
  compareVehicles: 'Compare New Vehicles',
  incentives: 'FIND INCENTIVES',
  chargingStations: 'CHARGING STATIONS',
  homeChargers: 'HOME CHARGING',
  dealers: 'Dealers',
  newVehicles: 'New Vehicles',
  electricians: 'Electricians',
  homeChargingAdvisor: 'Home Charging Advisor',
  'layout.button': 'Learn more about our EV Charging Program',
  'evsCatalog.title': 'Discover New Electric Vehicles',
  'evsCatalog.subtitle':
    'There are more electric vehicles than ever before. Find yours, today.',

  electricity: 'Electricity',
  gasoline: 'Gasoline',
  total: 'Total',
  description: 'Description',
  detailedCalculations: 'Details',
  charging: 'Charging',
  emissions: 'Emissions',
  source: 'Source',
  vehicles: 'Vehicles',
  make: 'EV MAKE',
  model: 'EV MODEL',
  trim: 'EV TRIM',
  all: 'All',
  apply: 'Apply',
  applyFilters: 'Apply Filters',
  age: 'Age',
  zipcode: 'ZIP CODE',

  personalizeIncentives: 'Personalize Incentives',

  'FAQ.title': 'EV 101',
  'FAQ.subtitle':
    'EVs are still new, and it’s natural to have questions. Find answers below.',

  'vehicle.miles': 'miles',
  'vehicle.trees': 'trees',
  'vehicle.view': 'View',
  'vehicle.more': 'more',
  'vehicle.fewer': 'fewer',
  'vehicle.greater': 'greater',

  'vehicle.plugInHybrid': 'Plugin Hybrid',
  'vehicle.allElectric': 'All Electric',
  'vehicle.gas': 'Gasoline',
  'vehicle.afterIncentives': 'After Incentives',
  'vehicle.msrp': 'MSRP',
  'vehicle.estimatedIncentives': 'EST. INCENTIVES',
  'vehicle.offMSRP': '{msrp} off MSRP',
  'vehicle.downPaymentMonth': 'for {months} months',
  'vehicle.downPayment': '+ {downPayment} down payment',
  'vehicle.plusTax': 'plus tax',
  'vehicle.type': 'Type',
  'vehicle.subtype': 'Subtype',
  'vehicle.gvwr': 'GVWR',
  'vehicle.gvwr.value': '{value} lbs',
  'vehicle.weightClass': 'Weight Class',
  'vehicle.drivetype': 'Drivetrain',
  'vehicle.transmission': 'Transmission',
  'vehicle.transmission.automatic': 'Automatic',
  'vehicle.payload': 'Payload',
  'vehicle.seats': 'Seats',
  'vehicle.cargoSpace': 'Cargo Space',
  'vehicle.cargoSpace.value': '{cargoSpace} cubic feet',
  'vehicle.batteryCapacity': 'Battery Capacity',
  'vehicle.batteryCapacity.value': '{batteryCapacity} kWh',
  'vehicle.electricEfficiency.value': '{electricEfficiency} MPGe',
  'vehicle.gasEfficiency': 'Gas Efficiency',
  'vehicle.gasEfficiency.value': '{gasEfficiency} MPG',
  'vehicle.electricRange': 'Electric Range',
  'vehicle.electricRange.value': '{electricRange} miles',
  'vehicle.totalRange': 'Total Range',
  'vehicle.totalRange.value': '{totalRange} miles',
  'vehicle.maxACChargeRate': 'Max AC Charge Rate',
  'vehicle.maxACChargeRate.value': '{maxACChargeRate} kW',
  'vehicle.maxDCChargeRate': 'Max DC Charge Rate',
  'vehicle.maxDCChargeRate.value': '{maxDCChargeRate} kW',
  'vehicle.chargeTimeLevel2': 'Charge Time - Level 2',
  'vehicle.chargeTimeDC': 'Charge Time - DC',
  'vehicle.plugTypes': 'Plug Types',

  //aca
  'vehicle.fuelType': 'Fuel Type',
  'vehicle.batterySize': 'BATTERY SIZE',
  'vehicle.timeToChargeLvlTwo': 'TIME TO CHARGE - LEVEL 2',
  'vehicle.milesPerThirtyFastCharge': 'MILES PER 30 MIN OF FAST CHARGING',
  'vehicle.coTwoEmissions': 'CO2 EMISSIONS REDUCTION',
  'vehicle.phev': 'Electricity and Gasoline',
  'vehicle.bev': 'Electricity',
  'vehicle.age.one': '0-1 years old',
  'vehicle.age.two': '2-3 years old',
  'vehicle.age.four': '4+ years old',
  'vehicle.power.all': 'All',
  'vehicle.power.high': 'High',
  'vehicle.power.public': 'Public',
  'vehicle.power.other': 'Other',

  'vehicle.type.sedan': 'Sedan',
  'vehicle.type.hatchback': 'Hatchback',
  'vehicle.type.coupe': 'Coupe',
  'vehicle.type.crossover': 'Crossover',
  'vehicle.type.minivan': 'Minivan',
  'vehicle.type.suv': 'SUV',
  'vehicle.type.wagon': 'Wagon',
  'vehicle.type.truck': 'Truck',
  'vehicle.type.suvcrossover': 'SUV/Crossover',

  'header.electricVehicles': 'Browse EVs',
  'header.compareVehicles': 'Compare Vehicles',
  'header.incentives': 'Incentives',
  'header.chargingStations': 'Charging Stations',
  'header.homeChargers': 'Home Chargers',
  'header.rates': 'Compare Rates',
  'header.english': 'English',
  'header.spanish': 'Spanish',
  'header.faq': 'EV 101',
  'header.subHeader':
    'Federal income tax credits may be available. Actual amounts vary. Consult your tax advisor. In the meantime, happy browsing!',

  'footer.electricVehicles': '{electricVehicles}',
  'footer.compareVehicles': '{compareVehicles}',
  'footer.incentives': '{incentives}',
  'footer.chargingStations': '{chargingStations}',
  'footer.homeChargers': '{homeChargers}',
  'footer.allRights': 'All Rights Reserved.',
  'footer.poweredBy': 'Powered by Zappyride',
  'footer.clientDisclaimer':
    '© 2024 Public Service Enterprise Group Incorporated. All Rights Reserved.',

  'homepage.welcome': 'Colton Recharged',
  'homepage.welcomeSub':
    'Your electric car guide, brought to you by Colton Electric Utility. Estimate and compare costs, savings, EV Incentives, and more.',

  'homepage.linkCard.browseElectricVehicles':
    'BROWSE ELECTRIC {lineBreak} VEHICLES',
  'homepage.linkCard.discoverIncentives': 'DISCOVER {lineBreak} INCENTIVES',
  'homepage.linkCard.locateChargingStations':
    'LOCATE CHARGING {lineBreak} STATIONS',
  'homepage.linkCard.homeChargers': 'HOME {lineBreak} CHARGERS',

  'homepage.vehicleCarousel.title':
    'There are more electric vehicles than ever.',
  'homepage.vehicleCarousel.title.discover': 'Discover yours.',
  'homepage.vehicleCarousel.subTitle':
    'Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car.',
  'homepage.vehicleCarousel.findEVButton': 'See all vehicles',
  'homepage.vehicleCarousel.compareVeiclesButton': 'COMPARE VEHICLES',
  'homepage.incentives': 'Maximize Incentives',
  'homepage.compare': 'Explore EV Ownership',
  'homepage.rates': 'Compare Rates',
  'homepage.charging': 'Find Charging',
  'homepage.browse': 'Browse EVs',
  'homepage.browse.mobile': 'Discover {lineBreak} EVs',
  'homepage.banner.title':
    'Estimate and compare costs, savings, incentives & more.',
  'homepage.banner.title.end': 'Listen.',
  'homepage.banner.button.text': 'FIND YOUR EV',
  'homepage.vehicleCarousel.compareVehiclesButton':
    'Find Your Electric Vehicle',

  'homepage.faq.title.questions':
    'EV QUESTIONS, <highline>ANSWERED.</highline>',
  'homepage.faq.title.answered': 'ANSWERED',
  'homepage.faq.subtitle':
    'Find answers to common EV questions about charging, incentives, vehicles, and more.',

  'graph.assumptions': 'ASSUMPTIONS',
  'graph.yourEV': '(Your selection)',
  'graph.similarGasVehicle': '(Similar gas vehicle)',
  'graph.graphValues': 'GRAPH VALUES',
  'graph.show': 'Show ',
  'graph.hide': 'Hide ',
  'graph.moreExpensive': 'cost more',
  'graph.cheaper': 'cheaper',
  'graph.toOwnOver': 'to own over ',
  'graph.years': ' Years',
  'graph.year': ' Year',

  'graph.costOfOwnership.title': 'Cost of Ownership',
  'graph.costOfOwnership.subTitle':
    'The {carName} will {costDeltaText} {costDeltaTextEnding}',
  'graph.costOfOwnership.subTitleThreeCars':
    'The {carName} is the cheapest to own for {yearsOfOwnership} years',
  'graph.costOfOwnership.descriptionRow.vehicle': 'Vehicle',
  'graph.costOfOwnership.descriptionRow.maintenance': 'Maintenance',
  'graph.costOfOwnership.descriptionRow.insurance': 'Insurance',
  'graph.costOfOwnership.descriptionRow.electricity': 'Electricity',
  'graph.costOfOwnership.descriptionRow.gasoline': 'Gasoline',
  'graph.costOfOwnership.totalRow': 'Total',
  'graph.costOfOwnership.description': 'Description',

  'graph.fuelCostGraphic.similarGasVehicle': '(Similar gas vehicle)',
  'graph.fuelCostGraphic.yourSelection': '(Your selection)',

  'graph.costOfOwnership.chart.vehicle': 'Vehicle after Incentives',
  'graph.costOfOwnership.chart.taxesFees': 'Taxes and Fees',
  'graph.costOfOwnership.chart.electricity': 'Electricity',
  'graph.costOfOwnership.chart.gasoline': 'Gasoline',
  'graph.costOfOwnership.chart.maintenance': 'Maintenance',
  'graph.costOfOwnership.chart.insurance': 'Insurance',

  'graph.title.costAnalysis': 'Cost Analysis',
  'graph.title.speedOfCharge': 'Speed of Charge',
  'graph.title.electricRange': 'Electricity',
  'graph.monthlyCostToFill.title': 'Cost to Fill Up Monthly',

  'graph.speedOfCharge.levelTwoChargingSpeed': 'Level 2 Charger Speed',
  'graph.speedOfCharge.fastChargingSpeed': 'Fast Charging Speed',
  'graph.speedOfCharge.mphLevelTwoCharger':
    'miles per hour with a level 2 charger',
  'graph.speedOfCharge.thirtyFastCharge':
    'miles per 30 minutes of fast charging',
  'graph.speedOfCharge.subTitle':
    'The {car} charges {number} {costDeltaText} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerThreeCars':
    'charges the most miles per hour with a level 2 charger',
  'graph.speedOfCharge.thirtyFastChargeThreeCars':
    'charges the most miles per 30 minutes of fast charging',
  'graph.speedOfCharge.subTitleThreeCars': 'The {car} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerTitle':
    'Amount of Charge Per Hour of Level 2 Charging',
  'graph.speedOfCharge.fastChargerTitle':
    'Amount of Charge Per 30 Minutes of Fast Charging',
  'graph.electricRange.subTitleThreeCars':
    'The {car} has the longest Electric Range',
  'graph.electricRange.subTitle':
    "The {car}'s electric range is {costDeltaText} by {number} miles",
  'graph.electricRange.gasolineRange': 'Gasoline',
  'graph.electricRange.assumption': 'Range based on EPA Estimates',
  'graph.monthlyCostToFill.subTitle':
    'Monthly, the {carName} costs {style} {costDeltaText} to fill up.',
  'graph.monthlyCostToFill.subTitleThreeCars':
    'Monthly, the {carName} is cheapest to fill up.',

  'homepage.incentives.title':
    'Maximize potential EV incentives and tax credits.',
  'homepage.incentives.subTitle':
    'See how much you could save getting behind the wheel of an electric car, whether you are buying or leasing. Incentives are personalized for where you live.',
  'homepage.incentives.exporeIncentives': 'EXPLORE INCENTIVES',

  'homepage.chargerBanner.title':
    'Need a Home charger? <highline>We’ve got you covered.</highline>',
  'homepage.chargerBanner.subTitle':
    'A long copy opportunity to talk about the details around selecting and installing a home charger.',

  'homepage.chargerBanner.chargerButton': 'GET A CHARGER',
  'charger.disclaimer':
   'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {here}.',

  'compareVehicles.subTitle':
    'Pick at least two vehicles to begin the comparison.',
  'compareVehicles.selectFuelTitle': 'FUEL TYPE',
  'compareVehicles.selectMakeTitle': 'VEHICLE MAKE',
  'compareVehicles.selectModelTitle': 'VEHICLE MODEL',
  'compareVehicles.pickTwo': 'Pick at least 2 vehicles to start the comparison',
  'compareVehicles.disclaimer': 'Not all make / models are available.',
  'compareVehicles.selectYear': 'Select a Year',

  'evs.title': 'Discover EVs and Local Inventory',
  'evs.welcomeSub': 'Find the right one for you.',
  'evs.buttonMatchScoreAndFilters': 'Open Filters',
  'evs.matchScoreAndFilters': 'Filters',
  'evs.matchScoreOptions': 'Filters',
  'evs.electricFuelFilter': 'All-Electric',
  'evs.hybridFuelFilter': 'Plug-in Hybrid',

  'evs.roundTripCommute': 'MINIMUM RANGE',
  'evs.budgetAfterIncentives': 'BUDGET AFTER INCENTIVES',
  'evs.minSeats': 'MINIMUM SEATS',
  'evs.availableNow': 'Available Now',
  'evs.availableNowTooltip':
    'Vehicles available now can be purchased for delivery, instead of just being open for pre-orders. Actual inventory may vary locally.',
  'evs.seats': 'seats',
  'evs.homeChargingAvailability': 'Home Charging Availability',
  'evs.homeChargingAvailabilityTooltip':
    'Driving and charging an EV is most convenient when you charge where you live',
  'evs.noCharging': 'No Charging',
  'evs.levelOne': 'Level 1',
  'evs.levelTwo': 'Level 2',
  'evs.helpMeChoose': 'Help me choose',
  'evs.chargingAvailability': 'Home Charging Availability',
  'evs.disclaimer':
    'Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer.',
  'evs.thirdParty':
    'We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability.',
  'chargingWizard.carOvernight': 'Where will you park your car overnight?',
  'chargingWizard.parkingGarage': 'Private garage or parking spot',
  'chargingWizard.onTheStreet': 'On the street',
  'chargingWizard.publicParking': 'Public parking lot',
  'chargingWizard.electricianInstall':
    'Would an electrician be allowed to install a charging station?',
  'chargingWizard.overnightParking':
    'Could you park the car overnight in a spot equipped with a charging station?',
  'chargingWizard.powerOutlet':
    'Is there a regular power outlet close to where you park your car?',
  'chargingWizard.chargingAvailability': ' charging availability',
  'chargingWizard.recommended': 'RECOMMENDED',
  'chargingWizard.noChargingAvailability': 'None',
  'chargingWizard.youWouldHave': 'you would have {resultText} ',
  'chargingWizard.useThisValue': 'Use This Value',

  evfilter: 'Filter',
  'evfilter.fuel': 'FUEL',
  'evfilter.fuelTooltip':
    'All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline.',
  'evfilter.type': 'TYPE',

  egbBringCharger: 'I will use my own Level 2 charger',

  yes: 'Yes',
  no: 'No',

  'evCard.electricRange': 'Electric Range',
  'evCard.totalRange': 'Total Range',
  'evCard.matchScore': 'MATCH SCORE',
  'evCard.seeDetails': 'More Details',
  'evCard.seeElectricVehicle': 'SEE ELECTRIC VEHICLE',

  'evSort.title': 'SORT BY',
  'evSort.matchScore': 'Match Score',
  'evSort.electricRange': 'Electric Range',
  'evSort.priceLtH': '$: Low to High',
  'evSort.priceHtL': '$: High to Low',
  'evSort.alphabeticaldesc': 'Make: A to Z',
  'evSort.alphabeticalasc': 'Make: Z to A',

  'pricePanels.cash': 'Cash',
  'pricePanels.loan': 'Loan',
  'pricePanels.lease': 'Lease',
  'pricePanels.loanPayment': 'Loan Payment',
  'pricePanels.downPayment': 'Down Payment',
  'pricePanels.leasePayment': 'Lease Payment',
  'pricePanels.perMonth': ' / mo',
  'pricePanels.downPaymentDescription': '10% of MSRP plus tax',
  'pricePanels.firstLeasePayment': 'FIRST LEASE PAYMENT',
  'pricePanels.incentivesForLease': 'Incentives For Lease',
  'pricePanels.seeAllIncentives': 'See all incentives',
  'pricePanels.seeAllCosts': 'See all costs',
  pri: 'See all costs',

  'ev.jumbotron.viewAllCars': 'View All Cars',
  'ev.jumbotron.maximizeIncentives': 'Maximize Incentives',
  'ev.jumbotron.totalCost': 'See Total Cost of Ownership',
  'ev.jumbotron.compare': 'Compare To Other Vehicles  ',
  'ev.jumbotron.localInventory': 'SHOP LOCAL INVENTORY',
  'ev.carDetails.view.back': 'All vehicles',

  'ev.carDetails.table.title': 'Vehicle Specs',
  'ev.carDetails.table.footer':
    'You may need to contact the manufacturer to obtain a more accurate or discounted price. {br} Vehicle specifications shown are standard, though variants may exist. Vehicles listed are for reference purposes only and availability is dependent on local inventory. {br} {br} Savings Calculator provides illustrative estimations throughout and does not guarantee the accuracy of any costs, savings, hardware specifications, or incentives estimates. Please refer to referenced sources and original equipment manufacturers for up-to-date costs, hardware specifications, and incentives information.',

  'ev.carDetails.view.subtitle': 'MSRP - {msrp}',
  'ev.carDetails.fastChargingTooltip': 'DC Fast Charger Speed:',
  timeToCharge:
    'Assumes charging from empty with an average mid-size Level 2 charger (7.7 kW). Note: Level 2 chargers require a 240V outlet. Level 1 charge times are much longer.',
  'ev.carDetails.batterySizeTooltip':
    'One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh.',
  'ev.carDetails.treesPlanted': 'Trees Planted',
  'ev.carDetails.gasolineSaved': 'Gasoline Saved',
  'ev.carDetails.compareText':
    'We selected the {gasVehicle} based on its proximity to the {vehicle} based on brand, size, and price.',
  'ev.cardetails.calc.dcRate': 'DC Fast Charging (DCFC) Rate',
  'ev.cardetails.calc.EPA': 'EPA Efficiency Rating',
  'ev.cardetails.calc.chargingRate': 'Charging Rate',
  'ev.cardetails.calc.vehicleBatteryCapacity': 'Vehicle Battery Capacity',
  'ev.cardetails.calc.maxACIntake': 'Vehicle Max AC Intake',
  'ev.cardetails.calc.lvlTwoChargingRate': 'Level 2 Charging Rate',
  'ev.cardetails.calc.minOflvl2AC':
    'Minimum of Level 2 Charging Rate and Vehicle Max AC Intake',
  'ev.cardetails.calc.lvl2FullCharge': 'Time to Full Charge for Level 2',
  'ev.cardetails.calc.milesDrivenPerYr': 'Miles driven per year',
  'ev.cardetails.calc.userInput': 'User Input',
  'ev.cardetails.calc.milesPerGallon': 'Miles Per Gallon (MPG)',
  'ev.cardetails.calc.mpg': ' miles/gal',
  'ev.cardetails.calc.portionElectric': 'Portion of Electric Driving',
  'ev.cardetails.calc.portionElectricSource':
    'User input. For a hybrid vehicle, estimates the portion of miles driven using the electric battery instead of gasoline.',
  'ev.cardetails.calc.gallonsUsed': 'Gallons Used',
  'ev.cardetails.calc.gallonsUsedFormula':
    'Miles driven * (1 - Portion of Electric Driving) / MPG',
  'ev.cardetails.calc.gallonsSaved': 'Gallons Saved',
  'ev.cardetails.calc.gallonsSavedFormula':
    'Gallons used by Equivalent Gas Vehicle - Gallons used by EV',
  'ev.cardetails.calc.electricEmissions': 'Electric Emissions in Lbs',
  'ev.cardetails.calc.electricEmissionsLbsMwh': 'Electric Emissions in Lbs/MWh',
  'ev.cardetails.calc.EIA': 'U.S. Energy Information Administration',
  'ev.cardetails.calc.gasolineEmissionsYr':
    'Gasoline Emissions in lbs of CO2/yr',
  'ev.cardetails.calc.gasolineEmissions':
    'Gasoline Emissions in Lbs per Gallon',
  'ev.cardetails.calc.emissionsReudctions': 'Emissions Reductions',
  'ev.cardetails.calc.lbsCo2PerYr': ' lbs of CO2 per year',
  'ev.cardetails.calc.gasolineEmissionsLbs': 'Gasoline Emissions in Lbs',
  'ev.cardetails.calc.arborDayFoundation': 'Arbor Day Founadtion',
  'ev.cardetails.calc.emissionsPerTree': 'Emissions Saved per Tree',
  'ev.cardetails.calc.electricityEmissions':
    'Electricity Emissions in CO2 lbs/MWh',
  'ev.usedCarTitle': 'Used {car}',
  'ev.cardetails.electricRange':
    'To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.',
  'ev.carDetails.usedBatterySizeTooltip':
    'One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.',

  'ev.purchaseMethod': 'PAYMENT METHOD',
  'ev.purchaseMethodToolTip':
    'In a lease, you have to return the car after the lease period. In the case of loan financing, you keep the car after the loan is paid off.',
  'ev.cash': 'Cash',
  'ev.loan': 'Loan',
  'ev.lease': 'Lease',
  'ev.milesDrivenAnnually': 'Miles Driven Per Year',
  'ev.electricPortion': 'Electric Miles Driven',
  'ev.electricPortionTooltip':
    'A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.',
  'ev.yearsOwnership': 'Years of Ownership/Lease',
  'ev.interestRate': 'Loan/Lease Interest Rate',
  'ev.electricityRateCalc': `Electricity rate default value starts at the state average rate as provided by the Energy Information Administration's (EIA) last annual report. Users can adjust to match their expectation of electricity prices.`,

  'assumption.salesTax': 'Sales Tax',
  'assumption.gasolinePrice': 'Price of Gasoline',
  'assumption.downPayment': 'Down Payment',
  'assumption.electricityRate': 'Electricity Rate',
  'assumption.downPaymentValue': '10% of MSRP + Tax',

  'ev.compareGasVehicle': 'Compare the {year} {car} to a similar gas vehicle.',
  'ev.compareOtherVehicles': 'COMPARE OTHER VEHICLES',
  'ev.incentives.title':
    'Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}',
  'ev.reviews': 'Reviews from around the web',
  'ev.compareGasVehicle.tooltip':
    'We selected the {gasCar} based on its proximity to the {car} based on brand, size, and price.',

  'chargingMap.updateZipcode': 'Update Zip Code',
  'chargingMap.errorZipcode': 'Error: {workingZipcode} is not a valid zipcode',
  'chargingMap.showFullListOfStations': 'full list of stations',
  'chargingMap.public': 'Public stations',
  'chargingMap.businessGovernment': 'Level 2 chargers',
  'chargingMap.highPowerStations': 'High-Power Stations',
  'chargingMap.DCChargers': 'DC fast chargers',
  'chargingMap.otherSttations': 'Other Stations',
  'chargingMap.privateStations': 'Private stations',
  'chargingMap.searchDealers': 'Search Qualified Dealers',
  'chargingMap.otherStations': 'Other Stations',
  'chargingMap.searchCharging': 'Search Charging Stations',
  'chargingMap.disclaimer':
    'Mileage ranges are based upon estimates provided by EV manufacturers. Your range may vary based upon climate, region, traffic, car model, and automobile conditions. Data from the Alternative Fuel Data Center may not reflect latest availability. Please confirm availability of charging stations before commencing your journey. Submit suspected missing charging stations {here}.',

  'homepage.chargingMap.title': 'Recharge on the go, wherever you go.',
  'homepage.chargingMap.subtitle':
    'With new stations powering on every day, it’s easier than ever to drive far and recharge. Enter your location to find all of the charging stations near you.',

  'page.chargingMap.title': 'Locate Charging Stations',
  'page.chargingMap.subtitle':
    'It’s easier than ever to charge on the go. Enter your location to find all of the charging stations near you or on your route.',

  'radiusMap.address': 'ADDRESS',
  'radiusMap.vehicle': 'VEHICLE',
  viewTravelRadius: 'View travel radius',
  radiusSpecification: 'miles total range from full',

  'dealers.brands': 'Brands',
  'dealers.allBrands': 'All Brands',
  'dealers.allDealers': 'All Dealers',
  'dealers.contact': 'CONTACT',
  'dealers.website': 'WEBSITE',
  'dealers.findDealers': 'Find Dealers',

  'incentives.title': 'Electric Vehicle and Charger Incentives',
  'incentives.disclaimer':
    'Incentive eligibility and amounts do not constitute tax or legal advice. Consult the incentive grantor and/or your tax or legal professional to determine eligibility, specific amount of incentives or rebates available.',
  'incentives.subTitle':
    'You may be eligible for a number of incentives, including rebates, tax credits, and various other benefits. Adjust the filters to personalize your results.',
  'incentives.single': 'Single',
  'incentives.married': 'Married',
  'incentives.headOfHousehold': 'Head of Household',
  'incentives.vehicleType': 'Vehicle Type',
  'incentives.vehicleTypeTooltip':
    'Certain incentives depend on which car you purchase.',
  'incentives.tradeIn': 'RETIRING A VEHICLE',
  'incentives.income': 'Income and tax status',
  'incentives.incomeTooltip':
    'Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates.',
  'incentives.clunkerNone':
    'Certain incentives compensate you for retiring an existing vehicle, also known as a clunker.',
  'incentives.clunkertoolTip':
    'Certain incentives compensate you for retiring an existing vehicle, also known as a clunker. Specifically, the ',
  'incentives.clunkertoolTipContd':
    ' are available in your region. Eligibility requirements vary by incentive; please check program details.',
  'incentives.householdSize': 'SIZE OF HOUSEHOLD',
  'incentives.householdIncome': 'HOUSEHOLD INCOME',
  'incentives.electricityProvider': 'ELECTRICITY PROVIDER',
  'incentives.evAge': 'EV Age',
  'incentives.selectProvider': 'ELECTRICITY PROVIDER',
  'incentives.planClunker': 'Are you planning to turn in a clunker?',
  'incentives.location': 'Location',
  'incentives.locationTooltip':
    'Many incentives depend on where you live. Enter your zipcode to help determine what incentives are available where you live.',
  'incentives.taxFilingStatus': 'TAX FILING STATUS',
  'incentives.whichVehicle': 'Which vehicle do you plan to purchase?',
  'incentives.incentiveEligibility': 'Update Incentive Eligibility',

  'homeChargers.subText':
    'Select the vehicle you are interested in to see the specific charging time to a full battery.',
  'homeChargers.wallMounted': 'Wall Mounted',
  'homeChargers.portable': 'Portable',
  'homeChargers.price': 'Price',
  'homeChargers.cordLength': 'Cord Length',
  'homeChargers.wifi': 'WiFi Connectivity',
  'homeChargers.socket': 'plug',
  'homeChargers.socketTooltip':
    "Different model chargers have different plug patterns. These plug patterns correspond to the way prongs fit into your wall outlet. If you're note sure which is best for your home/garage, check with a qualified electrician.",
  'homeChargers.cordLengthLtH': 'Cord Length: Low to High',
  'homeChargers.cordLengthHtL': 'Cord Length: High to Low',
  'homeChargersCard.buy': 'Details & Buy',
  'homeChargersCard.beforeIncentives': 'Before Incentives',
  'homeChargersCard.socket': '{socket} Socket',
  'homeChargers.cable': '{length} ft. cord',
  'homeChargers.hardwired': 'Hardwired Connection',
  'homeChargers.toFullCharge': 'to full charge',

  'electricians.residential': 'Residential',
  'electricians.commercial': 'Commercial',
  'electricians.industrial': 'Industrial',
  'electricians.type': 'Electrician Type',
  'electricians.searchElectricians': 'Search Qualified Electricians',

  'tabbed-maps-dealer-catalog-tab': 'Dealers',
  'tabbed-maps-all-stations-tab': 'Charging Stations',
  'tabbed-maps-electricians-tab': 'Electricians',
  'tabbed-maps-route-tab': 'route planner',
  'tabbed-maps-range-tab': 'Range Map',

  events: 'Events',
  'events.subHeader':
    'Get your hands on an electric vehicle in-person, and see what all the fuss is about.',
  eventsFilters: 'Filters',
  eventsDateRange: 'Date Range',
  'events.nationWide': 'Nationwide',
  'events.both': 'Both',
  'events.inPerson': 'In Person',
  'events.virtual': 'Virtual',
  'events.rideAndDrive': 'Ride and Drive',
  'events.autoshow': 'Autoshow',
  'events.educationEvent': 'Education Event',
  'events.training': 'Training',
  'events.webinar': 'Webinar',
  'events.conference': 'Conference',
  'events.distance': 'Distance',
  'events.inPersonOrVirtual': 'In Person or Virtual',
  'eventCard.inPerson': 'In Person Event',
  'eventCard.virtualEvent': 'Virtual Event',
  'eventCard.inPersonAndVirtual': 'In Person + Virtual Event',
  'eventCard.learnMore': 'Learn More',
  'eventCard.hostedBy': 'Hosted by',
  'eventSort.dateLtH': 'Date (Soonest first)',

  'inventory-filter.filter-vehicles': 'Filters',
  'inventory-card.vehicle-details': 'View Details',
  'evs.inventorythirdParty':
    '* This website contains links to third-party external websites. APS does not endorse or accept any liability for content or products sold on any third-party websites. APS has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party external websites. By clicking on links, you expressly relieve APS from any and all liability arising from your use of third-party external website, or from the content of any third-party external website.',
  toolGeneralDisclaimer:
    'This is a third party website owned, controlled and managed by ZappyRide, not Arizona Public Service Company (APS). ZappyRide may share or otherwise provide access to Non-Personally Identifiable Information to APS in connection with ZappyRide’s operation and management of the Site. APS’s privacy policy governs its collection, receipt, and use of such information on aps.com and is available here.',
  vehicleType: 'Select all vehicle body types you are interested in.',
  householdIncome:
    'Combine income from all household members. Used to determine incentive eligibility.',
  homeChargerAvailability:
    'Charging EVs at home is most convenient and cost-effective. If you can’t, we estimate public charging costs instead. Note: Level 1 chargers run on standard 120V, Level 2 run on 240V.',
  householdSize:
    'Include all members of your household (spouse, dependents, etc). Used to determine incentive eligibility.',
  taxFilingStatus: 'Used to determine incentive eligibility.',
  retireAVehicle:
    'Retiring an old or existing gas vehicle may be required for certain incentives.',
  batterySize:
    'One kilowatt-hour is enough energy to drive 1 to 4 miles, depending on the vehicle. For reference, a typical laptop battery can hold less than 0.1 kWh.',
  electricMilesDriven:
    'Estimate what percentage of your driving miles will be completely electric. If you recharge daily and drive less per day than the all-electric range, your percentage will be near 100%. For most typical driving beyond all-electric range, 50% is a safe assumption.',
  graphDisclaimerPrice:
    'The purchase price of an EV can be higher compared to a similar internal combustion engine vehicle. However, some of the higher upfront costs associated with EVs can be offset by vehicle incentives, lower total cost of ownership- and stronger resale value.',
  graphDisclaimerElectricity:
    'Fueling with electricity can cost less than fueling with gasoline, for the same distance traveled.',
  graphDisclaimerGas:
    'Gasoline fuel costs are typically more than electricity.',
  graphDisclaimerMaintenance:
    'Maintenance costs are generally lower for EVs due to fewer moving parts.',
  graphDisclaimerInsurance:
    'EVs can cost more to insure due to a higher purchase price. Higher insurance costs can be offset by fuel and maintenance savings.',
  graphDisclaimerCostAnalysis:
    'Comparisons reflect approximate savings and are not guaranteed. Actual savings will vary based on individual use and market conditions. APS makes no representations or warranties of any kind, either express or implied, with respect to the accuracy of the information contained herein.',
  costAnalysisDisclaimer:
    'Comparisons reflect approximate savings and are not guaranteed. Actual savings will vary based on individual use and market conditions. APS makes no representations or warranties of any kind, either express or implied, with respect to the accuracy of the information contained herein.',
  rangeMap:
    'As advertised by the manufacturer. Actual range may differ, depending on environmental factors, driving behavior, vehicle maintenance, and battery age.',

  tcoSavingsHeading:
    'The {car1} is not currently cheaper to own than your existing vehicle',

  here: 'here',
  any: 'any',
  new: 'Browse all EVS',
  localInventory: 'Local Inventory',
  preOwned: 'Shop Local Inventory',
  preOwnedElectricVehicles: 'Pre-Owned Electric Vehicles',
  'usedEVCard.yearRange': 'Year Range',
  'usedEVCard.electricRangeAverage': 'Electric Range Average',
  gasolinePriceLabel: 'Gasoline Price',
  incentivesHeader:
    'Discover EV Purchase and Lease Incentives, Rebates, & Credits',
  incentivesSubHeader:
    'You can lower the upfront cost of an EV with potential tax incentives, credits and {lineBreak} special rebates for Xcel Energy customers.',
  billCredit: 'Bill credit',
  discountedElectricRate: 'Discounted electric rate',
  financing: 'Financing',
  freeEquipment: 'Free equipment',
  hovLane: 'HOV Lane',
  insuranceDiscount: 'Insurance discount',
  parkingExemption: 'Parking exemption',
  publicCharging: 'Public charging',
  rebate: 'Rebate',
  taxCredit: 'Tax credit',
  taxExemption: 'Tax exemption',
  tollDiscount: 'Toll discount',
  home: 'Home',
  feet: 'feet',
  month: 'month',
  electricVehicle: 'Electric Vehicle',
  maximum: 'maximum',
  details: 'Details',
};
