export default {
  electricVehicles: 'Autos Eléctricos',
  usedElectricVehicles: 'Autos Eléctricos Usados',
  compareVehicles: 'Comparar Vehículos',
  incentives: 'Incentivos',
  chargingStations: 'Estaciones de Carga',
  homeChargers: 'Cargadores Caseros',
  dealers: 'Distribuidores',
  newVehicles: 'Autos Nuevos',
  electricians: 'Electricistas',
  homeChargingAdvisor: 'Consejero de Carga en el Hogar',

  newMexico: 'Nuevo Mexico',
  noResults: 'No se han encontrado resultados',
  localInventoryNavTitle: 'Inventario Local',
  localInventory: 'Inventario Local',

  'homeChargingPrograms.subheader':
    'Más del 80 % de la carga de vehículos eléctricos se realiza en casa. Estamos desarrollando soluciones para ahorrarle dinero y facilitar la carga de su EV en casa: envíenos un correo electrónico en {mailTo} lo mantendremos informado cuando haya nuevos programas disponibles en New Mexico',

  'disclaimer.electricRateDollarsKwH.newMexico':
    'La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Nuevo México que carga durante el verano en el plan de tarifa residencial regular.',
  'disclaimer.electricRateDollarsKwH.colorado':
    'La tarifa de electricidad que se muestra es un ejemplo de un conductor de EV en Colorado que carga fuera de las horas pico en el plan de tarifas Hora del día - Toda la Casa.',
  'disclaimer.chargingMap':
    'Datos cortesía del Centro de Datos de Combustibles Alternativos. Es posible que el mapa no refleje la disponibilidad más reciente de las estaciones de carga.',

  electricity: 'Fuente',
  gasoline: 'Gasolina',
  total: 'Total',
  description: 'Descripción',
  detailedCalculations: 'detalles del cálculo',
  charging: 'Carga',
  emissions: 'Emisiones',
  source: 'Fuente',
  vehicles: 'Vehículos',
  all: 'Todos',
  age: 'Años',
  cheaperTo: 'mas barato',
  moreExpensive: 'más cara',

  electricVehiclesHeader: 'Autos Eléctricos',

  personalizeIncentives: 'Personalizar Incentivos',

  'vehicle.miles': 'Millas',
  'vehicle.trees': 'Árboles',
  'vehicle.view': 'Ver',
  'vehicle.more': 'más',
  'vehicle.fewer': 'menos',
  'vehicle.greater': 'más',

  'vehicle.plugInHybrid': 'Híbrido Eléctrico Enchufable',
  'vehicle.allElectric': 'Eléctricos',
  'vehicle.gas': 'Gasolina',
  'vehicle.afterIncentives': 'Después de incentivos',
  'vehicle.msrp': 'MSRP',
  'vehicle.estimatedIncentives': 'Insentivos estimados',
  'vehicle.batterySize': 'Tamaño de la batería',
  'vehicle.timeToChargeLvlTwo': 'Tiempo de carga - Nivel 2',
  'vehicle.milesPerThirtyFastCharge': 'Millas por 30 minutos de carga rápida',
  'vehicle.coTwoEmissions': 'Reducción de emisiones de CO2',
  'vehicle.phev': 'Electricidad y gasolina ',
  'vehicle.bev': 'Electricidad',
  'vehicle.age.one': '0-1 Años',
  'vehicle.age.two': '2-3 Años',
  'vehicle.age.four': '4+ Años',

  'vehicle.type.sedan': 'Sedan',
  'vehicle.type.hatchback': 'Cinco puertas',
  'vehicle.type.coupe': 'Cupé',
  'vehicle.type.crossover': 'Híbrido',
  'vehicle.type.minivan': 'Minivan',
  'vehicle.type.suv': 'SUV',
  'vehicle.type.wagon': 'Camioneta',
  'vehicle.type.truck': 'Camión',
  'vehicle.type.suvcrossover': 'SUV/Crossover',
  'footer.allRights': 'Todos los derechos reservados.',
  'footer.poweredBy': 'Patrocinado por Zappyride',
  footerAdditional:
    'Este es un sitio web de terceros que pertenece, es controlado y administrado por ZappyRide, no por la Compañía de Servicios Públicos de Arizona (APS). ZappyRide puede compartir o proporcionar de otra manera el acceso a la información no personal identificable a APS en relación con el funcionamiento y la gestión del Sitio por parte de ZappyRide. La política de privacidad de APS regula la recolección, recepción y uso de dicha información en {link} y está disponible aquí.',

  yourGuide: 'Tu guía de vehículos eléctricos.',
  estimate:
    'Calcula y compara los costos, el ahorro, los incentivos y mucho más.',
  'homepage.findYour': 'Encuentra tu VE',

  'homepage.welcome': 'Colton Recharged',
  'homepage.welcomeSub':
    'Su guía para autos eléctricos, diseñada por Colton Electric Utility. Obtenga un estimado y compare costos, opciones de ahorro, incentivos y mucho más para VE.',

  'homepage.linkCard.browseElectricVehicles':
    'VEHICULOS {lineBreak} ELÉCTRICOS',
  'homepage.linkCard.discoverIncentives': 'DESCUBRA {lineBreak} INCENTIVOS',
  'homepage.linkCard.locateChargingStations':
    'ENCUENTRE ESTACIONES {lineBreak} DE CARGA',
  'homepage.linkCard.homeChargers': 'CARGADORES PARA {lineBreak} EL HOGAR',

  'homepage.vehicleCarousel.title':
    'Hay más vehículos eléctricos disponibles que nunca. Descubre el tuyo.',
  'homepage.vehicleCarousel.subTitle':
    'Compara el costo de tu selección con el de un vehículo de gasolina similar. Un coche eléctrico puede ahorrarte dinero porque puede ser menos costoso de poseer y mantener que un coche 100% de gasolina.',
  'homepage.vehicleCarousel.findEVButton': 'Encuentre su vehículo eléctrico',
  'homepage.incentives': 'Descubre los incentivos',
  'homepage.compare': 'Compara vehículos',
  'homepage.charging': 'Encuentra estaciones de carga',
  'homepage.browse': 'Descubre los vehículos eléctricos',

  'graph.assumptions': 'Supuestos',
  'graph.graphValues': 'Valores Graficados',
  'graph.show': 'Mostrar',
  'graph.hide': 'Ocultar',
  'graph.moreExpensive': 'mas caro',
  'graph.cheaper': 'mas barato',
  'graph.toOwnOver': 'de tener por ',
  'graph.years': ' Años',
  'graph.year': ' Año',

  'graph.costOfOwnership.title': 'Costo de Propiedad',
  'graph.costOfOwnership.subTitle':
    'El {carName} es {costDeltaText} {costDeltaTextEnding}',
  'graph.costOfOwnership.subTitleThreeCars':
    'El {carName} es el más barato de tener por {yearsOfOwnership} años',
  'graph.costOfOwnership.descriptionRow.vehicle': 'Vehículo',
  'graph.costOfOwnership.descriptionRow.maintenance': 'Mantenimiento',
  'graph.costOfOwnership.descriptionRow.insurance': 'Seguro',
  'graph.costOfOwnership.descriptionRow.electricity': 'Electricidad',
  'graph.costOfOwnership.descriptionRow.gasoline': 'Gasolina',
  'graph.costOfOwnership.totalRow': 'Total',
  'graph.costOfOwnership.description': 'Descripción',

  'graph.costOfOwnership.chart.vehicle': 'Incentivos para vehículo, Reventa.',
  'graph.costOfOwnership.chart.electricity': 'Electricidad',
  'graph.costOfOwnership.chart.gasoline': 'Gasolina',
  'graph.costOfOwnership.chart.maintenance': 'Mantenimiento',
  'graph.costOfOwnership.chart.insurance': 'Seguro',

  'graph.title.costAnalysis': 'Análisis de Costos',
  'graph.title.speedOfCharge': 'Velocidad de Carga',
  'graph.title.electricRange': 'Rango Eléctrico',
  'graph.monthlyCostToFill.title': 'Costo de Llenar el Tanque Mensualmente',

  'graph.speedOfCharge.levelTwoChargingSpeed': 'Velocidad de carga Nivel 2',
  'graph.speedOfCharge.fastChargingSpeed': 'Velocidad de carga rápida',
  'graph.speedOfCharge.mphLevelTwoCharger':
    'millas por hora al usar un cargador nivel 2 ',
  'graph.speedOfCharge.thirtyFastCharge':
    'millas por cada 30 minutos de carga rápida',
  'graph.speedOfCharge.subTitle':
    'El {car} tendrá {number} {costDeltaText} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerThreeCars':
    'será el que gane más rango de carga tras una hora de uso de un cargador nivel 2',
  'graph.speedOfCharge.thirtyFastChargeThreeCars':
    'será el que gane más rango de carga después de 30 minutos de carga rápida',
  'graph.speedOfCharge.subTitleThreeCars': 'El {car} {subTitleText}',
  'graph.speedOfCharge.mphLevelTwoChargerTitle':
    'Porcentaje de carga por hora en Nivel 2',
  'graph.speedOfCharge.fastChargerTitle':
    'Cantidad de millas por 30 minutos de carga rápida.',
  'graph.electricRange.subTitleThreeCars':
    'El {car} tiene el rango eléctrico más amplio de todos',
  'graph.electricRange.subTitle':
    'El rango eléctrico del {car} tiene {number} millas { costDeltaText} ',
  'graph.electricRange.gasolineRange': 'Rango de gasolina',
  'graph.electricRange.assumption': 'Rango basado en estimaciones de la EPA',
  'graph.monthlyCostToFill.subTitle':
    'Mesualmenta, el {carName} es {style} {costDeltaText} de tanquear',
  'graph.monthlyCostToFill.subTitleThreeCars':
    'Mensualmente, el {carName} cuesta $100 más para repostar.',

  'evs.incentivesScoreOptions': 'Filtros de elegibilidad',

  'evs.electricFuelFilter': 'Eléctricos',
  'evs.hybridFuelFilter': 'Híbrido',

  'inventory-filter.filter-vehicles': 'Filtros',
  'inventory-card.vehicle-details': 'Ver detalles',
  'evs.inventorythirdParty':
    '*Este sitio web contiene enlaces a sitios web externos de terceros. Arizona Public Service no respalda ni acepta ninguna responsabilidad por el contenido o los productos vendidos en sitios web de terceros. Arizona Public Service no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web externos de terceros. Al hacer clic en los enlaces, usted libera expresamente a Arizona Public Service de toda responsabilidad que surja de su uso del sitio web externo de terceros, o del contenido de cualquier sitio web externo de terceros.',
  toolGeneralDisclaimer:
    'Este es un sitio web de terceros, ofrecido, controlado y administrado por ZappyRide, no Arizona Public Service Company (APS). ZappyRide puede compartir o proporcionar acceso a información de identificación no personal a APS en relación con la operación y la gestión del sitio de ZappyRide. La Política de privacidad de APS rige su recopilación, recibo y uso de dicha información en APS.com y está disponible aquí.',
  homeChargerAvailability:
    'Cargar tu vehiculo electrico (VE) en casa es más conveniente y accesible. Si no puedes, estimamos los costos de carga pública. Nota: Los cargadores de nivel 1 requieren 120V estándar, o 240V pare los caradores nivel 2.',
  householdIncome:
    'Combina los ingresos de todos los miembros de tu hogar. Utilizamos esta información para determinar tu elegibilidad de incentivos.',
  householdSize:
    'Incluye a todos los miembros de tu hogar (cónyuge, dependientes, etc.). Utilizamos esta información para determinar la elegibilidad de incentivos.',
  taxFilingStatus:
    'Utilizamos esta información para determinar la elegibilidad de incentivos.',
  retireAVehicle:
    'Se puede requerir retirar un vehículo de gas antiguo o existente para ciertos incentivos.',
  timeToCharge:
    'Asume carga desde batería vacía con un cargador de nivel medio 2 promedio (7.7 kW). Nota: Los cargadores de nivel 2 requieren una salida de 240 V. Los tiempos de carga de nivel 1 son mucho más largos.',
  batterySize:
    'Un kilovatio-hora es suficiente energía para conducir de 1 a 4 millas, dependiendo del vehículo. Como referencia, una batería de laptop típica puede contener menos de 0.1 kWh.',
  electricMilesDriven:
    'Estima qué porcentaje de tus millas de conducción será completamente eléctrico. Si recargas diariamente y conduces menos por día que el rango totalmente eléctrico, tu porcentaje será cerca del 100%. Para la mayoría de la conducción típica más allá del rango totalmente eléctrico, el 50% es una suposición segura.',
  graphDisclaimerPrice:
    'El precio de compra de un VE puede ser más alto en comparación con un vehículo de motor de combustión interna similar. Sin embargo, algunos de los costos iniciales más altos asociados con los VE pueden compensarse con incentivos del vehículo, un menor costo total de propiedad y un valor de reventa más fuerte.',
  graphDisclaimerElectricity:
    'El costo de electricidad puede ser menos que el costo del combustible, a la misma distancia recorrida.',
  graphDisclaimerGas:
    'Los costos de combustible de gasolina suelen ser más que los costos de electricidad.',
  graphDisclaimerMaintenance:
    'Los costos de mantenimiento son generalmente más bajos para los VE debido a que tienen menos piezas móviles.',
  graphDisclaimerInsurance:
    'Los costos de seguro de los vehiculos electricos (VE) pueden ser mas altos que los costos de seguro de un vehiculo típico, debido al mayor precio de compra de los VE. Los costos de seguro más altos pueden compensarse con los ahorros de combustible y mantenimiento.',
  graphDisclaimerCostAnalysis:
    'Las comparaciones reflejan ahorros aproximados y no están garantizadas. Los ahorros reales variarán según el uso individual y las condiciones del mercado. APS no ofrece representaciones ni garantías de ningún tipo, ya sea expresas o implícitas, con respecto a la precisión de la información contenida en este documento.',
  costAnalysisDisclaimer:
    'Las comparaciones reflejan ahorros aproximados y no están garantizadas. Los ahorros reales variarán según el uso individual y las condiciones del mercado. APS no ofrece representaciones ni garantías de ningún tipo, ya sea expresas o implícitas, con respecto a la precisión de la información contenida en este documento.',
  rangeMap:
    'Según lo anunciado por el fabricante. El rango real puede diferir, dependiendo de los factores ambientales, el comportamiento de conducción, el mantenimiento del vehículo y la edad de la batería.',

  'homepage.incentives.title':
    'Descubra incentivos y créditos fiscales para VE ',
  'homepage.incentives.subTitle':
    'Descubra que tanto puede ahorrar con un VE, ya sea por compra o alquiler. Los incentivos son personalizados según su lugar de residencia.',
  'homepage.incentives.exporeIncentives': 'DESCUBRA INCENTIVOS',

  'compareVehicles.subTitle':
    'Elige al menos dos vehículos para comenzar la comparación.',
  'compareVehicles.selectFuel': 'Seleccione el Tipo de Combustible ',
  'compareVehicles.selectMake': 'Seleccione la marca',
  'compareVehicles.selectModel': 'Seleccione el modelo',
  'compareVehicles.pickTwo':
    'Elíja al menos 2 vehículos para hacer la comparación',
  'compareVehicles.disclaimer':
    'No todas las marcas / modelos están disponibles.',

  'evs.welcomeSub': 'Encuentra el adecuado para ti.',
  'evs.buttonMatchScoreAndFilters': 'Filtros',
  'evs.matchScoreAndFilters': 'Filtros',
  'evs.matchScoreOptions': 'Filtros',
  'evs.roundTripCommute': 'Viaje de ida y vuelta',
  'evs.budgetAfterIncentives': 'Presupuesto después de incentivos',
  'evs.minSeats': 'Cantidad mínima de asientos',
  'evs.seats': 'Asientos',
  'evs.homeChargingAvailability': 'Disponibilidad de carga en el hogar',
  'evs.homeChargingAvailabilityTooltip':
    'Más autos eléctricos serán mejores para ti si tienes la posibilidad de cargarlos rapidamente en casa.',
  'evs.noCharging': 'Sin carga',
  'evs.levelOne': 'Nivel 1',
  'evs.levelTwo': 'Nivel 2',
  'evs.helpMeChoose': 'Ayúdame a elegir',
  'evs.chargingAvailability': 'Disponibilidad de carga',
  'evs.disclaimer':
    'Los vehículos mostrados pueden no reflejar la disponibilidad real. {company} no respalda ni recomienda ningún vehículo o fabricante de automóviles específico.',
  evsCatalogTitle: 'Vehiculos Electricos',

  'chargingWizard.carOvernight': '¿En dónde parquear tu Auto en la noche?',
  'chargingWizard.parkingGarage': 'Garaje privado o estacionamiento',
  'chargingWizard.onTheStreet': 'En la calle',
  'chargingWizard.publicParking': 'Estacionamiento público',
  'chargingWizard.electricianInstall':
    '¿Puede un electricista instalarme una estación de carga?',
  'chargingWizard.overnightParking':
    '¿Podrías estacionar tu auto en un lugar que cuenta con estación de carga?',
  'chargingWizard.powerOutlet':
    '¿Existe algún tomacorriente cerca al lugar en donde estacionas tu auto?',
  'chargingWizard.chargingAvailability': ' Disponibilidad de carga',
  'chargingWizard.noChargingAvailability': 'No disponibilidad de carga',
  'chargingWizard.youWouldHave': 'Tendrás {resultText} ',
  'chargingWizard.useThisValue': 'Usar este valor',

  evfilter: 'Filtro',
  'evfilter.fuel': 'Combustible',
  'evfilter.fuelTooltip':
    'Los vehículos eléctricos solo usan electricidad. Los vehículos híbridos pueden funcionar con electricidad y gasolina ',
  'evfilter.type': 'Tipo',

  egbBringCharger: 'Usaré mi propio cargador de nivel 2',

  yes: 'Si',
  no: 'No',

  'evCard.electricRange': 'Rango eléctrico',
  'evCard.totalRange': 'Rango total',
  'evCard.matchScore': 'Puntaje personalizado',
  'evCard.seeDetails': 'Detalles',
  'evCard.seeElectricVehicle': 'Ver Vehículo Eléctrico',

  'evSort.matchScore': 'Puntaje personalizado',
  'evSort.electricRange': 'Rango eléctrico',
  'evSort.priceLtH': 'Precio: Menor a mayor',
  'evSort.priceHtL': 'Precio: Mayor a menor',
  'evSort.alphabetical': 'Orden alfabético',

  'pricePanels.cash': 'Efectivo',
  'pricePanels.loan': 'Crédito',
  'pricePanels.lease': 'Arrendamiento',
  'pricePanels.loanPayment': 'Pago del préstamo',
  'pricePanels.downPayment': 'Cuota inicial',
  'pricePanels.leasePayment': 'Pago de arriendo',
  'pricePanels.perMonth': ' / Mes',
  'pricePanels.downPaymentDescription': '10% de MSRP más impuestos',
  'pricePanels.firstLeasePayment': 'Primera cuota de la renta',
  'pricePanels.incentivesForLease': 'Incentivos para rentar',
  'pricePanels.seeAllIncentives': 'Ver todos los incentivos',
  'pricePanels.seeAllCosts': 'Ver todos los costos',

  'ev.jumbotron.viewAllCars': 'Ver todos los autos',
  'ev.carDetails.fastChargingTooltip': 'Velocidad de carga rápida con DC:',
  'ev.carDetails.levelTwoChargerSpeedTooltip':
    'Asume carga desde batería vacía con un cargador de nivel medio 2 promedio (7.7 kW). Nota: Los cargadores de nivel 2 requieren una salida de 240 V. Los tiempos de carga de nivel 1 son mucho más largos.',
  'ev.carDetails.batterySizeTooltip':
    'Un Kilovatio por hora (kWh) provee suficiente energía para conducir de 1 a 4 millas dependiendo del vehículo. Una batería común para computadora puede contener menos de 1 kWh',
  'ev.carDetails.treesPlanted': 'Árboles plantados',
  'ev.carDetails.gasolineSaved': 'Gasolina ahorrada',
  'ev.carDetails.compareText':
    'Seleccionamos el {gasVehicle} por su proximidad con el {vehicle} con base en la marca, tamaño y precio.',
  'ev.cardetails.calc.dcRate': 'Cargador Rápido (DCFC)',
  'ev.cardetails.calc.EPA': 'Calificación de eficiencia EPA',
  'ev.cardetails.calc.chargingRate': 'Velocidad de carga',
  'ev.cardetails.calc.vehicleBatteryCapacity': 'Capacidad de la batería',
  'ev.cardetails.calc.maxACIntake': 'Capacidad maxima de AC',
  'ev.cardetails.calc.lvlTwoChargingRate': 'Velocidad de carga Nivel 2',
  'ev.cardetails.calc.minOflvl2AC':
    'Nivel mínimo de carga y capacidad máxima de absorcion del AC',
  'ev.cardetails.calc.lvl2FullCharge': 'Tiempo de carga total en Nivel 2',
  'ev.cardetails.calc.milesDrivenPerYr': 'Millas conducidas al año',
  'ev.cardetails.calc.userInput': 'Introducción de datos por el usuario',
  'ev.cardetails.calc.milesPerGallon': 'Millas por Galón (mpg)',
  'ev.cardetails.calc.mpg': ' millas/gal',
  'ev.cardetails.calc.portionElectric': 'Porción eléctrica usada',
  'ev.cardetails.calc.portionElectricSource':
    'Introducción de datos por el usuario. Para un vehículo híbrido, estima la porción de millas conducidas usando batería eléctrica en vez de gasolina.',
  'ev.cardetails.calc.gallonsUsed': 'Galones Usados',
  'ev.cardetails.calc.gallonsUsedFormula':
    'Millas manejadas * (1 - Porción eléctrica usada) / MPG',
  'ev.cardetails.calc.gallonsSaved': 'Galones Ahorrados',
  'ev.cardetails.calc.gallonsSavedFormula':
    'Galones usados por un vehiculo a gasolina comparable - Galones usados por VE',
  'ev.cardetails.calc.electricEmissions':
    'Emisiones por consumo eléctrico en Lbs',
  'ev.cardetails.calc.electricEmissionsLbsMwh':
    'Emisiones de CO2 por consumo eléctrico en lbs/MWh',
  'ev.cardetails.calc.EIA':
    'Administración de información energética de Estados Unidos',
  'ev.cardetails.calc.gasolineEmissionsYr':
    'Emisiones por consumo de gasolina en lbs de CO2/yr',
  'ev.cardetails.calc.gasolineEmissions':
    'Emisiones de CO2 por consumo de gasolina en lbs/gal',
  'ev.cardetails.calc.emissionsReudctions': 'Reducción de emisiones',
  'ev.cardetails.calc.lbsCo2PerYr': ' lbs de CO2 al año',
  'ev.cardetails.calc.gasolineEmissionsLbs':
    'Emisiones por consumo de gasolina en lbs',
  'ev.cardetails.calc.arborDayFoundation': 'Fundación Arbor Day',
  'ev.cardetails.calc.emissionsPerTree':
    'Emisiones capturadas por árbol por año',
  'ev.cardetails.calc.electricityEmissions':
    'Emisiones de CO2 por consumo eléctrico en lbs/MWh',
  'ev.usedCarTitle': '{car} Usado',
  'ev.cardetails.electricRange':
    'To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns.',
  'ev.cash': 'Efectivo',
  'ev.loan': 'Crédito',
  'ev.lease': 'Renta',
  'ev.milesDrivenAnnually': 'Millas conducidas por año',
  'ev.electricPortion': 'Millas eléctricas recorridas',
  'ev.electricPortionTooltip':
    'A Plug-In Hybrid Electric Vehicle (PHEV) can use both electricity and gasoline. It is usually more beneficial to use electricity.',
  'ev.yearsOwnership': 'Compra/renta al año',
  'ev.interestRate': 'Tasa de interés',
  'ev.electricityRateCalc':
    'El precio por kilovatio-hora proporcionado es la tarifa eléctrica residencial promedio anual para Arizona según la Administración de Información de Energía de EE. UU (EIA). El costo real de la carga en el hogar depende del plan de tarifas eléctricas de su hogar, así como también de cuándo y con qué frecuencia carga.',

  'assumption.salesTax': 'Impuesto a las ventas',
  'assumption.gasolinePrice': 'Precio de gasolina',
  'assumption.downPayment': 'Cuota inicial',
  'assumption.electricityRate': 'Rango eléctrico',

  'ev.compareGasVehicle':
    'Compare el {car} con un vehículo similar a gasolina, el {gasCar}.',
  'ev.compareOtherVehicles': 'COMPRE OTHER VEHICLES',
  'ev.incentives.title':
    'Hasta {savings} en créditos fiscales y reembolsos están disponibles {lineBreak} para el {car}.',
  'ev.reviews': 'Reseñas en la web',

  'chargingMap.updateZipcode': 'Actualizar Código Postal',
  'chargingMap.errorZipcode':
    'Error: {workingZipcode} no es un código postal válido',
  'chargingMap.showFullListOfStations': 'lista completa de estaciones',
  'chargingMap.public': 'Estaciones Públicas',
  'chargingMap.businessGovernment': 'Instaladas por empresas o el gobierno',
  'chargingMap.highPowerStations': 'Estaciones de Alta Potencia',
  'chargingMap.DCChargers': 'Carga rápida CC o súper-cargadores',
  'chargingMap.otherSttations': 'Otros Tipos de Estaciones',
  'chargingMap.privateStations': 'Estaciones rivadas',
  'chargingMap.searchDealers': 'Buscar Distribuidores Calificados',
  'chargingMap.otherStations': 'Otros tipos de estaciones',

  'dealers.brands': 'Marcas',
  'dealers.allBrands': 'Todos las Marcas',
  'dealers.allDealers': 'Todos los Distribuidores',
  'dealers.contact': 'CONTACTO',
  'dealers.website': 'PAGINA WEB',
  'dealers.findDealers': 'Encontrar Distribuidores',

  'incentives.title': 'Incentivos para Vehículos Eléctricos',
  'incentives.subTitle':
    'Usted puede ser elegible para un rango de incentivos, incluyendo reembolsos, créditos fiscales y otros beneficios. Los incentivos son personalizados según su lugar de residencia.',
  'incentives.single': 'Soltero',
  'incentives.married': 'Casado',
  'incentives.headOfHousehold': 'Cabeza de hogar',
  'incentives.vehicleType': 'Tipo de vehículo',
  'incentives.vehicleTypeTooltip':
    'Ciertos incentivos dependen de qué carro compres.',
  'incentives.income': 'Estado de ingresos e impuestos',
  'incentives.incomeTooltip':
    'La elegiblidad para incentivos y la cantidad que puedes recibir depende normalmente de tu nivel de ingresos. Clientes con niveles de ingresos menores son usualmente elegibles para reembolsos mayores.',
  'incentives.clunkerNone':
    'Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.',
  'incentives.clunkertoolTip':
    'Ciertos incentivos te compensan por retirar un vehículo existente, también conocido como carro viejo.',
  'incentives.clunkertoolTipContd':
    ' Están disponibles en tu región. Los requisitos para elegibilidad varían por incentivo, por favor revise los detalles del programa.',
  'incentives.planClunker': '¿Estás planeando intercambiar tu carro viejo?',
  'incentives.location': 'Ubicación',
  'incentives.locationTooltip':
    'Muchos incentivos dependen del lugar en el que vives. Por favor ingresa tu código zip para ayudarte a determinar cuáles incentivos están disponibles en tu lugar de residencia.',
  'incentives.whichVehicle': '¿Qué vehículo planeas comprar?',
  'incentives.incentiveEligibility': 'Actualizar Elegibilidad para Incentivos',

  'homeChargers.subText':
    'Seleccione el vehículo que le interesa para ver los detalles de tiempo de carga total de la bateria.',
  'homeChargers.wallMounted': 'Montaje en pared',
  'homeChargers.portable': 'Portátil',
  'homeChargers.price': 'Precio',
  'homeChargers.cordLength': 'Longitud del cable',
  'homeChargers.wifi': 'Conectividad Wifi',
  'homeChargers.socket': 'enchufe',
  'homeChargers.socketTooltip':
    'Cada modelo de cargador tiene un patrón de enchufe diferente. Estos tipos de patrón corresponden a la forma en la que las puntas encajan en la toma de corriente de la pared. Si no está seguro de cuál es el mejor para su hogar/garaje, consulte con un electricista calificado..',
  'homeChargers.cordLengthLtH': 'Longitud del cable: Menor a mayor',
  'homeChargers.cordLengthHtL': 'Longitud del cable: Mayor a menor',
  'homeChargersCard.buy': 'Detalles y Compra',
  'homeChargersCard.beforeIncentives': 'Antes de incentivos',
  'homeChargersCard.socket': 'Enchufe {socket}',
  'homeChargers.cable': 'Cable de {length} ft.',
  'homeChargers.hardwired': 'Cableado Enchufe',
  'homeChargers.toFullCharge': 'para carga completa',

  'electricians.residential': 'Residencial',
  'electricians.commercial': 'Comercial',
  'electricians.industrial': 'Industrial',
  'electricians.type': 'Tipo de Electricista',
  'electricians.searchElectricians': 'Buscar Electricistas Calificados',

  'tabbed-maps-dealer-catalog-tab': 'Distribuidores',
  'tabbed-maps-all-stations-tab': 'Estaciones de Carga',
  'tabbed-maps-electricians-tab': 'Electricistas',
  'tabbed-maps-route-tab': 'Buscar una Ruta',
  'tabbed-maps-range-tab': 'Mapa de Rango',

  viewTravelRadius: 'Ver radio de viaje',
  radiusSpecification: 'millas de rango total desde nivel completo',

  any: 'cualquier',
  new: 'Nuevo',
  preOwned: 'Seminuevo',
  preOwnedElectricVehicles: 'Vehículos Eléctricos Usados',
  'usedEVCard.yearRange': 'Rango de Años',
  'usedEVCard.electricRangeAverage': 'Promedio de Rango Eléctrico',
  'header.chargingStations': 'Carga Pública',
  'header.subHeader':
    'Es posible que se disponga de créditos del impuesto federal sobre la renta. Las cantidades reales varían. Consulta a tu asesor fiscal. Mientras tanto, ¡feliz navegación!',
  home: 'Hogar',
  feet: 'pies',
  month: 'mes',
  electricVehicle: 'Auto Eléctrico',
  maximum: 'máxima',
  details: 'Detalles',

  'header.faq': 'VE 101',
  'header.electricVehicles': 'Buscar VE',
  'header.compareVehicles': 'Comparar Vehículos',
  'header.incentives': 'Incentivos',
  'header.rates': 'Comparar Tarifas',
  'homepage.banner.title':
    'Calcule y compare costos, ahorros, incentivos y mucho más.',
  'homepage.rates': 'Comparar Tarifas',
  'homepage.vehicleCarousel.compareVehiclesButton':
    'Encuentre Su Vehículo Eléctrico',
  'incentiveCard.applyNow': 'Aplique Ahora',
  'incentiveCard.seeMore': 'Ver más',
  'incentiveCard.back': 'Atrás',
  'incentiveCard.more': 'Más',
  'homepage.chargingMap.title': 'Recargue sobre la marcha, vaya donde vaya.',
  'homepage.chargingMap.subtitle':
    'Con nuevas estaciones colocándose cada día, es más fácil que nunca conducir lejos y recargar. Ingrese su ubicación para encontrar todas las estaciones de recarga cerca de usted.',
  'chargingMap.highPower': 'SÓLO ESTACIONES DE ALTA POTENCIA',
  'chargingMap.searchCharging': 'Actualizar Código Postal',
  'charger.disclaimer':
    'Los rangos de kilometraje se basan en estimaciones proporcionadas por los fabricantes de VE. Su autonomía puede variar en función del clima, la región, el tráfico, el modelo de automóvil y las condiciones del automóvil. Los datos del Centro de Datos de Combustibles Alternativos pueden no reflejar la disponibilidad más reciente. Confirme la disponibilidad de las estaciones de recarga antes de iniciar su viaje. Envíe las presuntas estaciones de carga desaparecidas {here}.',
  'FAQ.title': 'Preguntas Frecuentes',
  'FAQ.subtitle':
    'Los VE aún son nuevos y es natural que surjan preguntas. Encuentre las respuestas a continuación.',
  'FAQ.sectionTitleOne': '¿Qué es un VE y cómo funciona?',
  'FAQ.bodyOne':
    'Un vehículo eléctrico (VE) es un tipo de vehículo que utiliza electricidad como fuente principal de energía, en lugar de gasolina o diésel. Los VE se han hecho cada vez más populares en los últimos años debido a su potencial para reducir las emisiones y disminuir los costos de funcionamiento en comparación con los vehículos tradicionales de gasolina.{lineBreak} Entonces, ¿cómo funciona un VE? Un VE funciona con un motor eléctrico, alimentado a su vez por una batería. La batería almacena electricidad, que se utiliza para alimentar el motor y hacer girar las ruedas del vehículo. Cuando el vehículo está en uso, la batería se agota lentamente. Para recargar la batería, el VE debe enchufarse a una fuente de energía eléctrica, como una toma de corriente o una estación de carga pública. {lineBreak}Existen dos tipos principales de VE: los vehículos eléctricos de batería (BEV) y los vehículos eléctricos híbridos enchufables (PHEV). Los BEV funcionan exclusivamente con electricidad, mientras que los PHEV disponen tanto de un motor eléctrico como de un motor de gasolina. Los PHEV pueden alternar entre las dos fuentes de energía según sea necesario, lo que les permite ampliar su autonomía más allá de lo que es posible sólo con electricidad.',
  'FAQ.sectionTitleTwo': 'Carga de VE 101',
  'FAQ.bodyTwo':
    'La recarga de vehículos eléctricos (VE) se clasifica en tres niveles: Nivel 1, Nivel 2 y Nivel 3 (Carga Rápida DC). {lineBreak} La carga de Nivel 1 utiliza una toma doméstica estándar de 120 voltios y proporciona la carga más lenta, añadiendo normalmente entre 3 y 5 millas de autonomía por hora, lo que la hace adecuada para la carga nocturna en casa. Los VE suelen venir con cargadores de Nivel 1 incluidos. {lineBreak} La carga de Nivel 2 utiliza una toma de 240 voltios como las que se utilizan para los grandes electrodomésticos. Proporciona una carga más rápida, añadiendo unos 15-60 kilómetros de autonomía por hora, y se encuentra habitualmente en hogares, lugares de trabajo y estaciones de carga públicas. La instalación de un cargador de Nivel 2 debe ser realizada por un electricista profesional. {lineBreak} Por último, el Nivel 3, o carga rápida de DC, emplea corriente continua (DC) y estaciones de alta potencia (normalmente 400 voltios o más), proporcionando la carga más rápida. Es capaz de añadir entre 60 y 100 millas de autonomía en sólo 20 minutos y se utiliza principalmente en entornos comerciales y a lo largo de las autopistas para recargas rápidas.',
  'FAQ.sectionTitleThree': 'Aliviar la Ansiedad por la Autonomía del VE',
  'FAQ.bodyThree':
    'Los vehículos eléctricos (VE) varían significativamente en cuanto a la distancia que pueden recorrer con una sola carga, dependiendo de factores como la capacidad de la batería, la eficiencia del vehículo y las condiciones de conducción. La mayoría de los VE modernos ofrecen autonomías de entre 150 y 300 millas por carga, y algunos modelos de gama alta superan las 400 millas. Los avances tecnológicos y las crecientes inversiones en tecnología de baterías están ampliando continuamente estos límites, mejorando la densidad energética y la eficiencia.{lineBreak} Si necesita cargar en carretera, encontrar una estación de carga pública para vehículos eléctricos (VE) es cada vez más fácil gracias a la rápida expansión de la infraestructura de recarga. En las zonas urbanas y a lo largo de las principales autopistas, las estaciones de recarga son relativamente comunes, con redes como Tesla Superchargers, ChargePoint y Electrify America que proporcionan una amplia cobertura. Muchos VE vienen equipados con sistemas de navegación que incluyen la ubicación de las estaciones de carga, y numerosas aplicaciones para smartphone y sitios web también ayudan a los conductores a localizar cargadores cercanos, revisar su disponibilidad e incluso iniciar sesiones de carga a distancia. Aunque es posible que las zonas rurales y menos pobladas sigan teniendo menos opciones, el continuo crecimiento del mercado del VE está impulsando una mayor inversión en infraestructura de recarga, lo que hace más cómodo para los propietarios de VE encontrar estaciones de recarga públicas en diversos lugares.',
  'FAQ.sectionTitleFour':
    'Beneficios del VE: Medio Ambiente, Costos y Mantenimiento',
  'FAQ.bodyFour':
    'Una de las principales ventajas de los VE es su potencial para reducir las emisiones. Como funcionan con electricidad, en lugar de con combustibles fósiles, no producen emisiones del tubo de escape cuando están en uso. Esto los convierte en una alternativa más limpia a los vehículos tradicionales impulsados por gasolina. Además, los VE suelen ser más eficientes energéticamente que los vehículos de gasolina, lo que puede reducir aún más su impacto medioambiental.{lineBreak} Otra ventaja de los VE son sus bajos costos de funcionamiento. Al tener menos piezas móviles que los vehículos de gasolina, pueden requerir menos mantenimiento y, en consecuencia, tener una vida útil más larga. Además, la electricidad suele ser más barata que la gasolina, por lo que el funcionamiento de un VE puede resultar más rentable a largo plazo. También hay varios incentivos y descuentos disponibles para los propietarios de VE, como créditos fiscales federales y estatales, acceso al carril HOV y peajes reducidos. Estos incentivos pueden reducir aún más el costo de propiedad.',
  'FAQ.sectionTitleFive': 'Seguridad de los VE',
  'FAQ.bodyFive':
    'En general, los vehículos eléctricos (VE) se consideran seguros, ya que cumplen o superan las normas de seguridad de los vehículos de gasolina tradicionales. Se someten a rigurosas pruebas y deben cumplir las mismas normas de seguridad. Los VE tienen algunas ventajas de seguridad inherentes, como un centro de gravedad más bajo debido a la colocación de la batería, lo que puede reducir el riesgo de vuelcos. Además, carecen de depósito de combustible, lo que minimiza el riesgo de incendio en caso de colisión. Muchos VE también están equipados con funciones de seguridad avanzadas, como sistemas para evitar colisiones, frenado automático de emergencia y asistencia para mantenerse en el carril. {lineBreak} Para garantizar aún más una experiencia segura y agradable con su VE, dedique tiempo a familiarizarse con su vehículo para comprender las características del mismo, incluidos los sistemas de seguridad, los procedimientos de carga y los protocolos de emergencia. El manual del propietario es un recurso valioso. Asegúrese también de mantener su VE realizando el mantenimiento periódico recomendado por el fabricante. Esto incluye la revisión de la batería, los frenos, los neumáticos y los sistemas eléctricos. Por último, emplee prácticas de carga seguras utilizando las estaciones de carga designadas y evitando las extensiones eléctricas para la carga doméstica. Asegúrese de que un electricista calificado instala su sistema de carga doméstico y de que sigue todas las directrices de seguridad.',
  'evsCatalog.title': 'DESCUBRA LOS NUEVOS VEHÍCULOS ELÉCTRICOS',
  'evsCatalog.subtitle':
    'Compare automóviles eléctricos por autonomía, precio o su Match Score personalizado. {lineBreak} Haga clic en el VE para obtener más detalles, incluido el costo total en comparación con un vehículo de gasolina similar.',
  'evs.availableNow': 'Disponible Ahora',
  'evs.availableNowTooltip':
    'Los vehículos disponibles ahora pueden comprarse para su entrega, en lugar de estar sólo abiertos a pedidos anticipados. El inventario real puede variar localmente.',
  vehicleType: 'Seleccione todos los tipos de vehículos que le interesen.',
  minimumRangeTooltipDaily:
    'Según la Administración Federal de Carreteras, la media de kilómetros diarios por conductor en Estados Unidos es inferior a 60.',
  'evSort.alphabeticaldesc': 'Marca: De la A a la Z',
  'evSort.alphabeticalasc': 'Marca: Z a A',
  'evSort.chargeasc': 'Carga Más Rápida',
  'incentiveSort.largest': 'Primero el Más Grande',
  'incentiveSort.aToZ': 'Por orden alfabético: De la A a la Z',
  'incentiveSort.zToA': 'Por orden alfabético: Z a A',
  'incentives.electricityProvider': 'PROVEEDOR DE ELECTRICIDAD',
  'evs.incentivesFilterHeader': 'PROVEEDOR DE ELECTRICIDAD',
  make: 'FABRICANTE DE VE',
  model: 'MODELO DE VE',
  trim: 'LÍNEA DE VE',
  'ev.purchaseMethod': 'Método de Compra',
  'ev.purchaseMethodToolTip':
    'En un leasing, tiene que devolver el automóvil después del periodo de arrendamiento. En el caso del financiamiento mediante préstamo, usted se queda con el automóvil una vez pagado el préstamo.',
  'incentives.retiringVehicleTooltip':
    'Retirar un viejo vehículo de gas puede ser necesario para ciertos incentivos.',
  'incentives.householdIncome': 'INGRESOS FAMILIARES',
  'incentives.householdIncomeTooltip':
    'Combine los ingresos de todos los miembros del hogar. Se utiliza para determinar la elegibilidad para los incentivos.',
  'incentives.tradeIn': 'RETIRAR UN VEHÍCULO',
  'incentives.householdSize': 'TAMAÑO DEL HOGAR',
  'incentives.taxFilingStatus': 'SITUACIÓN FISCAL',
  'incentives.taxFilingStatusTooltip':
    'Se utiliza para determinar la elegibilidad para los incentivos.',
  'incentives.householdSizeTooltip':
    'Incluya a todos los miembros de su hogar (cónyuge, dependientes, etc). Se utiliza para determinar la elegibilidad incentivada.',
  'incentives.selectMake': 'Seleccione la Marca',
  'incentives.selectModel': 'Seleccionar el Modelo',
  'incentives.selectTrim': 'Seleccionar el Línea',
  zipcode: 'CÓDIGO POSTAL',
  'evSort.title': 'ORDENAR POR',
  'incentives.disclaimer':
    'La elegibilidad y los importes de los incentivos no constituyen asesoramiento fiscal o jurídico. Consulte al otorgante del incentivo y/o a su profesional fiscal o jurídico para determinar la elegibilidad, el importe específico de los incentivos o las rebajas disponibles.',
  'page.chargingMap.title': 'Localizar las estaciones de carga',
  'page.chargingMap.subtitle':
    'Cargar sobre la marcha es más fácil que nunca. Ingrese su ubicación para encontrar todas las estaciones de recarga cerca de usted o en su ruta.',
  'compareVehicles.selectFuelTitle': 'TIPO DE COMBUSTIBLE',
  'compareVehicles.selectMakeTitle': 'MARCA DEL VEHÍCULO',
  'compareVehicles.selectModelTitle': 'MODELO DE VEHÍCULO',
  'ev.carDetails.usedBatterySizeTooltip':
    'Un kilovatio-hora es energía suficiente para recorrer de 1 a 4 millas, dependiendo del vehículo. Como referencia, la batería de una computadora portátil típica puede contener menos de 0.1 kWh.',
  'vehicle.timeToCharge':
    'Supone la carga desde vacío con un cargador medio de Nivel 2 (7.7 kW). Nota: Los cargadores de Nivel 2 requieren una toma de 240 V. Los tiempos de carga del Nivel 1 son mucho más largos.',
  'vehicle.c02Emissions':
    'En comparación con un vehículo de gasolina equivalente de marca, tipo y año similares, suponiendo un kilometraje anual típico de 12,000 millas y teniendo en cuenta las emisiones de su mezcla energética local según los datos de EIA.gov.',
  'graph.yourEV': '(Su selección)',
  'graph.similarGasVehicle': '(Vehículo de gas similar)',
  minimumRangeTooltip:
    'Calcule qué porcentaje de sus kilómetros recorridos será completamente eléctrico. {br} Si recarga diariamente y conduce menos al día que la autonomía totalmente eléctrica, su porcentaje se acercará al 100%. {br} Para la conducción más típica más allá de la autonomía totalmente eléctrica. El 50% es una suposición segura.',
  gasolinePriceLabel: 'Precio de la Gasolina ($/gal)',
  'ev.publicPrivateCharging': 'PORCENTAJE DE CARGA PÚBLICA',
  'ev.electricityRate': 'TARIFA ELÉCTRICA DOMÉSTICA',
  'evs.thirdParty':
    'No respaldamos ni recomendamos ningún vehículo o fabricante de automóviles específico. Los vehículos mostrados pueden no reflejar la disponibilidad real.',
  'assumption.downPaymentValue': '10% del PVPR + Impuestos',
  'graph.electricRange.assumptions': 'SUPOSICIÓN',
  'vehicle.downPayment': '+ {downPayment} pago inicial',
  'vehicle.downPaymentMonth': 'durante {months} meses',
  'vehicle.plusTax': 'más impuestos',
  'vehicle.offMSRP': '{msrp} descuento PVPR',
  'ev.jumbotron.maximizeIncentives': 'MAXIMIZAR LOS INCENTIVOS',
  'ev.jumbotron.totalCost': 'VER COSTO TOTAL DE PROPIEDAD',
  'ev.jumbotron.compare': 'COMPARAR CON OTROS VEHÍCULOS',
  'ev.carDetails.table.title': 'Especificaciones del Vehículo',
  'ev.carDetails.table.footer':
    'Es posible que tenga que ponerse en contacto con el fabricante para obtener un precio más exacto o con descuento. {br} Las especificaciones del vehículo mostradas son estándar, aunque pueden existir variantes. Los vehículos enumerados son sólo para fines de referencia y la disponibilidad depende del inventario local. {br} {br} La Calculadora de Ahorro proporciona estimaciones ilustrativas en todo momento y no garantiza la exactitud de ninguna estimación de costos, ahorros, especificaciones de hardware o incentivos. Consulte las fuentes de referencia y a los fabricantes de equipos originales para obtener información actualizada sobre costos, especificaciones de hardware e incentivos.',
  'vehicle.transmission': 'Transmisión',
  'vehicle.transmission.automatic': 'Automático',
  'vehicle.payload': 'Carga útil',
  'vehicle.seats': 'Asientos',
  'vehicle.cargoSpace': 'Espacio de Carga',
  'vehicle.cargoSpace.value': '{cargoSpace} pies cúbicos',
  'vehicle.batteryCapacity': 'Capacidad de la Batería',
  'vehicle.totalRange.value': '{totalRange} millas',
  'vehicle.totalRange': 'Alcance Total',
  'vehicle.electricRange': 'Gama Eléctrica',
  'vehicle.electricRange.value': '{electricRange} millas',
  'vehicle.gasEfficiency': 'Eficiencia del Gas',
  'vehicle.electricEfficiency': 'Eficiencia Eléctrica',
  'vehicle.type': 'Tipo',
  'vehicle.subtype': 'Subtipo',
  'vehicle.weightClass': 'Clase de Peso',
  'vehicle.drivetype': 'Tren de Transmisión',
  'vehicle.fuelType': 'Tipo de Combustible',
  'ev.carDetails.view.back': 'TODOS LOS VEHÍCULOS',
  'vehicle.maxACChargeRate': 'Velocidad Máxima de Carga de AC',
  'ev.compareGasVehicle.tooltip':
    'Seleccionamos el {gasCar} por su proximidad al {car} en función de la marca, el tamaño y el precio.',
  tcoSavingsHeading:
    'El {car1} no cuesta actualmente menos en propiedad que su vehículo actual',
  'graph.costToBreakdown.subTitleAlwaysCheaper':
    'El {car1} cuesta actualmente menos en propiedad que su vehículo actual',
  'graph.costToBreakdown.subTitleCheaper':
    'El {car1} Se abarata después de {time}',
  year: 'año',
  years: 'años',
  'Year ': 'Año',
  modal1: '¿Cuál es la carga eléctrica media de su factura mensual?',
  modal4: 'Factura Mensual de Electricidad Doméstica',
  modal5: '¿Qué VE posee ya o desea comprar/alquilar?',
  modal6:
    'Sugerencia: Puede ajustar estos filtros después de ver los resultados.',
  modal7:
    'Elija su VE actual o preferido para ver el ahorro potencial en los distintos planes de precios. La selección del vehículo afecta al ahorro potencial de la factura eléctrica debido a las diferencias de rendimiento y eficiencia eléctrica entre los distintos modelos.',
  modal2: 'Paso ',
  'modal3.of': 'del',
  hcaCarMake: 'Marca de VE Considerada',
  hcaCarModel: 'Modelo de VE Considerado',
  modal8: 'Previo',
  modal9: 'En promedio, ¿cuántos kilómetros conduce al año?',
  modal10: 'Sugerencia: Un conductor típico recorre unas 12,000 millas al año.',
  modal11: '¿Qué plazo de carga le conviene más?',
  modal12:
    'Sugerencia: Cargar durante la noche o los fines de semana es lo más rentable. Puede configurar su VE para que se cargue durante el horario que mejor se adapte a su agenda.',
  modal14: '¿Cuál es su plan de precios actual?',
  modal15:
    'Sugerencia: La mayoría de los clientes están en el Servicio Residencial (RS)',
  modal16:
    'Nota: Para los clientes con tarifa de Servicio Residencial (RS), suponemos que actualmente conducen un vehículo de gas con un tipo, marca y precio similares al VE seleccionado en el paso 2 y mostramos el ahorro que supondría cambiar a este VE.',
  'modal.allHours': 'TODAS LAS HORAS',
  'modal.weekend': 'FIN DE SEMANA',
  'modal.residentialService': 'Servicio Residencial (RS)',
  'modal.residentialLoad': 'Gestión de la Carga Residencial (RLM)',
  'rateComparison.youAreAlreadySavingVehicleCompared':
    'Ya está ahorrando hasta {highlightText} al año en {highlight} con un {selectedVehicle} en relación con un vehículo de gasolina comparable.',
  'rateComparison.youAreSavingVehicleCompared':
    'Puede ahorrar hasta {highlightText}/año cambiando al {highlight} con {selectedVehicle} respecto a un vehículo de gasolina comparable.',
  'rateComparison.youAreAlreadySaving':
    'Ahorrará hasta {highlightText} si tiene un VE y utiliza el plan de tarifas {highlight}.',
  'chargingMap.oneMile': 'En un radio de 1 Milla',
  'chargingMap.threeMiles': 'En un radio de 3 Millas',
  'chargingMap.fiveMiles': 'En un radio de 5 Millas',
  modal13: 'Todas las horas',
  basicFilters: 'Filtros Básicos',
  regularResidential: 'Residencial Regular',
  hcaCurrentElectricRate: 'Tasa Actual',
  'rateAdvisor.rateComparison': 'Comparación de Tarifas',
  pricingPlan: 'Comparación de planes de precios',
  'rateAdvisor.sub':
    'Identifique la tarifa eléctrica de menor costo para su VE. Ajuste las entradas que aparecen a continuación para encontrar la tarifa eléctrica adecuada para usted.',
  'rateAdvisor.title': 'Asesor de Tarifas',
  hcaWhenWillYouCharge: 'Patrón de Carga',
  homeAssumptions: 'Inicio Supuestos',
  hcaCurrentMonthlyBill: 'Factura de Electricidad Mensual del Hogar',
  drivingAssumptions: 'Supuestos de Conducción',
  gasolinePrice: 'Precio de la Gasolina',
  conventionalVehicleEfficiency: 'Eficiencia de los Vehículos de Gas',
  conventionalVehicleEfficiencyTooltip:
    'Para calcular el posible ahorro, introduzca las millas por galón (MPG) del vehículo de gasolina que desea sustituir por un VE totalmente eléctrico o híbrido enchufable.',
  hcaPortionPublicCharging: 'Porción de Carga Pública',
  hcaPortionPublicChargingTooltip:
    'Los conductores de VE realizan casi toda su recarga en casa.',
  costOfPublicCharging: 'Costo de la Carga Pública',
  costOfPublicChargingTooltip:
    'Estimamos el costo de las estaciones de recarga públicas de pago, pero puede editar este filtro si conoce el costo de recarga de su localidad.',
  free: 'gratis',
  low: 'baja',
  medium: 'medio',
  high: 'alta',
  'rateDetails.learnMore': 'Más Información',
  'pseg.changeYourRate': 'Cambie Su Tarifa',
  savingsGraphDisclaimer:
    '* Supongamos que alimenta su hogar con este plan de precios y que actualmente conduce un vehículo de gas con un tipo, marca y precio similares a los del VE que ha seleccionado.',
  'rateComparison.youAreAlreadySavingVehicle':
    'Ya está ahorrando hasta {highlightText} al año al cambiar a {highlight} con un {selectedVehicle}.',
  'rateComparison.youAreSavingVehicle':
    'Puede ahorrar {highlightText} al año cambiando a {highlight} con un {selectedVehicle}.',
  'rateComparison.youAreSaving':
    'Ahorrará hasta {highlightText} si tiene un VE y utiliza el plan de tarifas {highlight}.',
  savingsGraphYAxis: 'Costos en promedio estimados por año',
  saved: 'guardado',
  'rs.residentialService': 'Servicio Residencial',
  'rs.rateOverview': 'Tarifas',
  'rs.residentialBody':
    'El programa de tarifas del Servicio Residencial (RS) está diseñado para los clientes residenciales que reciben el servicio de suministro eléctrico de Public Service Electric and Gas Company (PSE&G). Los clientes pueden optar por comprar su suministro eléctrico a un Proveedor Externo (TPS) o al Servicio de Generación Básica (BGS) de PSE&G. La tarifa incluye un cargo mensual por servicio y diversos cargos de distribución en función del uso y la temporada.',
  'rs.howItWorks': 'Cómo Funciona',
  'rs.serviceCharge': 'Cargo por Servicio:',
  'rs.tax':
    'Una cuota mensual fija de $4.64 (o $4.95 con el impuesto sobre ventas y uso de Nueva Jersey)',
  'rs.distribution': 'Cargos de Distribución:',
  'rs.billed':
    'Se facturan por kilovatio-hora (kWh) y varían según la temporada y el uso:',
  'rs.timeOne': 'De octubre a mayo:',
  'rs.rangeOne':
    '$0.033344 por kWh (hasta 600 kWh), $0.033344 por kWh (más de 600 kWh).',
  'rs.timeTwo': 'De junio a septiembre:',
  'rs.rangeTwo':
    '$0.047308 por kWh (hasta 600 kWh), $0.051129 por kWh (más de 600 kWh).',
  'rs.charges':
    'Los cargos incluyen tasas adicionales para mejoras de infraestructuras y diversos programas de política pública.',
  'rs.additional': 'Cargos Adicionales:',
  'rs.include':
    'Incluya los beneficios sociales, la generación no eléctrica, la recuperación del piloto solar, los programas ecológicos, los ajustes fiscales, los certificados de emisión cero y los incentivos a la conservación, todos ellos combinados en la facturación.',
  'rlms.loadService': 'Servicio de Administración de Cargas Residenciales',
  'rlms.rateOverview': 'Tarifas',
  'rlms.designed':
    'El Servicio de Administración de Cargas Residenciales (Tarifa RLM) está diseñado para clientes residenciales. Cubre el suministro de electricidad, que puede adquirirse a un Proveedor Externo (TPS) o a través del servicio básico de generación del servicio público. Se aplican tarifas de servicio mensuales y tarifas de distribución variables en función de la temporada y la hora de uso (en hora pico o fuera de hora pico). Los cargos adicionales pueden incluir Beneficios Sociales, Generación No Utilitaria, Recuperación del Piloto Solar, Recuperación de Programas Ecológicos, Crédito de Ajuste Fiscal, Recuperación del Certificado de Emisiones Cero y Programa de Incentivos a la Conservación.',
  'rlms.customerBilled':
    'A los clientes se les factura un cargo mensual por el servicio más cargos de distribución basados en el consumo de electricidad, que varían según la estación y la hora del día. En verano, las horas pico son más caras. Varios cargos adicionales recuperan los costos relacionados con las políticas públicas, los programas de energías renovables y los incentivos a la eficiencia. Los clientes pueden optar por obtener su suministro eléctrico de un TPS o del servicio por defecto de Public Service. Si opta por el TPS, los cargos se basan en el acuerdo con el proveedor, y el Servicio Público no cobrará por la parte del suministro.',
  'rlms.whatItMeans': 'Lo Que Significa Para Usted',
  'rlms.customerFlexibility':
    'Como cliente, tiene flexibilidad para elegir su proveedor de electricidad, lo que podría repercutir en su costo total. Comprender las distintas tarifas y cuándo la electricidad es más cara (en hora pico frente a fuera de horas pico) puede ayudarle a gestionar su consumo y reducir potencialmente su factura. Además, los cargos incluyen contribuciones a programas e incentivos exigidos por el estado, lo que garantiza que usted está apoyando políticas públicas y medioambientales más amplias. Asegúrese de realizar los pagos a tiempo para evitar penalizaciones, y revise los términos completos para conocer las condiciones específicas relacionadas con su servicio.',
  'rlms.howItWorks': 'Cómo Funciona',
  here: 'aqui',
  'vehicle.maxDCChargeRate': 'Tasa máxima de carga de DC',
  'vehicle.chargeTimeLevel2': 'Tiempo de carga - Level 2',
  'vehicle.plugTypes': 'Tipos de enchufe',
  'vehicle.chargeTimeDC': 'Tiempo de carga - DC',
  'graph.costOfOwnership.chart.taxesFees': 'Impuestos y honorarios',
  'graph.costOfOwnership.chart.charger': 'Cargador/instalar',

  'footer.clientDisclaimer':
    '© 2024 Grupo Empresarial de Servicio Público Incorporado. Reservados todos los derechos.',
  billCredit: 'Crédito en la factura',
  discountedElectricRate: 'Tarifa eléctrica con descuento',
  financing: 'Financiación',
  freeEquipment: 'Equipo gratuito',
  hovLane: 'Carril HOV',
  insuranceDiscount: 'Descuento de seguro',
  parkingExemption: 'Exención de estacionamiento',
  publicCharging: 'Carga pública',
  rebate: 'Rebaja',
  taxCredit: 'Crédito fiscal',
  taxExemption: 'Exención de impuestos',
  tollDiscount: 'Descuento de peaje',
  'header.english': 'Inglés',
  'header.spanish': 'Español',
  'homepage.browse.mobile': 'Descubra {lineBreak} VE',
  'ev.perGallon': '/galón',
  'ev.public': 'pública',
  'ev.msrp': 'del PVPR + Impuestos',
  apply: 'aplicar',
  savingsGraphHomeLabel: 'hogar',
  savingsGraphEVLabel: 'Autos Eléctricos',
  savingsGraphGasLabel: 'Gasolina',
  Make: 'FABRICANTE DE VE',
  Model: 'MODELO DE VE',
  Trim: 'LÍNEA DE VE',
  'inventory-filter.model-label': 'MODELO DE VE',
  'inventory-filter.all': 'Todos',
  'graph.fuelCostGraphic.similarGasVehicle': '(Vehículo de gas similar)',
  'graph.fuelCostGraphic.yourSelection': '(Su selección)',

  'results.updated': 'Results have been updated as of ',
  'vehicles.none': 'No se encontraron vehículos',
  loading: 'Cargando',
  linkUnavailable: 'Enlace de red no disponible',
};

