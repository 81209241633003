import React from "react";

const CPAdditionalInfo = () => {
    return (
        <div className="row">
            <div className="col-md-6 offset-md-3">
                <div className="colon-header">
                    <strong>Additional Information</strong>
                </div>
                <p>Charging Perks Pilot&nbsp;
                    <a 
                        href="https://www.xcelenergy.com/staticfiles/xe-responsive/Admin/Charging-Perks-TC.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms & Conditions (PDF).
                    </a>
                </p>
                <p>Questions? Email us at&nbsp;
                    <a 
                        href="mailto:Builder.Call.Line@xcelenergy.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        ElectricVehicles@xcelenergy.com
                    </a>
                </p>
            </div>
        </div>
    )
}

export default CPAdditionalInfo;