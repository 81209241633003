import { useState, useRef } from "react";
import haversineDistance from "../utils/Helpers/haversineDistancs";

const NEAREST_URL =
  "https://developer.nrel.gov/api/alt-fuel-stations/v1/nearest.json";

const fetchData = (additionalParams, signal) => {
  let params = {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    fuel_type: "ELEC",
    format: "JSON",
    // NREL API doesn't handle preflight CORS requests properly, so we have to
    // include the API key as a GET query param instead of as a header
    api_key: process.env.REACT_APP_NREL_API_KEY,
    radius: 25,
    limit: "all",
    ...additionalParams,
  };
  let url = new URL(NEAREST_URL);

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  return window.fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    signal,
  });
};

const areBoundsWithinCurrentArea = (
  bounds,
  currentCenter,
  currentRadiusInMi
) => {
  if (
    Object.keys(bounds)
      .map((k) => bounds[k])
      .some((val) => !val)
  )
    return false;

  if (
    !currentCenter ||
    !currentCenter.lat ||
    !currentCenter.lng ||
    !currentRadiusInMi
  )
    return false;

  const distances = boundsDistancesFromCenter(bounds, currentCenter);

  return !distances.some((distance) => distance >= currentRadiusInMi);
};

const calcNewRadiusInMi = (bounds, newCenter) => {
  const distances = boundsDistancesFromCenter(bounds, newCenter);

  const maxDistance = Math.max(...distances);

  // The buffer reduces the number of requests that need to be made
  // to the NREL API. By grabbing stations outside of the immediate
  // visible boundary, stations don't need to be fetched when the
  // user scrolls around in small areas.
  const RADIUS_BUFFER_IN_MI = 15;
  const MAX_ALLOWED_RADIUS = 370; // If someone zooms way out, limit the request size. 370 is range of Tesla Model S.
  const bufferedDistance = (maxDistance || 0) + RADIUS_BUFFER_IN_MI;
  return Math.min(bufferedDistance, MAX_ALLOWED_RADIUS);
};

const boundsDistancesFromCenter = (bounds, center) => {
  const coordinates = [bounds.nw, bounds.ne, bounds.sw, bounds.se];

  return coordinates.map((coordinate) =>
    haversineDistance(center, coordinate, true)
  );
};

const useChargingStations = () => {
  const [loading, setLoading] = useState(false);
  const [chargingStations, setChargingStations] = useState([]);
  const [error, setError] = useState("");

  const [currentCenter, setCurrentCenter] = useState();
  const [currentRadiusInMi, setCurrentRadiusInMi] = useState();

  const abortControllerRef = useRef(null);

  const fetchChargingStations = async (
    { latitude, longitude, radius: userSuppliedRadius },
    bounds = {}
  ) => {
    if (!latitude || !longitude) return;
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    if (areBoundsWithinCurrentArea(bounds, currentCenter, currentRadiusInMi))
      return;

    try {
      abortControllerRef.current = new window.AbortController();
      setLoading(true);
      const newRadiusInMi =
        userSuppliedRadius ||
        calcNewRadiusInMi(bounds, {
          lat: latitude,
          lng: longitude,
        });

      const response = await fetchData(
        {
          latitude,
          longitude,
          radius: newRadiusInMi,
        },
        abortControllerRef.current.signal
      );
      const json = await response.json();

      abortControllerRef.current = null;

      setLoading(false);
      setCurrentCenter({
        lat: latitude,
        lng: longitude,
      });
      setCurrentRadiusInMi(newRadiusInMi);
      if (json) {
        setChargingStations(json.fuel_stations);
      }
    } catch (error) {
      setLoading(false);

      if (error.name !== "AbortError") {
        setError(error);
      }
      abortControllerRef.current = null;
    }
  };

  return {
    error,
    chargingStations,
    fetchChargingStations,
    loading,
  };
};

export default useChargingStations;
