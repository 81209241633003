import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import unique from "lodash/uniq";
import { FormattedMessage, useIntl } from "react-intl";

import Select from "../shared/InputElements/Select";

const SelectVehicleModelFilter = ({
  id = "select-vehicle-model-filter",
  vehicles,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const make = userPrefs.get("vehicleMakeFilter");

  const intl = useIntl();

  let allLabel =
    intl && intl.formatMessage
      ? intl.formatMessage({
          id: "inventory-filter.all",
          defaultMessage: "All",
        })
      : "";

  let vehicleModelOptions =
    make !== "All" ? (vehicles || []).filter((ev) => ev.make === make) : [];
  vehicleModelOptions = unique(
    (vehicleModelOptions || []).map((ev) => ev.model)
  );

  let modelLabel = (
    <FormattedMessage
      id="inventory-filter.model-label"
      defaultMessage="EV MODEL"
      description="EV MODEL"
    />
  );

  return (
    <Select
      disabled={make === "All"}
      id={id}
      value={userPrefs.get("vehicleModelFilter")}
      label={modelLabel}
      optionNames={[allLabel, ...vehicleModelOptions]}
      optionValues={["All", ...vehicleModelOptions]}
      handler={(e) => userPrefs.set({ vehicleModelFilter: e.target.value })}
    />
  );
};

export default SelectVehicleModelFilter;

SelectVehicleModelFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
