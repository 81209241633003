import React from 'react';
import './Footer.scss';
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
import { FormattedMessage } from 'react-intl';


const Footer = () => {

  return (
    <div className="Footer">
      <div className="container">
        <div>
          <span className="disclaimer_container">
            <DisclaimerComponent
              clientName="zappynobackground"
              textStyle={{
                fontFamily: 'BentonSans',
                fontSize: '10px',
              }}
              imageStyle={{
                marginTop: '30px',
                maxWidth: '100%',
                marginBottom: '30px',
              }}
              wrapperStyle={{
                padding: '0px',
              }}
            />
          </span>
        </div>
        <p style={{fontSize: '10px'}}>
          <FormattedMessage
            id="footer.clientDisclaimer"
            defaultMessage="© 2024 Public Service Enterprise Group Incorporated. All Rights Reserved."
            description="© 2024 Public Service Enterprise Group Incorporated. All Rights Reserved."
          />
        </p>
      </div>
    </div>
  );
};

export default Footer;
