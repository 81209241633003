import {
  HOME_ENERGY_COST_ID,
  EV_ENERGY_COST_ID,
  GAS_ENERGY_COST_ID,
} from "../constants/";

const calculateRateTotals = ({
  rates,
  currentRate,
  rateCostCalculator,
  gasolineCostCalculator,
  publicChargingElectricCostPerMonth,
  selectedEnergyCostIds,
  isComparingLowestRateOnly,
}) => {
  const {
    equivalentGasVehicle: equivalentGasVehicleGasolineCost,
    ev: evGasolineCost,
  } = gasolineCostCalculator.annualCosts();

  const rateTotals = [currentRate, ...rates].map((rate, idx) => {
    const isCurrentUserSituation = idx === 0;

    const { home: homeElectricityCost, ev: evElectricityCost } =
      rateCostCalculator.annualCosts(rate);

    const gasolineCost = (() => {
      if (!selectedEnergyCostIds.includes(GAS_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation) return equivalentGasVehicleGasolineCost;
      return evGasolineCost;
    })();

    const evCost = (() => {
      if (!selectedEnergyCostIds.includes(EV_ENERGY_COST_ID)) return 0;
      if (isCurrentUserSituation && rate.id !== "EV-TOU-5") return 0;

      return publicChargingElectricCostPerMonth + evElectricityCost;
    })();

    const homeCost = selectedEnergyCostIds.includes(HOME_ENERGY_COST_ID)
      ? homeElectricityCost
      : 0;


    let title = [rate.title[0]];
    if (idx === 0) {
       title.push("(Current)*");
    }

    return {
      id: `${rate.id}${idx === 0 ? "-current" : ""}`,
      title: title,
      [HOME_ENERGY_COST_ID]: homeCost,
      monthly: 0,
      [EV_ENERGY_COST_ID]: evCost,
      [GAS_ENERGY_COST_ID]: gasolineCost,
      annual: 0,
      evCostValue: publicChargingElectricCostPerMonth + evElectricityCost
    };
  });

  const lowestRateTotal = rateTotals.find((total) => {
    return (
      total.monthly ===
      Math.min(
        ...rateTotals
          .filter((rateTotal, idx) => idx !== 0)
          .map((t) => t.monthly)
      )
    );
  });
  const filteredRateTotals = isComparingLowestRateOnly
    ? rateTotals.filter(
        (rateTotal, idx) => idx === 0 || rateTotal.id === lowestRateTotal.id
      )
    : rateTotals.filter((rateTotal) => rateTotal.id !== currentRate.id);

  return filteredRateTotals.map((rateTotal) => ({
    ...rateTotal,
    isLowest: rateTotal.id === lowestRateTotal.id,
  }));
};

export default calculateRateTotals;
