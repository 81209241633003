import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './IncentiveCard.scss';
import { FormatAsDollars } from './../../utils/Helpers/Format';
import GaTracker from './../../utils/GaTracker/GaTracker';
import { useIntl } from 'react-intl';

const IncentiveCard = (props) => {
  const intl = useIntl();

  const [showMore, setShowMore] = useState(false);
  let incentive = props && props.incentive ? props.incentive : {};

  let eligibility = incentive.evaluation && incentive.evaluation.eligibility;

  if (eligibility === 'ineligible') {
    return null;
  }

   let incentiveTypes = {
    'Bill Credit': intl.formatMessage
      ? intl.formatMessage({ id: 'billCredit', defaultMessage: 'Bill Credit' })
      : 'Bill Credit',
    'Discounted Electric Rate': intl.formatMessage
      ? intl.formatMessage({
          id: 'discountedElectricRate',
          defaultMessage: 'Discounted Electric Rate',
        })
      : 'Discounted Electric Rate',
    Financing: intl.formatMessage
      ? intl.formatMessage({ id: 'financing', defaultMessage: 'Financing' })
      : 'Financing',
    'Free Equipment': intl.formatMessage
      ? intl.formatMessage({
          id: 'freeEquipment',
          defaultMessage: 'Free Equipment',
        })
      : 'Free Equipment',
    'HOV Lane': intl.formatMessage
      ? intl.formatMessage({ id: 'hovLane', defaultMessage: 'HOV Lane' })
      : 'HOV Lane',
    'Insurance Discount': intl.formatMessage
      ? intl.formatMessage({
          id: 'insuranceDiscount',
          defaultMessage: 'Insurance Discount',
        })
      : 'Insurance Discount',
    'Parking Exemption': intl.formatMessage
      ? intl.formatMessage({
          id: 'parkingExemption',
          defaultMessage: 'Parking Exemption',
        })
      : 'Parking Exemption',
    'Public Charging': intl.formatMessage
      ? intl.formatMessage({
          id: 'publicCharging',
          defaultMessage: 'Public Charging',
        })
      : 'Public Charging',
    Rebate: intl.formatMessage
      ? intl.formatMessage({ id: 'rebate', defaultMessage: 'Rebate' })
      : 'Rebate',
    'Tax Credit': intl.formatMessage
      ? intl.formatMessage({ id: 'taxCredit', defaultMessage: 'Tax Credit' })
      : 'Tax Credit',
    'Tax Exemption': intl.formatMessage
      ? intl.formatMessage({
          id: 'taxExemption',
          defaultMessage: 'Tax Exemption',
        })
      : 'Tax Exemption',
    'Toll Discount': intl.formatMessage
      ? intl.formatMessage({
          id: 'tollDiscount',
          defaultMessage: 'Toll Discount',
        })
      : 'Toll Discount',
  };

  let description = incentive.description || '';


  const getIncentiveAmount = (incentive) => {
    let amount = false;
    if (!incentive) return 0;

    if (incentive.support_for === 'Vehicle purchase') {
      if (
        incentive.evaluation?.applicable_to_lease === true &&
        incentive.evaluation?.applicable_to_ownership === false
      ) {
        amount =
          incentive.evaluation &&
          incentive.evaluation?.amount_in_lease &&
          parseInt(incentive.evaluation?.amount_in_lease, 10) > 0
            ? parseInt(incentive.evaluation?.amount_in_lease, 10)
            : 0;
        amount = FormatAsDollars(amount);
      } else if (
        incentive.evaluation?.applicable_to_lease === false &&
        incentive.evaluation?.applicable_to_ownership === true
      ) {
        amount =
          incentive.evaluation &&
          incentive.evaluation?.amount_in_purchase &&
          parseInt(incentive.evaluation?.amount_in_purchase, 10) > 0
            ? parseInt(incentive.evaluation?.amount_in_purchase, 10)
            : 0;
        amount = FormatAsDollars(amount);
      } else if (
        incentive.evaluation?.applicable_to_lease === true &&
        incentive.evaluation?.applicable_to_ownership === true
      ) {
        if (
          incentive.evaluation?.amount_in_lease !== 0 &&
          incentive.evaluation?.amount_in_purchase !== 0
        ) {
          amount =
            incentive.evaluation &&
            incentive.evaluation?.amount_in_purchase &&
            parseInt(incentive.evaluation?.amount_in_purchase, 10) > 0
              ? parseInt(incentive.evaluation?.amount_in_purchase, 10)
              : 0;
          amount = FormatAsDollars(amount);
        }
      }
    } else {
      amount =
        incentive.typical_amount && incentive.typical_amount !== 'N/A'
          ? incentive.typical_amount.replace('-$', '-')
          : false;
    }

    if (!amount) {
      return (
        <svg
          width="34"
          height="54"
          viewBox="0 0 34 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.26869 53.979C0.868664 53.8632 0.571505 53.6422 0.468642 53.2424C0.434355 53.1161 0.422925 53.0109 0.480071 52.8952C0.91438 51.9376 1.33726 50.9801 1.783 50.0226C4.70887 43.6356 7.62332 37.2381 10.5492 30.8511C10.8349 30.2303 11.1092 29.6095 11.395 28.9887C11.4178 28.9256 11.4635 28.873 11.4521 28.7888C11.3607 28.7888 11.2578 28.7888 11.1664 28.7888C7.96619 28.7888 4.76602 28.7888 1.56584 28.7888C0.971526 28.7888 0.662939 28.5678 0.468642 28.0522C0.377209 27.8102 0.50293 27.6103 0.605792 27.4209C1.40584 25.9373 2.21731 24.4431 3.02878 22.9595C7.05186 15.5413 11.0864 8.12315 15.1094 0.704988C15.2923 0.368277 15.5323 0.105222 15.9323 0C21.2583 0 26.5844 0 31.9104 0C31.9218 0.0105222 31.9332 0.0315654 31.9446 0.0315654C32.619 0.14731 32.8933 0.90491 32.4932 1.49415C28.7445 6.92362 25.0071 12.3531 21.2698 17.7825C21.2355 17.8352 21.2012 17.8983 21.1555 17.9719C21.2583 18.014 21.3612 18.0035 21.4526 18.0035C25.1671 18.0035 28.8816 18.0035 32.5961 18.0035C33.1219 18.0035 33.4876 18.214 33.6362 18.6033C33.7619 18.9295 33.6933 19.2241 33.4647 19.4871C31.7275 21.3917 29.9903 23.2962 28.2416 25.2007C26.3672 27.2525 24.4928 29.3044 22.607 31.3562C20.9155 33.2081 19.224 35.06 17.521 36.9119C15.8295 38.7638 14.138 40.6157 12.4464 42.4677C10.8349 44.2354 9.21198 45.9926 7.60046 47.7603C5.82893 49.6964 4.05741 51.6325 2.28588 53.5791C2.10302 53.779 1.88586 53.9158 1.62299 54C1.49727 53.979 1.38298 53.979 1.26869 53.979Z"
            fill="#BDBDBD"
          />
        </svg>
      );
    } else {
      return amount;
    }
  };

  const truncateText = (text, max = 25) => {
    if (!text) return 'None';

    if (text.length > max) {
      return text.substring(0, max);
    } else return text;
  };

  const handleShowClick = (e) => {
    e.preventDefault();
    e.stopPropagation();


    setShowMore((s) => !s);
  };

  const handleClick = () => {
    GaTracker.trackEvent({
      category: 'Incentives',
      action: 'Clicked on Incentive Card',
      label: `${incentive.name}`,
    });
  };

  let renderCard =
    incentive && !props.displayNone ? (
      <a
        className={`${props.displayNone ? 'displayNone' : 'IncentiveCard'} ${
          showMore ? 'showAllCard' : ''
        }`}
        href={incentive.details_url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => handleClick()}
      >
        {!showMore ? (
          <>
            <div className={`IncentiveCardTop`}>
              <span>
                {incentive.grantor + ' - ' + incentiveTypes[incentive.type]}
              </span>
            </div>
            <div className="IncentiveCardBody">
              <p className="h1 card-title">{getIncentiveAmount(incentive)}</p>
              <p className="h2 light">{incentive.name || ''}</p>
            </div>
            <div className="pl-2 pr-2 hrContainer">
              <hr />
            </div>
          </>
        ) : null}

        <div
          className={
            showMore ? 'IncentiveCardBottom active' : 'IncentiveCardBottom'
          }
        >
          <div className="incentive-card-bottom-content">
            {showMore ? description : truncateText(description, 0)}
          </div>
          <div className={showMore ? 'showBtn' : 'applyBtn'}>
            {showMore ? (
              <div className="readLess">
                <button className="readFull" onClick={handleShowClick} tabIndex={0}>
                  {intl.formatMessage({
                    id: 'incentiveCard.back',
                    defaultMessage: 'Back',
                  })}
                </button>
                <a
                  className="readFull"
                  href={incentive.details_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClick()}
                >
                  {intl.formatMessage({
                    id: 'incentiveCard.more',
                    defaultMessage: 'More',
                  })}
                </a>
              </div>
            ) : (
              <>
                <a
                  className="readFull Apply"
                  href={incentive.details_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleClick()}
                >
                  {intl.formatMessage({
                    id: 'incentiveCard.applyNow',
                    defaultMessage: 'Apply Now',
                  })}
                </a>
                <button className="readFull" onClick={handleShowClick} tabIndex={0}>
                  {intl.formatMessage({
                    id: 'incentiveCard.seeMore',
                    defaultMessage: 'See More',
                  })}
                </button>
              </>
            )}
          </div>
        </div>
      </a>
    ) : null;

  return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
  incentive: PropTypes.object,
  compact: PropTypes.bool,
};
