import React, { useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import SlideElectricMilesPortionForPhev from '../InputComponents/SlideElectricMilesPortionForPhev/SlideElectricMilesPortionForPhev';
import SlideInterestRateAsBasisPoints from '../InputComponents/SlideInterestRateAsBasisPoints/SlideInterestRateAsBasisPoints';
import SlideMilesDrivenAnnually from '../InputComponents/SlideMilesDrivenAnnually/SlideMilesDrivenAnnually';
import SlideMonthsOfOwnership from '../InputComponents/SlideMonthsOfOwnership/SlideMonthsOfOwnership';
import SelectPurchaseMethod from '../InputComponents/SelectPurchaseMethod/SelectPurchaseMethod';
import SlideGasolinePrice from '../InputComponents/SlideGasolinePrice/SlideGasolinePrice';
import SlideSalesTax from '../InputComponents/SlideSalesTax/SlideSalesTax';
import SlideDownPayment from '../InputComponents/SlideDownPayment/SlideDownPayment';
import ControlledInputZipcode from '../InputComponents/InputZipcode/ControlledInputZipcode';
import { ValidateFiveDigitCode } from '../../utils/Helpers/USPostalCodeValidator';
import UserPrefsContext from '../../context/UserPrefs/UserPrefsContext';
import SlidePortionPublicCharging from '../InputComponents/SlidePortionPublicCharging/SlidePortionPublicCharging';
import isPHEV from '../../functions/vehicle/isPHEV';
import { FormattedMessage } from 'react-intl';
import InputElectricityRate from '../InputComponents/InputElectricityRate/InputElectricityRate';

const CostOfOwnershipOptions = ({ cars, zipcode }) => {
  
  const userPrefs = useContext(UserPrefsContext);
  useEffect(() => {
    setWorkingZipcode(zipcode);
  }, [zipcode]);

  const [workingZipcode, setWorkingZipcode] = useState(zipcode);
  if (!cars || cars.length === 0) return null;
  const phevExists = cars.some((car) => isPHEV(car));

  let renderElectricMilesPortionForPhev = phevExists ? (
    <SlideElectricMilesPortionForPhev />
  ) : null;
  const mobile = window.innerWidth < 720;

  

  const handleFormSubmit = () => {
    if (ValidateFiveDigitCode(workingZipcode)) {
      userPrefs.syncWorkingZipcode();
      userPrefs.toggleIncentivePrefsModal();
    }
  };
  return (
    <>
      <div className="input-well bordered">
        <ControlledInputZipcode
          zipcode={userPrefs.get('zipcode')}
          handleSubmit={(e) => {
            handleFormSubmit();
          }}
          workingZipcode={workingZipcode}
          setZipcode={(e) => {
            setWorkingZipcode(e.target.value);
            if (ValidateFiveDigitCode(e.target.value)) {
              userPrefs.set({ workingZipcode: e.target.value });
            }
          }}
          id="input-zipcode-for-rate-comp-launch"
          isUpdating={userPrefs.zipcodeIsUpdating}
          isNotFound={userPrefs.zipcodeIsNotFound}
          hideValidateRequest
        />
        <div style={{ marginTop: '10px' }}>
          <SelectPurchaseMethod />
        </div>
        <SlideMilesDrivenAnnually />
        {renderElectricMilesPortionForPhev}
        <SlideMonthsOfOwnership />
        <SlideInterestRateAsBasisPoints />
        {process.env.REACT_APP_SLIDE_SALES_TAX && <SlideSalesTax />}
        {process.env.REACT_APP_SLIDE_DOWN_PAYMENT && <SlideDownPayment />}
        <SlideGasolinePrice />
        {process.env.REACT_APP_SLIDE_PORTION_PUBLIC_CHARGING && (
          <SlidePortionPublicCharging />
        )}

        <InputElectricityRate />
      </div>
      {!mobile ? (
        <p className="selected-subText">
          <FormattedMessage
            id="evs.thirdParty"
            defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
            description="Compare Text"
          />
        </p>
      ) : null}
    </>
  );
};

CostOfOwnershipOptions.propTypes = {
  car: PropTypes.object,
};
export default CostOfOwnershipOptions;
