import React, { useEffect } from "react";
import PropTypes from "prop-types";
import "./EVs.scss";

import EVCatalog from "./../../client_customizations/components/EVCatalog/EVCatalog";
import MatchScoreOptions from "./../../client_customizations/components/MatchScoreOptions/MatchScoreOptions";
import EvSortControls from "./../../components/EvSortControls/EvSortControls";

import ModalComponent from "../../components/ModalUIComponent/ModalUIComponent";

import { FormattedMessage, useIntl } from 'react-intl';

const EVs = ({ electricVehicles, ip, uuid, page }) => {
  const intl = useIntl()

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_VEHICLES_TITLE;
  });

  const time = new Date().toLocaleString();
   const mobile = window.innerWidth < 720;


  const renderOptions = (mobile) => (
    <>
      <MatchScoreOptions  mobile={mobile} />
    </>
  );


  return (
    <section className="container-fluid" id="EVs">
      <div className="container">
        <div className="row mb-3">
          <div className="col-sm-12 text-center">
            <h1 className="header">
              <FormattedMessage
                id="evsCatalog.title"
                defaultMessage="DISCOVER NEW ELECTRIC VEHICLES"
                description="Electric Vehicles"
              />
            </h1>
            <h2 className="small-centered mb-bottom sub-header" >
              <FormattedMessage
                id="evsCatalog.subtitle"
                defaultMessage="Compare electric cars by EV range, price, or your personalized Match Score. {lineBreak} Click on the EV for more details, including total cost compared to a similar gas vehicle."
                description="Description of Colton Recharged"
                values={{
                  lineBreak: <br />,
                }}
              />
            </h2>
          </div>
        </div>
        <div className="evsort-row">{mobile ? <EvSortControls mobile={mobile} /> : null}</div>
        <div className="row">
          <div className="col-sm-12 d-block d-lg-none text-center">
            <ModalComponent
              buttonText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'evs.buttonMatchScoreAndFilters',
                      defaultMessage: 'Match Score and Filters',
                    })
                  : 'Match Score and Filters'
              }
              titleText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'evs.matchScoreAndFilters',
                      defaultMessage: 'Refine Match Score and Filters',
                    })
                  : 'Refine Match Score and Filters'
              }
              submitText={
                intl.formatMessage
                  ? intl.formatMessage({
                      id: 'apply',
                      defaultMessage: 'Apply',
                    })
                  : 'Apply'
              }
              disclaimerText={
                <FormattedMessage
                  id="evs.thirdParty"
                  defaultMessage="We do not endorse or recommend any specific vehicle or car manufacturer. Vehicles displayed may not reflect actual availability."
                  description="Vehicles Disclaimer"
                  values={{
                    company: process.env.REACT_APP_FULL_COMPANY_NAME,
                  }}
                />
              }
            >
              {renderOptions(mobile)}
            </ModalComponent>
            <br />
          </div>
          <div className="col-xl-3 col-lg-4 d-none d-lg-block">
            {renderOptions(mobile)}
          </div>
          <div
            id="electric-vehicles-catalog"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: 'absolute', top: '-9999px' }}
          >
            <span>Results have been updated as of {time}.</span>
          </div>
          <div
            className="col-xl-9 col-lg-8 col-sm-12"
            style={{ padding: 0, flexDirection: 'column', display: 'flex' }}
          >
            <div className="evsort-row">
              {!mobile ? <EvSortControls mobile={mobile} /> : null}
            </div>
            <EVCatalog
              vehicles={electricVehicles}
              hasEvDetails
              hasIncentivesAndMatchScore
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EVs;

EVs.propTypes = {
  electricVehicles: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
