import React from "react";
import { FormattedMessage } from "react-intl";

const AboutChargingPerks = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="perksText"
          defaultMessage="The Xcel Energy Charging Perks pilot program helps EV drivers charge up during times of day that help the energy grid operate more efficiently and use more renewable energy. Every time you plug in at home, Xcel Energy and your automaker or evPulse will work together to automatically schedule your car’s charging."
          description="The Xcel Energy Charging Perks pilot program helps EV drivers charge up during times of day that help the energy grid operate more efficiently and use more renewable energy. Every time you plug in at home, Xcel Energy and your automaker or evPulse will work together to automatically schedule your car’s charging."
        />
      </p>

      <p>
        <FormattedMessage
          id="perksText2"
          defaultMessage="You can rest assured that your EV is charging at the best time for the energy grid, and your vehicle is ready to go when you need it. Plus, by participating in this pilot, you’ll inform future innovative programs."
          description="You can rest assured that your EV is charging at the best time for the energy grid, and your vehicle is ready to go when you need it. Plus, by participating in this pilot, you’ll inform future innovative programs."
        />
      </p>
    </>
  );
};

export default AboutChargingPerks;
