const ALL_STATIONS_TAB_NAME = "Charging Stations";
const ALL_STATIONS_TAB_URL = "/charging-stations";
export const ALL_STATIONS_TAB_ID = "tabbed-maps-all-stations-tab";

export const RANGE_MAP_TAB_ID = "tabbed-maps-range-tab";

const ROUTE_MAP_TAB_NAME = "Route Map";
const ROUTE_MAP_TAB_URL = "/route-map";
export const ROUTE_MAP_TAB_ID = "tabbed-maps-route-tab";

export const MAP_TABS = [
  {
    id: ALL_STATIONS_TAB_ID,
    name: ALL_STATIONS_TAB_NAME,
    url: ALL_STATIONS_TAB_URL,
    title: process.env.REACT_APP_PAGES_CHARGING_TITLE,
    description: "Find local public charging stations",
    isEnabled: process.env.REACT_APP_PAGES_CHARGING_ENABLED,
    activePage: "map",
  },
  {
    id: ROUTE_MAP_TAB_ID,
    name: ROUTE_MAP_TAB_NAME,
    url: ROUTE_MAP_TAB_URL,
    title: process.env.REACT_APP_PAGES_ROUTE_MAP_TITLE,
    description: "Find a Range",
    isEnabled: process.env.REACT_APP_PAGES_MAP_ROUTE_ENABLED,
  },
];
