import getPaymentDetails from "../getPaymentDetails";
import calcCharging from "../Charging/calcCharging";

// Source: https://docs.google.com/spreadsheets/d/1dfIie-kuQ9PiD8_FB-5zK5bH6HMshyHqlVKt1_-vCdg/edit#gid=0
const calcMatchScore = (
  vehicle,
  milesDrivenDaily,
  chargerLevel,
  maxBudget,
  minSeats,
  monthsOfOwnership,
  interestRateAsBasisPoints,
  milesDrivenAnnually,
  salesTax,
  downPayment
) => {
  let paymentDetails = getPaymentDetails(
    vehicle,
    monthsOfOwnership,
    interestRateAsBasisPoints,
    milesDrivenAnnually,
    salesTax,
    downPayment
  );
  let afterIncentives = paymentDetails.afterIncentives
    ? paymentDetails.afterIncentives
    : 0;

  // Range (45)
  let range = 0;
  const rangeDelta = parseInt(vehicle.total_range, 10) - milesDrivenDaily;
  if (rangeDelta > 0)
    range = range + 30 + Math.min(15, Math.round(rangeDelta / 10));

  // Charging (30)
  let charging = 0;
  let timeToCharge = calcCharging.timeForDailyCommute(
    milesDrivenDaily,
    vehicle.electric_efficiency,
    chargerLevel
  );

  if (timeToCharge < 8) charging = 20;
  if (timeToCharge <= 8 && timeToCharge > 4)
    charging = charging + Math.round(-2.5 * timeToCharge + 20);
  if (timeToCharge <= 4) charging = charging + 10;

  // Seats (25)
  let seats = 0;
  const seatsDelta = parseInt(vehicle.seats_max, 10) - minSeats;
  if (seatsDelta >= 0) seats = 25;

  // Budget (up to negative 50)
  let budgetAdjustment = 0;
  if (afterIncentives > maxBudget)
    budgetAdjustment = Math.round(
      50 * Math.min(1, (afterIncentives - maxBudget) / maxBudget)
    );

  return {
    seats: seats,
    charging: charging,
    range: range,
    budgetAdjustment: budgetAdjustment,
    total: parseInt(seats + charging + range - budgetAdjustment, 10)
  };
};

export default calcMatchScore;
