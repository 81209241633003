import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalComponent = ({ children, submitText, buttonText, titleText, disclaimerText }) => {
  const [showModal, setModal] = useState(false);
  const toggle = () => {
    setModal(!showModal);
  };

  let renderFooter = submitText ? (
    <ModalFooter style={{border: "none"}}>
      <Button color="btn btn-pseg" style={{width: "100%"}}onClick={toggle}>
        {submitText}
      </Button>
    </ModalFooter>
  ) : null;

  return (
    <div>
      <button
        onClick={toggle}
        className="btn btn-pseg"
        style={{ width: '100%', height: '54px', marginBottom: '20px' }}
        type="button"
      >
        {buttonText}
      </button>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader
          toggle={toggle}
        >
          {titleText}
        </ModalHeader>
        <ModalBody>
          {children}
          {renderFooter}  
          <div className="disclaimer-modal">{disclaimerText}</div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalComponent;

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  submitText: PropTypes.string
};
