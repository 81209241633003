import React from 'react';
import PropTypes from 'prop-types';

import './SelectedChargingStationCard.scss';
import ChargingStationsMapMarker from '../ChargingStationsMapMarker/ChargingStationsMapMarker';

const SelectedChargingStationCard = ({ station, toggleWithHistory }) => {
  if (!station) return null;

  const {
    station_name: stationName,
    street_address: streetAddress,
    city,
    state,
    zip,
    station_phone: stationPhone,
  } = station;


  return (
    <div className="SelectedChargingStationCard">
      <div className="map-marker">
        <ChargingStationsMapMarker key={station.id} station={station} />
        <p>{stationName}</p>
      </div>
      <p>{streetAddress}</p>
      <p>{`${city}, ${state} ${zip}`}</p>
      {stationPhone ? (
        <a href={`tel:+1${stationPhone}`}>{stationPhone}</a>
      ) : null}
    </div>
  );
};

export default SelectedChargingStationCard;

SelectedChargingStationCard.propTypes = {
  station: PropTypes.object,
};
