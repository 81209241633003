import React, { useContext } from "react";
import RateButtonGroup from "../../../../components/shared/InputElements/RateButtonGroup";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

const ButtonGroupChargingPatterns = ({
  id = "btn-group-charging-patterns",
  label = "",
  chargingPatterns,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const chargingPatternId = userPrefs.get('chargingPatternId');

  return (
    <div className="ButtonGroupChargingPatterns">
      <RateButtonGroup
        id={id}
        value={chargingPatternId}
        label={label}
        chargingPatterns={chargingPatterns}
        handler={(e, selected) =>
          userPrefs.set({ chargingPatternId: selected })
        }
        {...rest}
      />
    </div>
  );
};

export default ButtonGroupChargingPatterns;
