import React from "react";
import { FormattedMessage } from "react-intl";

const HowToGetStarted = () => {
  return (
    <>
      <p>
        <FormattedMessage
          id="perksDisclaimer"
          defaultMessage="If you drive a battery electric or plug-in hybrid electric vehicle from BMW, Chevrolet, Ford, Honda, or Tesla and do some of your charging at home, you can apply to participate through your automaker or evPulse."
          description="If you drive a battery electric or plug-in hybrid electric vehicle from BMW, Chevrolet, Ford, Honda, or Tesla and do some of your charging at home, you can apply to participate through your automaker or evPulse."
        />
      </p>
      <ul>
        <li>
          <b>
            <FormattedMessage
              id="perksBMWDrivers"
              defaultMessage="BMW drivers:"
              description="BMW drivers:"
            />
          </b>
          <a
            href="https://bmwchargeforward.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksApply"
              defaultMessage="Apply here"
              description="Apply here"
            />
          </a>
          <FormattedMessage
            id="perksBMWDriversComp"
            defaultMessage="if you drive any BMW fully electric or plug-in hybrid vehicle."
            description="if you drive any BMW fully electric or plug-in hybrid vehicle."
          />
        </li>
        <li>
          <b>
            <FormattedMessage
              id="perksChevroletDrivers"
              defaultMessage="Chevrolet drivers:"
              description="Chevrolet drivers:"
            />
          </b>
          <a
            href="https://smartcharging.chevrolet.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksApply"
              defaultMessage="Apply here"
              description="Apply here"
            />
          </a>
          <FormattedMessage
            id="perksChevroletDriversComp"
            defaultMessage="if you drive an eligible Chevrolet electric vehicle or plug-in hybrid"
            description="if you drive an eligible Chevrolet electric vehicle or plug-in hybrid"
          />

          <a
            href="http://res.delivery.generalmotors.com/res/gmde_mid_prod7/CH_XCEL_FAQ_042122.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksChecktheFAQs"
              defaultMessage="Check the FAQs"
              description="Check the FAQs"
            />
          </a>

          <FormattedMessage
            id="perksChevroletDriversComp2"
            defaultMessage="for a list of currently eligible vehicles."
            description="for a list of currently eligible vehicles."
          />
        </li>
        <li>
          <b>
            <FormattedMessage
              id="perksFordDrivers"
              defaultMessage="Ford drivers:"
              description="Ford drivers:"
            />
          </b>
          <a
            href="http://www.ford.com/grid/xcel"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksApply"
              defaultMessage="Apply here"
              description="Apply here"
            />
          </a>

          <FormattedMessage
            id="perksChevroletFordComp"
            defaultMessage="if you drive a Mustang Mach-E"
            description="if you drive a Mustang Mach-E"
          />
        </li>
        <li>
          <b>
            <FormattedMessage
              id="perksHondaDrivers"
              defaultMessage="Honda drivers:"
              description="Honda drivers:"
            />
          </b>
          <a
            href="https://hondasmartcharge.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksApply"
              defaultMessage="Apply here"
              description="Apply here"
            />
          </a>

          <FormattedMessage
            id="perksHondaFordComp"
            defaultMessage="if you drive a Honda Clarity."
            description="if you drive a Honda Clarity."
          />
        </li>
        <li>
          <b>
            <FormattedMessage
              id="perksTeslaDrivers"
              defaultMessage="Tesla drivers:"
              description="Tesla drivers:"
            />
          </b>

          <FormattedMessage
            id="perksHondaTeslaComp"
            defaultMessage="If you drive a Tesla, you can apply to participate through evPulse, an EV charge optimization platform from WeaveGrid."
            description="If you drive a Tesla, you can apply to participate through evPulse, an EV charge optimization platform from WeaveGrid."
          />

          <a
            href="https://chargingperks.xcelenergy.ev-pulse.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="perksApply"
              defaultMessage="Apply here"
              description="Apply here"
            />
          </a>

          <FormattedMessage
            id="perksHondaTeslaComp2"
            defaultMessage="to learn more and apply on the evPulse enrollment website."
            description="to learn more and apply on the evPulse enrollment website."
          />
        </li>
      </ul>
      <div className="charging-perks-iframe">
        <iframe
          title="xcel-charging-perks"
          height="340"
          src="https://www.youtube.com/embed/QzgdTR5aJy0"
        />
      </div>
    </>
  );
};

export default HowToGetStarted;
