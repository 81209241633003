import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands } from "../../../utils/Helpers/Format";

import Range from "../../shared/InputElements/Range";
import {useIntl} from 'react-intl';

const SlideMilesDrivenDaily = ({
  id = "miles-driven-daily-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.roundTripCommute", defaultMessage: "Roundtrip Commute"}) : "Roundtrip Commute"
  const description = val => {
    return `${FormatAsThousands(val)} ${intl.formatMessage ? intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles"}) : "miles"}`;
  };

  return (
    <Range
      id={id}
      value={userPrefs.get('milesDrivenDaily')}
      label={label}
      rangeMin={1}
      rangeMax={150}
      rangeStep={1}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={(e) => userPrefs.set({ milesDrivenDaily: e.target.value })}
      tooltip={intl.formatMessage(
        {
          id: 'minimumRangeTooltipDaily',
          defaultMessage:
            'According to the {fha}, the average daily miles per day per driver in the United States is under 60.',
        },
        {
          fha: (
            <a href="https://highways.dot.gov/" target="_blank" rel="noopener noreferrer" className="FHA-Link">
              Federal Highway Administration
            </a>
          ),
        }
      )}
      {...rest}
    />
  );
};

export default SlideMilesDrivenDaily;

SlideMilesDrivenDaily.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};