import React from "react";
import PropTypes from "prop-types";

import GaTracker from "../../../utils/GaTracker/GaTracker"

import GrandTitleParas from "../shared/GrandTitledParas/GrandTitledParas"
import { FormattedMessage } from 'react-intl';

// import "./EasyChargingHome.scss";

const handleEnrollClick = () => {
    GaTracker.trackEvent({
        category: "Xcel Domain Links",
        action: "Clicked Xcel Domain Link",
        label: `Enroll in Income Qualified CO`,
    });
};

const renderEnrollmentDetails = (stateName, enrollURL) => (
  <>
    <section className="container works-elible-enroll">
      <div className="row">
        <GrandTitleParas
          classNames="eligible-if"
          colonHeader={
            <FormattedMessage
              id="youAreEligible"
              defaultMessage="You're eligible if you:"
              description="You're eligible if you:"
            />
          }
        >
          <ul>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleAccount"
                defaultMessage="Have an electric service account with Xcel Energy in Colorado."
                description="Have an electric service account with Xcel Energy in Colorado."
              />
            </li>
            <li>
              <FormattedMessage
                id="evRebateCoEligibleIncome"
                defaultMessage="Meet certain income requirements."
                description="Meet certain income requirements."
              />
              <li>
                <FormattedMessage
                  id="evRebateCoEligibleEnrolled"
                  defaultMessage="If you are enrolled in a program such as LEAP, CARE, SNAP or TANF, you qualify."
                  description="If you are enrolled in a program such as LEAP, CARE, SNAP or TANF, you qualify."
                />
              </li>
              <li>
                <FormattedMessage
                  id="evRebateCoEligibleQuestions"
                  defaultMessage="See Frequently Asked Questions for more information."
                  description="See Frequently Asked Questions for more information."
                />
              </li>
            </li>
          </ul>
        </GrandTitleParas>
        <GrandTitleParas classNames="enroll">
          <div className="text-center">
            <a
              href={
                'https://www.xcelenergy.com/staticfiles/xe-responsive/Marketing/21-07-533_CO-EV-CarRebate_app_P03.pdf'
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={handleEnrollClick}
            >
              <button className="btn btn-ae btn-pseg">
                <FormattedMessage
                  id="incentiveCard.applyNow"
                  defaultMessage="Apply Now"
                  description="Apply Now"
                />
              </button>
            </a>
          </div>
        </GrandTitleParas>
      </div>
    </section>
  </>
);


const EasyChargingHome = ({
    stateName,
    enrollURL
}) => {
    return (
        <div className="easy-charging-home">
            <section className="overview">
                {renderEnrollmentDetails(stateName, enrollURL)}
            </section>
        </div>
    );
}

export default EasyChargingHome;

EasyChargingHome.propTypes = {
    stateName: PropTypes.string,
    enrollURL: PropTypes.string
}