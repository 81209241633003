import React from "react";
import PropTypes from "prop-types";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import { getFAQs } from "./getFAQs";
import "./FrequentlyAskedQuestionsSection.scss";

const FaqSection = ({ content }) => (
  <CollapsibleSection headLine={content.headLine}>
    {content.body}
  </CollapsibleSection>
);

const FrequentlyAskedQuestionsSection = ({ applicableTo, region }) => {
  return (
    <div id="faqContainer">
      {getFAQs(applicableTo, region).map((content, index) => (
        <FaqSection
          content={content}
          key={`${index}-${content.applicableTo.join("-")}`}
        />
      ))}
    </div>
  );
};

export default FrequentlyAskedQuestionsSection;

FrequentlyAskedQuestionsSection.propTypes = {
  applicableTo: PropTypes.string,
};
