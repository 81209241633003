import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import { FormattedMessage } from 'react-intl';
import Lightning from '../../../../client_customizations/assets/images/icons/LightningIcon.svg';
import Gasoline from '../../../../client_customizations/assets/images/icons/gasolineIcon.svg';
import Hybrid from '../../../../client_customizations/assets/images/icons/Hybrid_Icon.svg';
import getCarCostOfOwnership from '../../CostOfOwnership/getCarCostOfOwnership';
import UserPrefsContext from '../../../../context/UserPrefs/UserPrefsContext';
import './MonthlyFuelComparison.scss';

import {
  FormatCarName,
  FormatAsDollars,
} from '../../../../utils/Helpers/Format';
import { useIntl } from 'react-intl';

const FuelCostGraphic = ({ variant, cost, vehicleType }) => (
  <Box
    height={150}
    width={150}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <Box
      height={variant === 'large' ? 150 : variant === 'medium' ? 130 : 100}
      width={variant === 'large' ? 150 : variant === 'medium' ? 130 : 100}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-end"
      borderRadius="50%"
      bgcolor={
        vehicleType === 'BEV' || vehicleType === 'PHEV' ? '#8F220A' : '#142C41'
      }
    >
      {vehicleType === 'BEV' ? (
        <img src={Lightning} alt="" />
      ) : vehicleType === 'PHEV' ? (
        <img src={Hybrid} alt="" style={{ width: '75%' }} />
      ) : (
        <img src={Gasoline} alt="" />
      )}
      <p
        style={{
          color: '#FFFFFF',
          fontWeight: 'bold',
          fontSize: '1.2rem',
        }}
      >
        {FormatAsDollars(cost)}
      </p>
    </Box>
  </Box>
);

export default function MonthlyFuelComparison({
  cars,
  comparison,
  insuranceData,
}) {
  const userPrefs = useContext(UserPrefsContext);

  const carsCosts = cars.map((car) => {
    return getCarCostOfOwnership(car, userPrefs,  insuranceData[car.handle]);
  });
  const vehicle1Fuel =
    carsCosts[0].electricity.perMonth + carsCosts[0].gasoline.perMonth;
  const vehicle2Fuel =
    carsCosts[1].electricity.perMonth + carsCosts[1].gasoline.perMonth;
  const vehicle3Fuel =
    cars.length > 2
      ? carsCosts[2].electricity.perMonth + carsCosts[2].gasoline.perMonth
      : null;
  const monthlyFuelCostDelta = vehicle1Fuel - vehicle2Fuel;
  const intl = useIntl();

  const sortedCarCosts = [...carsCosts].sort((carCost1, carCost2) => {
    let cost1 = carCost1.electricity.perMonth + carCost1.gasoline.perMonth;
    let cost2 = carCost2.electricity.perMonth + carCost2.gasoline.perMonth;
    return cost1 - cost2;
  });

  return (
    <div className="costOfOwnership input-well bordered">
      <Box py={2} display="flex" justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <div
            className={
              comparison ? 'graph-sub-title margin' : 'graph-title margin'
            }
          >
            {cars.length > 2 ? (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitleThreeCars"
                defaultMessage="{carName} is {style} {costDeltaText} to fill up monthly"
                description="Three Graph Sub Title"
                values={{
                  carName: FormatCarName(sortedCarCosts[0].car),
                }}
              />
            ) : monthlyFuelCostDelta > 0 ? (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitle"
                defaultMessage="{carName} is {style} {costDeltaText} to fill up monthly"
                description="Graph Sub Title"
                values={{
                  carName: FormatCarName(cars[0]),
                  style: FormatAsDollars(Math.abs(monthlyFuelCostDelta)),
                  costDeltaText: intl.formatMessage({
                    id: 'moreExpensive',
                    defaultMessage: 'more',
                  }),
                }}
              />
            ) : (
              <FormattedMessage
                id="graph.monthlyCostToFill.subTitle"
                defaultMessage="{carName} is {style} {costDeltaText} to fill up monthly"
                description="Graph Sub Title"
                values={{
                  carName: FormatCarName(cars[0]),
                  style: FormatAsDollars(Math.abs(monthlyFuelCostDelta)),
                  costDeltaText: intl.formatMessage({
                    id: 'cheaperTo',
                    defaultMessage: 'less',
                  }),
                }}
              />
            )}
          </div>
          <Box
            className="fuel-cost-boxes"
            display="flex"
            justifyContent="space-around"
            width={cars.length > 2 ? '100%' : '100%'}
          >
            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <FuelCostGraphic
                variant={
                  vehicle2Fuel > 300
                    ? 'large'
                    : vehicle2Fuel > 200
                    ? 'medium'
                    : 'small'
                }
                vehicleType={cars[1]['fuel']}
                cost={vehicle2Fuel}
              />
              <div className="bubble-title" mt={1.5}>
                {FormatCarName(cars[1])}
              </div>
              <p className="bubble-title">
                <FormattedMessage
                  id="graph.fuelCostGraphic.similarGasVehicle"
                  defaultMessage="(Similar gas vehicle)"
                  description="fuel cost graphic gas"
                />
              </p>
            </Box>

            <Box
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <FuelCostGraphic
                variant={
                  vehicle1Fuel > 300
                    ? 'large'
                    : vehicle1Fuel > 200
                    ? 'medium'
                    : 'small'
                }
                vehicleType={cars[0]['fuel']}
                cost={vehicle1Fuel}
              />
              <div className="bubble-title" mt={1.5} fontSize="0.875rem">
                {FormatCarName(cars[0])}
              </div>
              <p style={{ fontSize: "14px"}}>
                <FormattedMessage
                  id="graph.fuelCostGraphic.yourSelection"
                  defaultMessage="(Your selection)"
                  description="fuel cost graphic your selection"
                />
              </p>
            </Box>

            {cars.length > 2 ? (
              <Box
                flex="1"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <FuelCostGraphic
                  variant={
                    vehicle3Fuel > 300
                      ? 'large'
                      : vehicle3Fuel > 200
                      ? 'medium'
                      : 'small'
                  }
                  vehicleType={cars[2]['fuel']}
                  cost={vehicle3Fuel}
                />
                <div className="bubble-title" mt={1.5} fontSize="0.875rem">
                  {FormatCarName(cars[2])}
                </div>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
     
    </div>
  );
}
