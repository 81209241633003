import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Seo from  "../../../components/Seo/Seo"


import PropTypes from "prop-types";

import "./PageLayout.scss";
import FavIcon from "../../assets/images/favicon.ico"
import defaultImage from "../../assets/images/generic-preview.png"

import Header from "./Header/Header";
import Main from "./Main/Main";
import Footer from "./Footer/Footer";

import Sidebar from "../Sidebar/Sidebar"
import { FormattedMessage } from "react-intl";

const PageLayout = ({ children, page, description, props, image, language, changeLanguage, zipcode, activePage, userLocation, title, electricVehicles, usedElectricVehicles }) => {

  let path = props && props.match ? props.match.path : null

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [path])

  return (
    <div className="PageLayout">

      <Helmet defer={true}>
        <link rel="shortcut icon" href={FavIcon} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

      </Helmet>
      <Seo 
         image = {image ? image : defaultImage}
         title =  {title}
         path = {props ? props.location.pathname : ""}
         description = {description}
         twitterHandle =  {process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE ? process.env.REACT_APP_COMPONENTS_SEO_TWITTER_HANDLE : (null)}
      />
      {process.env.REACT_APP_LEAD_GEN && 
      <Sidebar electricVehicles={electricVehicles} usedElectricVehicles={usedElectricVehicles}/>
      }

      <Header page={page} zipcode={zipcode} activePage={activePage} userLocation={userLocation} changeLanguage={changeLanguage} language={language}/>
      <Main>
        <span id="main-content-id" tabIndex="-1" />
        {children}
        <div className="layout-btn-wrapper">
          <a 
            href="https://nj.myaccount.pseg.com/myservicepublic/electricvehicles-residential-program"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="link-btn">
              <FormattedMessage
                id="layout.button"
                defaultMessage="Learn more about our EV Charging Program"
              />
            </button>
          </a>
        </div>
      </Main>
      <Footer language={language} changeLanguage={changeLanguage}/>
    </div>
  );
};

export default PageLayout;

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  props: PropTypes.object,
  page: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.string
};
