import React from "react";
import { FormattedMessage } from "react-intl";

const ThePerks = () => {
  return (
    <>
      <p>
        <b>
          <FormattedMessage
            id="perksTextPays"
            defaultMessage="It pays. "
            description="It pays. "
          />
        </b>
        <FormattedMessage
          id="perksTextPays2"
          defaultMessage="We’ll send you a $100 e-gift card from a retailer of your choice just for signing up. Keep participating in the pilot program and you’ll get up to $100 more at the end of 2021 and again when the pilot ends in 2022."
          description="We’ll send you a $100 e-gift card from a retailer of your choice just for signing up. Keep participating in the pilot program and you’ll get up to $100 more at the end of 2021 and again when the pilot ends in 2022."
        />
      </p>

      <p>
        <b>
          <FormattedMessage
            id="perksTextClean"
            defaultMessage="It’s clean. "
            description="It’s clean. "
          />
        </b>
        <FormattedMessage
          id="perksTextClean2"
          defaultMessage="By charging at the right times of day, you’ll help use even more renewable energy as we work together to build smarter energy infrastructure."
          description="By charging at the right times of day, you’ll help use even more renewable energy as we work together to build smarter energy infrastructure."
        />
      </p>

      <p>
        <b>
          <FormattedMessage
            id="perksTextEasy"
            defaultMessage="It’s easy. "
            description="It’s easy. "
          />
        </b>
        <FormattedMessage
          id="perksTextEasy2"
          defaultMessage="Just plug in at home and Xcel Energy and your automaker or evPulse will do the rest."
          description="Just plug in at home and Xcel Energy and your automaker or evPulse will do the rest."
        />
      </p>
    </>
  );
};

export default ThePerks;
